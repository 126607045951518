import React from 'react'

function Spinner() {
    return (
        <div className="flex items-center h-screen w-full">
            <div className="w-full text-center">
                <img alt="spinner" className="mx-auto w-32" />
                <div className="w-full mx-auto mt-10">

                </div>
                <svg className="animate-spin spinner-border mt-5 inline-block w-8 h-8 border-4 rounded-full text-skin-base mx-auto" viewBox="0 0 24 24">
                </svg>
            </div>
        </div>
    )
}

export default Spinner