import React, { Fragment, useEffect, useState } from "react";
import { Popover, Disclosure, Transition, Menu } from "@headlessui/react";
import { } from "@heroicons/react/24/solid";
import {
  Bars3BottomLeftIcon,
  ChevronDownIcon,
  XMarkIcon,
  UserIcon,
  ArrowLeftOnRectangleIcon,
  Cog8ToothIcon,
} from "@heroicons/react/24/outline";
import Logo from "../assets/img/logo.png";
import { Link, useHistory } from "react-router-dom";
import configuration from "../config";
import { reactLocalStorage } from "reactjs-localstorage";
import { findWhere, where } from "underscore";

function Header() {
  const recruitmentmobile = [
    {
      question: "Trips",
    },
    // More questions...
  ];

  const user = [
    {
      name: "Users",
      code: "USER",
      href: "/app-user",
    },
    {
      name: "Admin User",
      code: "ADMIN_USER",
      href: "/user",
    },
    {
      name: "Role",
      code: "ROLE",
      href: "/role",
    },
    {
      name: "Permissions",
      code: "PERMISSION",
      href: "/permission",
    },
    {
      name: "Admin Login History",
      code: "LOG_HISTORY",
      href: "/login-log",
    },
  ];

  const vehicle = [
    {
      name: "Vehicle Type",
      code: "VEHICLE_TYPE",
      href: "/ride-option",
    },
    {
      name: "Brand",
      code: "BRAND",
      href: "/brand",
    },
    {
      name: "Car",
      code: "CAR",
      href: "/car",
    },
  ];
  const report = [
    {
      name: "Sales Revenue",
      code: "R_SALES",
      href: "/sales",
    },
    {
      name: "Net Profits",
      code: "R_PROFIT",
      href: "/profit",
    },
    {
      name: "Tax",
      code: "R_TAX",
      href: "/tax-report",
    },
    {
      name: "Driver Earning",
      code: "R_DRIVER",
      href: "/driver-earning",
    },
    {
      name: "Company Earning",
      code: "R_COMPANY",
      href: "/company-earning",
    },
  ];
  const content = [
    {
      name: "Static Pages",
      code: "STATIC_PAGES",
      href: "/cms",
    },
    {
      name: "Language Labels",
      code: "LANGUAGE_LABLE",
      href: "/language-lable",
    },
    {
      name: "Help Categories",
      code: "HELP_CATEGORIES",
      href: "/help-category",
    },
    {
      name: "Help",
      code: "HELP",
      href: "/help",
    },
    {
      name: "Rating Criterias",
      code: "RATING_CRITERIAS",
      href: "/rating-criteria",
    },
    {
      name: "Cancellations Reasons Criterias",
      code: "CANCEL_REASON",
      href: "/cancelation-reason",
    },
    {
      name: "Contact Us",
      code: "CONTACT_US",
      href: "/contact-us",
    },
    {
      name: "Subscription",
      code: "SUBSCRIPTION",
      href: "/subscription",
    },
    /* {
      name: "Points",
      code: "POINTS",
      href: "/points",
    }, */
    {
      name: "Share Ride Discound",
      code: "SHARERIDEDISCOUNT",
      href: "/share-ride-discount",
    },
  ];
  const recruitment = [
    {
      name: "New",
      code: "NEW_RIDE",
      href: "/new-ride",
    },
    {
      name: "Accepted",
      code: "ACCEPTED_RIDE",
      href: "/accepted-ride",
    },
    {
      name: "Started",
      code: "STARTED_RIDE",
      href: "/started-ride",
    },
    {
      name: "Completed",
      code: "COMPLATED_RIDE",
      href: "/completed-ride",
    },
    {
      name: "Cancelled",
      code: "CANCELLED_RIDE",
      href: "/cancelled-ride",
    },
    {
      name: "Scheduled",
      code: "SCHEDULED_RIDE",
      href: "/scheduled-ride",
    },
  ];

  const history = useHistory();
  const [userData, setUserData] = useState({});
  const [access, setAccess] = useState([]);
  const [serviceAccess, setServiceAccess] = useState(false);
  const [bankAccess, setBankAccess] = useState(false);
  const [trackingAccess, setTrackingAccess] = useState(false);
  const [settingAccess, setSettingAccess] = useState(false);
  const [userTabAccess, setUserTabAccess] = useState(false);
  const [vehicleTabAccess, setVehicleTabAccess] = useState(false);
  const [RideTabAccess, setRideTabAccess] = useState(false);
  const [ContentTabAccess, setContentTabAccess] = useState(false);
  const [ReportTabAccess, setReportTabAccess] = useState(false);
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  function handleSignOut() {
    configuration
      .postAPI({
        url: "auth/sign-out",
        params: { loginlog_id: reactLocalStorage.get("loginlog_id") },
      })
      .then((data) => {
        // console.log(data)
        localStorage.setItem("token", "");
        reactLocalStorage.set("loginlog_id", "");
        reactLocalStorage.set("user_id", "");
        reactLocalStorage.set("email", "");
        history.push("/");
      })
      .catch((error) => {
        // console.log(error)
        // return toast.error(error.message)
      });
  }

  useEffect(() => {
    try {
      var retrievedObject = JSON.parse(localStorage.getItem("token"));
      if (retrievedObject) {
        setUserData(retrievedObject);
        configuration
          .getAPI({
            url: "common/list-access",
            params: { roleID: retrievedObject.roleID },
          })
          .then((data) => {
            if (data.payload) {
              // console.log(data.payload)
              setAccess(data.payload);
              let serviceData = findWhere(data.payload, { code: "SERVICE" });
              let trackingData = findWhere(data.payload, { code: "TRACKING" });
              let settingData = findWhere(data.payload, { code: "SETTING" });
              let bankData = findWhere(data.payload, { code: "BANK" });
              setServiceAccess(serviceData.data.is_view ? true : false);
              setTrackingAccess(trackingData.data.is_view ? true : false);
              setSettingAccess(settingData.data.is_view ? true : false);
              setBankAccess(bankData.data.is_view ? true : false);

              const vehicleData = data.payload.filter(
                (single) =>
                  (single.code === "BRAND" && single?.data?.is_view) ||
                  (single.code === "CAR" && single?.data?.is_view) ||
                  (single.code === "VEHICLE_TYPE" && single?.data?.is_view)
              );
              setVehicleTabAccess(vehicleData.length > 0);

              const userData = data.payload.filter(
                (single) =>
                  (single.code === "USER" && single?.data?.is_view) ||
                  (single.code === "ADMIN_USER" && single?.data?.is_view) ||
                  (single.code === "ROLE" && single?.data?.is_view) ||
                  (single.code === "PERMISSION" && single?.data?.is_view) ||
                  (single.code === "LOG_HISTORY" && single?.data?.is_view)
              );
              setUserTabAccess(userData.length > 0);

              const rideData = data.payload.filter(
                (single) =>
                  (single.code === "NEW_RIDE" && single?.data?.is_view) ||
                  (single.code === "ACCEPTED_RIDE" && single?.data?.is_view) ||
                  (single.code === "STARTED_RIDE" && single?.data?.is_view) ||
                  (single.code === "COMPLATED_RIDE" && single?.data?.is_view) ||
                  (single.code === "CANCELLED_RIDE" && single?.data?.is_view) ||
                  (single.code === "SCHEDULED_RIDE" && single?.data?.is_view)
              );
              setRideTabAccess(rideData.length > 0);

              const contentData = data.payload.filter(
                (single) =>
                  (single.code === "STATIC_PAGES" && single?.data?.is_view) ||
                  (single.code === "LANGUAGE_LABLE" && single?.data?.is_view) ||
                  (single.code === "HELP_CATEGORIES" &&
                    single?.data?.is_view) ||
                  (single.code === "HELP" && single?.data?.is_view) ||
                  (single.code === "RATING_CRITERIAS" &&
                    single?.data?.is_view) ||
                  (single.code === "CANCEL_REASON" && single?.data?.is_view) ||
                  (single.code === "CONTACT_US" && single?.data?.is_view) ||
                  (single.code === "SUBSCRIPTION" && single?.data?.is_view) ||
                  // (single.code === "POINTS" && single?.data?.is_view) ||
                  (single.code === "SHARE_RIDE_DISCOUNT" && single?.data?.is_view)
              );
              setContentTabAccess(contentData.length > 0);

              const reportData = data.payload.filter(
                (single) =>
                  (single.code === "R_SALES" && single?.data?.is_view) ||
                  (single.code === "R_PROFIT" && single?.data?.is_view) ||
                  (single.code === "R_TAX" && single?.data?.is_view) ||
                  (single.code === "R_DRIVER" && single?.data?.is_view) ||
                  (single.code === "R_COMPANY" && single?.data?.is_view)
              );
              setReportTabAccess(reportData.length > 0);
            }
          })
          .catch((error) => {
            console.log(error);
            // return toast.error(error.message)
          });
        // history.push("/dashboard")
      } else {
        history.push("/");
      }
    } catch (err) {
      history.push("/");
    }
  }, []);

  return (
    <>
      <Popover className="bg-skin-white header">
        <div className=" px-4 sm:px-6">
          <div className="flex items-center justify-between  py-1 xl:justify-start xl:space-x-10">
            <div className="flex justify-start gap-10 lg:flex-1">
              <Link className=" xl:block hidden" to="#">
                <span className="sr-only">XLRideshare</span>
                <img className="h-10 w-auto" src={Logo} alt="" />
              </Link>

              <div className="-my-2 -mr-2 xl:hidden">
                <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 outline-none ">
                  <span className="sr-only">Open menu</span>
                  <Bars3BottomLeftIcon className="h-6 w-6" aria-hidden="true" />
                </Popover.Button>
              </div>

              <Popover.Group
                as="nav"
                className="hidden xl:items-center space-x-3 xl:flex"
              >
                <Link
                  to="/dashboard"
                  className="link-tab-white link text-base font-medium text-skin-black"
                >
                  Dashboard
                </Link>
                {/* <Link to="/app-user" className="link text-base font-medium text-skin-white">
                  App Users
                </Link> */}
                {userTabAccess ? (
                  <Menu as="div" className="relative ml-3">
                    {({ open }) => (
                      <>
                        <div>
                          <Menu.Button
                            className={classNames(
                              open
                                ? "link-tab-black link text-base font-medium text-skin-black"
                                : "link-tab-white link text-base font-medium text-skin-black",
                              "group inline-flex items-center text-base font-medium"
                            )}
                          >
                            <span>Users</span>
                            <ChevronDownIcon
                              className={classNames(
                                "text-base font-medium text-skin-black",
                                "ml-2 h-5 w-5"
                              )}
                              aria-hidden="true"
                            />
                          </Menu.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-200"
                          enterFrom="opacity-0 translate-y-1"
                          enterTo="opacity-100 translate-y-0"
                          leave="transition ease-in duration-150"
                          leaveFrom="opacity-100 translate-y-0"
                          leaveTo="opacity-0 translate-y-1"
                        >
                          <Popover.Panel className="absolute z-20  w-max  transform px-2 sm:px-0 lg:ml-0">
                            <div className="overflow-hidden shadow-lg">
                              <div className="relative grid bg-skin-primary min-w-[200px]">
                                {user.map((item) => {
                                  let accessData = findWhere(access, {
                                    code: item.code,
                                  });
                                  if (accessData && accessData.data.is_view) {
                                    return (
                                      <Link
                                        key={item.name}
                                        to={item.href}
                                        className="p-2.5 text-base font-medium link text-base font-medium text-skin-white"
                                      >
                                        {item.name}
                                      </Link>
                                    );
                                  } else {
                                    return "";
                                  }
                                })}
                              </div>
                            </div>
                          </Popover.Panel>
                        </Transition>
                      </>
                    )}
                  </Menu>
                ) : (
                  ""
                )}

                {/* <Popover className="relative">
                  {({ open }) => (
                    <>
                      <Popover.Button
                        className={classNames(
                          open ? 'link-tab-black link text-base font-medium text-skin-black' : 'link-tab-white link text-base font-medium text-skin-black',
                          'group inline-flex items-center text-base font-medium'
                        )}
                      >
                        <span>Users</span>
                        <ChevronDownIcon
                          className={classNames(
                            open ? 'text-base font-medium text-skin-white' : 'text-base font-medium text-skin-black',
                            'ml-2 h-5 w-5'
                          )}
                          aria-hidden="true"
                        />
                      </Popover.Button>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                      >
                        <Popover.Panel className="absolute z-20  w-max  transform px-2 sm:px-0 lg:ml-0">
                          <div className="overflow-hidden shadow-lg">
                            <div className="relative grid bg-skin-primary min-w-[200px]">
                              {user.map((item) => {
                                let accessData = findWhere(access, { code: item.code });
                                if (accessData && accessData.data.is_view) {
                                  return (
                                    <Link

                                      key={item.name}
                                      to={item.href}
                                      className="p-2.5 text-base font-medium link text-base font-medium text-skin-white"
                                    >
                                      {item.name}
                                    </Link>)
                                } else {
                                  return '';
                                }
                              })}
                            </div>
                          </div>
                        </Popover.Panel>
                      </Transition>

                    </>

                  )}
                </Popover> */}
                {serviceAccess ? (
                  <Link
                    to="/service"
                    className="link-tab-white link text-base font-medium text-skin-black"
                  >
                    Services
                  </Link>
                ) : (
                  ""
                )}

                {vehicleTabAccess ? (
                  <Menu as="div" className="relative ml-3">
                    {({ open }) => (
                      <>
                        <div>
                          <Menu.Button
                            className={classNames(
                              open
                                ? "link-tab-black link text-base font-medium text-skin-black"
                                : "link-tab-white link text-base font-medium text-skin-black",
                              "group inline-flex items-center text-base font-medium"
                            )}
                          >
                            <span>Vehicle</span>
                            <ChevronDownIcon
                              className={classNames(
                                "text-base font-medium text-skin-black",
                                "ml-2 h-5 w-5"
                              )}
                              aria-hidden="true"
                            />
                          </Menu.Button>
                        </div>

                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-200"
                          enterFrom="opacity-0 translate-y-1"
                          enterTo="opacity-100 translate-y-0"
                          leave="transition ease-in duration-150"
                          leaveFrom="opacity-100 translate-y-0"
                          leaveTo="opacity-0 translate-y-1"
                        >
                          <Popover.Panel className="absolute z-20  w-max transform px-2 sm:px-0 lg:ml-0">
                            <div className="overflow-hidden shadow-lg">
                              <div className="relative grid bg-skin-primary min-w-[200px]">
                                {vehicle.map((item) => {
                                  let accessData = findWhere(access, {
                                    code: item.code,
                                  });
                                  if (accessData && accessData.data.is_view) {
                                    return (
                                      <Link
                                        key={item.name}
                                        to={item.href}
                                        className="p-2.5 text-base font-medium link text-base font-medium text-skin-white"
                                      >
                                        {item.name}
                                      </Link>
                                    );
                                  } else {
                                    return "";
                                  }
                                })}
                              </div>
                            </div>
                          </Popover.Panel>
                        </Transition>
                      </>
                    )}
                  </Menu>
                ) : (
                  ""
                )}
                {RideTabAccess ? (
                  <Menu as="div" className="relative ml-3">
                    {({ open }) => (
                      <>
                        <div>
                          <Menu.Button
                            className={classNames(
                              open
                                ? "link-tab-black link text-base font-medium text-skin-black"
                                : "link-tab-white link text-base font-medium text-skin-black",
                              "group inline-flex items-center text-base font-medium"
                            )}
                          >
                            <span>Rides</span>
                            <ChevronDownIcon
                              className={classNames(
                                "text-base font-medium text-skin-black",
                                "ml-2 h-5 w-5"
                              )}
                              aria-hidden="true"
                            />
                          </Menu.Button>
                        </div>

                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-200"
                          enterFrom="opacity-0 translate-y-1"
                          enterTo="opacity-100 translate-y-0"
                          leave="transition ease-in duration-150"
                          leaveFrom="opacity-100 translate-y-0"
                          leaveTo="opacity-0 translate-y-1"
                        >
                          <Popover.Panel className="absolute z-20  w-max  transform px-2 sm:px-0 lg:ml-0">
                            <div className="overflow-hidden shadow-lg">
                              <div className="relative grid bg-skin-primary min-w-[200px]">
                                {recruitment.map((item) => {
                                  let accessData = findWhere(access, {
                                    code: item.code,
                                  });
                                  if (accessData && accessData.data.is_view) {
                                    return (
                                      <Link
                                        key={item.name}
                                        to={item.href}
                                        className="p-2.5 text-base font-medium link text-base font-medium text-skin-white"
                                      >
                                        {item.name}
                                      </Link>
                                    );
                                  } else {
                                    return "";
                                  }
                                })}
                              </div>
                            </div>
                          </Popover.Panel>
                        </Transition>
                      </>
                    )}
                  </Menu>
                ) : (
                  ""
                )}
                {ContentTabAccess ? (
                  <Menu as="div" className="relative ml-3">
                    {({ open }) => (
                      <>
                        <div>
                          <Menu.Button
                            className={classNames(
                              open
                                ? "link-tab-black link text-base font-medium text-skin-black"
                                : "link-tab-white link text-base font-medium text-skin-black",
                              "group inline-flex items-center text-base font-medium"
                            )}
                          >
                            <span>Content</span>
                            <ChevronDownIcon
                              className={classNames(
                                "text-base font-medium text-skin-black",
                                "ml-2 h-5 w-5"
                              )}
                              aria-hidden="true"
                            />
                          </Menu.Button>
                        </div>

                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-200"
                          enterFrom="opacity-0 translate-y-1"
                          enterTo="opacity-100 translate-y-0"
                          leave="transition ease-in duration-150"
                          leaveFrom="opacity-100 translate-y-0"
                          leaveTo="opacity-0 translate-y-1"
                        >
                          <Popover.Panel className="absolute z-20  w-max  transform px-2 sm:px-0 lg:ml-0">
                            <div className="overflow-hidden shadow-lg">
                              <div className="relative grid bg-skin-primary min-w-[200px]">
                                {content.map((item) => {
                                  let accessData = findWhere(access, {
                                    code: item.code,
                                  });
                                  if (accessData && accessData.data.is_view) {
                                    return (
                                      <Link
                                        key={item.name}
                                        to={item.href}
                                        className="p-2.5 text-base font-medium link text-base font-medium text-skin-white"
                                      >
                                        {item.name}
                                      </Link>
                                    );
                                  } else {
                                    return "";
                                  }
                                })}
                              </div>
                            </div>
                          </Popover.Panel>
                        </Transition>
                      </>
                    )}
                  </Menu>
                ) : (
                  ""
                )}
                {ReportTabAccess ? (
                  <Menu as="div" className="relative ml-3">
                    {({ open }) => (
                      <>
                        <div>
                          <Menu.Button
                            className={classNames(
                              open
                                ? "link-tab-black link text-base font-medium text-skin-black"
                                : "link-tab-white link text-base font-medium text-skin-black",
                              "group inline-flex items-center text-base font-medium"
                            )}
                          >
                            <span>Reports</span>
                            <ChevronDownIcon
                              className={classNames(
                                "text-base font-medium text-skin-black",
                                "ml-2 h-5 w-5"
                              )}
                              aria-hidden="true"
                            />
                          </Menu.Button>
                        </div>

                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-200"
                          enterFrom="opacity-0 translate-y-1"
                          enterTo="opacity-100 translate-y-0"
                          leave="transition ease-in duration-150"
                          leaveFrom="opacity-100 translate-y-0"
                          leaveTo="opacity-0 translate-y-1"
                        >
                          <Popover.Panel className="absolute z-20  w-max  transform px-2 sm:px-0 lg:ml-0">
                            <div className="overflow-hidden shadow-lg">
                              <div className="relative grid bg-skin-primary min-w-[200px]">
                                {report.map((item) => {
                                  let accessData = findWhere(access, {
                                    code: item.code,
                                  });
                                  if (accessData && accessData.data.is_view) {
                                    return (
                                      <Link
                                        key={item.name}
                                        to={item.href}
                                        className="p-2.5 text-base font-medium link text-base font-medium text-skin-white"
                                      >
                                        {item.name}
                                      </Link>
                                    );
                                  } else {
                                    return "";
                                  }
                                })}
                              </div>
                            </div>
                          </Popover.Panel>
                        </Transition>
                      </>
                    )}
                  </Menu>
                ) : (
                  ""
                )}
                {bankAccess ? (
                  <Link
                    to="/bank"
                    className="link-tab-white link text-base font-medium text-skin-black"
                  >
                    Bank
                  </Link>
                ) : (
                  ""
                )}
                {trackingAccess ? (
                  <Link
                    to="/tracker"
                    className="text-base font-medium link text-base font-medium text-skin-black"
                  >
                    Live Tracking
                  </Link>
                ) : (
                  ""
                )}
              </Popover.Group>
            </div>

            <div className="flex items-center">
              <div className="">
                <Link
                  to="/adminland"
                  className="btn-link mt-1 inline-flex w-max items-center justify-center rounded-md border-2 border-skin-secondary px-5 py-2 text-[16px] font-medium text-red shadow-sm text-skin-secondary sm:w-auto"
                >
                  Adminland
                </Link>
              </div>
              <Menu as="div" className="relative ml-3">
                <div>
                  <Menu.Button className="flex gap-3 pl-3 max-w-xs items-center rounded-full text-base outline-none">
                    {/* <img
                      className="h-10 w-10 rounded-full"
                      src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                      alt=""
                    /> */}
                    <div className="flex items-center">
                      <div>
                        <img
                          className="inline-block h-9 w-9 rounded-full"
                          src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                          alt=""
                        />
                      </div>
                      <div className="ml-3">
                        <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900">
                          {userData.name}
                        </p>
                        <p className="text-xs text-left font-medium text-gray-500 group-hover:text-gray-700">
                          {userData.roleTitle}
                        </p>
                      </div>
                    </div>
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  {/* <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    {userNavigation.map((item) => (
                      <Menu.Item key={item.name}>
                        {({ active }) => (
                          <Link
                            to={item.href}
                            className={classNames(
                              active ? 'bg-gray-100' : '',
                              'block px-4 py-2 text-sm text-skin-secondary'
                            )}
                          >
                            {item.name}
                          </Link>
                        )}
                      </Menu.Item>
                    ))}
                  </Menu.Items> */}
                  <Popover.Panel className="absolute z-10 right-0 transform  mt-3 px-2 w-max	 sm:px-0 w-48">
                    <div className="rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                      <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-2 sm:p-4">
                        <Link
                          to={`/user/edit/${userData.user_id}`}
                          className="text-skin-primary hover:bg-skin-primary hover:text-white group flex items-center px-2 py-2 text-base font-medium rounded-md"
                        >
                          <UserIcon
                            className="mr-4 flex-shrink-0 h-6 w-6 text-skin-primary group-hover:text-skin-white"
                            aria-hidden="true"
                          />
                          Your Profile
                        </Link>
                        {settingAccess ? (
                          <Link
                            to={`/personal-settings`}
                            className="text-skin-primary hover:bg-skin-primary hover:text-white group flex items-center px-2 py-2 text-base font-medium rounded-md"
                          >
                            <Cog8ToothIcon
                              className="mr-4 flex-shrink-0 h-6 w-6 text-skin-primary group-hover:text-skin-white"
                              aria-hidden="true"
                            />
                            Settings
                          </Link>
                        ) : (
                          ""
                        )}
                        {/* <Link
                          to={`/personal-settings`}
                          className='link py-2 text-sm text-skin-primary'
                        >
                          Settings
                        </Link> */}
                        <button
                          type="button"
                          onClick={handleSignOut}
                          className="text-skin-primary hover:bg-skin-primary hover:text-white group flex items-center px-2 py-2 text-base font-medium rounded-md"
                        >
                          <ArrowLeftOnRectangleIcon
                            className="mr-4 flex-shrink-0 h-6 w-6 text-skin-primary group-hover:text-skin-white"
                            aria-hidden="true"
                          />
                          Sign Out
                        </button>
                        {/* <button
                          type="button"
                          onClick={handleSignOut}
                          className='link py-2 text-sm text-skin-primary text-left'
                        >
                          Sign Out
                        </button> */}
                      </div>
                    </div>
                  </Popover.Panel>
                </Transition>
              </Menu>
            </div>
          </div>
        </div>

        <Transition
          as={Fragment}
          enter="duration-200 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel
            focus
            className="absolute z-20 inset-x-0 top-0 origin-top-right transform p-2 transition bg-skin-primary xl:hidden"
          >
            <div className="flex items-center justify-between px-4 py-4 ">
              <div>
                <Link to="#">
                  <span className="sr-only">Qgo</span>
                  <img className="h-10 w-auto" src={Logo} alt="" />
                </Link>
              </div>
              <div className="-mr-2">
                <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 outline-none ">
                  <span className="sr-only">Close menu</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </Popover.Button>
              </div>
            </div>
            <div className="w-full pt-5">
              <Link
                to="/dashboard"
                className="text-base font-medium link text-base font-medium text-skin-white"
              >
                Dashboard
              </Link>
            </div>
            <div className="w-full pt-5">
              <Link
                to="/app-users"
                className="text-base font-medium link text-base font-medium text-skin-white"
              >
                App Users
              </Link>
            </div>

            <dl className="">
              {recruitmentmobile.map((faq) => (
                <Disclosure as="div" key={faq.question} className="pt-6">
                  {({ open }) => (
                    <>
                      <dt className="text-lg">
                        <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-400">
                          <span className="text-base font-medium link text-base font-medium text-skin-white">
                            {faq.question}
                          </span>
                          <span className="ml-6 flex h-7 items-center">
                            <ChevronDownIcon
                              className={classNames(
                                open ? "-rotate-180" : "rotate-0",
                                "h-6 w-6 transform text-skin-white"
                              )}
                              aria-hidden="true"
                            />
                          </span>
                        </Disclosure.Button>
                      </dt>
                      <Disclosure.Panel as="dd" className="mt-2 pr-12">
                        {recruitment.map((item) => (
                          <Link
                            key={item.name}
                            to={item.href}
                            className="p-2.5 text-base block font-medium link text-base font-medium text-skin-white"
                          >
                            {item.name}
                          </Link>
                        ))}
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              ))}
            </dl>
            {/* <div className='w-full py-5'>
              <Link to="#contact" className="text-base font-medium link text-base font-medium text-skin-white">
                Interviews
              </Link>
            </div> */}
          </Popover.Panel>
        </Transition>
      </Popover>
    </>
  );
}

export default Header;
