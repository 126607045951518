import React, { useEffect, useState } from "react";
import Breadcrumb from '../../components/breadcrumb';
import labels from "../../constants/EmailTemplate";
import Label from "../../components/Form/Label";
import TextInput from "../../components/Form/TextInput";
import ButtonSection from "../../components/Form/ButtonSection";
import Dropdown from "../../components/Form/Dropdown";
import TinyEditor from "../../components/Form/TinyEditor";
import configuration from '../../config';
import { toast } from 'react-toastify';
import { each, findWhere } from 'underscore';
import Loader from '../../components/Loader/Loader';
export default function View({ history }) {
    const options = [
        { id: 1, title: "Active", value: "active" },
        { id: 2, title: "Inactive", value: "inactive" },
    ];
    const [languages, setLanguages] = useState([])
    const [fields, setFields] = useState({});
    const [errors, setErrors] = useState({});
    const [isValid, setIsValid] = useState(false)
    const [authorisation, setAuthorisation] = useState()
    useEffect(() => {
        configuration.getAPI({ url: 'language/list', params: { status: 'active' } }).then((data) => {
            // console.log(data)
            if (data.payload) {
                setLanguages(data.payload)
            }
        }).catch(error => {
            // console.log(error)
            return toast.error(error.message)
        });
    }, []);

    function validation() {
        let flag = true;
        let error = {}
        each(languages, (lang, ind) => {
            if (!fields['email_subject_' + lang.code]) {
                error['email_subject_' + lang.code] = "Please enter email subject in " + lang.title
                flag = false
            }
            if (!fields['description_' + lang.code]) {
                error['description_' + lang.code] = "Please enter description in " + lang.title
                flag = false
            }
        })
        if (!fields.template_code) {
            error['template_code'] = "Please enter code"
            flag = false
        }
        if (!fields.title) {
            error['title'] = "Please enter title"
            flag = false
        }
        if (!fields.from_name) {
            error['from_name'] = "Please enter from name"
            flag = false
        }
        if (!fields.from_email) {
            error['from_email'] = "Please enter from email"
            flag = false
        }
        if (!fields.status) {
            error['status'] = "Please select status"
            flag = false
        }
        setErrors({ ...error })
        return flag;
    }

    const handleSubmit = () => {
        if (validation()) {
            let questionObj = {}
            let answerObj = {}
            each(languages, (lang, ind) => {
                questionObj[lang.code] = fields['email_subject_' + lang.code] ? fields['email_subject_' + lang.code] : '';
                answerObj[lang.code] = fields['description_' + lang.code] ? fields['description_' + lang.code] : '';
                delete fields['email_subject_' + lang.code];
                delete fields['description_' + lang.code];
            })
            fields.email_subject = questionObj;
            fields.description = answerObj;
            configuration.allAPI({ url: 'email-template/create', method: 'put', params: fields }).then((data) => {
                // console.log(data)
                if (data.payload) {
                    history.push("/email-template")
                } else if (data.error) {
                    return toast.error(data.error.message)
                } else {
                    return toast.error('Something went wrong')
                }
            }).catch(error => {
                // console.log(error)
                return toast.error(error.message)
            });
        }
    }

    useEffect(() => {
        var retrievedObject = JSON.parse(localStorage.getItem('token'));
        if (retrievedObject) {
            configuration.getAPI({ url: 'common/list-access', params: { roleID: retrievedObject.roleID } }).then((data) => {
                if (data.payload) {
                    setAuthorisation(data.payload)
                }
            }).catch(error => {
                return toast.error(error.message)
            });
        }
    }, []);
    useEffect(() => {
        const whereFind = (findWhere(authorisation, { code: 'EMAIL_T' }))
        if (whereFind?.data?.is_add === false) {
            setIsValid(!isValid)
            history.push('/dashboard')
        }
        if (whereFind?.data?.is_add === true) {
            setIsValid(!isValid)
        }
    }, [authorisation])

    return (
        < >
            {isValid ? <>  <Breadcrumb module={labels.addEmailTemplate} />
                <div className='main dark:bg-black bg-white mb-5'>
                    <div className="dark:bg-black bg-white">
                        <div className="py-5 px-5 flex items-center justify-between">
                            <h1 className="text-3xl font-bold tracking-tight dark:text-[#fff] text-black">{labels.addEmailTemplate}</h1>
                        </div>

                        <div className="mt-5 sm:mx-auto sm:w-full px-4">
                            <div className="bg-skin-white py-5 px-5 shadow sm:rounded-lg">
                                <form className="space-y-6" action="#" method="POST">
                                    <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
                                        <div>
                                            <Label title="Title" />
                                            <TextInput
                                                id={'title'}
                                                name={'title'}
                                                handleChange={(field, e) =>
                                                    setFields({ ...fields, [field]: e.target.value })}
                                                value={fields['title']}
                                            />
                                            {errors.title ? (
                                                <Label title="title" fieldError={errors.title} />
                                            ) : null}
                                        </div>
                                        <div>
                                            <Label title="Code" />
                                            <TextInput
                                                id={'template_code'}
                                                name={'template_code'}
                                                handleChange={(field, e) =>
                                                    setFields({ ...fields, [field]: e.target.value })}
                                                value={fields['template_code']}
                                            />
                                            {errors.template_code ? (
                                                <Label title="template_code" fieldError={errors.template_code} />
                                            ) : null}
                                        </div>
                                        <div>
                                            <Label title="From Name" />
                                            <TextInput
                                                id={'from_name'}
                                                name={'from_name'}
                                                handleChange={(field, e) =>
                                                    setFields({ ...fields, [field]: e.target.value })}
                                                value={fields['from_name']}
                                            />
                                            {errors.from_name ? (
                                                <Label title="from_name" fieldError={errors.from_name} />
                                            ) : null}
                                        </div>
                                        <div>
                                            <Label title="From Email" />
                                            <TextInput
                                                id={'from_email'}
                                                name={'from_email'}
                                                handleChange={(field, e) =>
                                                    setFields({ ...fields, [field]: e.target.value })}
                                                value={fields['from_email']}
                                            />
                                            {errors.from_email ? (
                                                <Label title="from_email" fieldError={errors.from_email} />
                                            ) : null}
                                        </div>
                                        {languages.map((single) => {
                                            return <div>
                                                <Label title={`Email Subject [${single.title}]`} />
                                                <TextInput
                                                    id={`email_subject_${single.code}`}
                                                    name={`email_subject_${single.code}`}
                                                    handleChange={(field, e) =>
                                                        setFields({ ...fields, [field]: e.target.value })}
                                                    value={fields[`email_subject_${single.code}`]}
                                                />
                                                {errors[`email_subject_${single.code}`] ? (
                                                    <Label title={`email_subject_${single.code}`} fieldError={errors[`email_subject_${single.code}`]} />
                                                ) : null}
                                            </div>
                                        })}
                                        <div>
                                            <Label title="Status" />
                                            <Dropdown
                                                id="status"
                                                name="status"
                                                title="status"
                                                value={fields.status}
                                                status={options}
                                                handleChange={(field, e) =>
                                                    setFields({ ...fields, [field]: e.target.value })}
                                            />
                                            {errors.status ? (
                                                <Label title="status" fieldError={errors.status} />
                                            ) : null}
                                        </div>
                                    </div>
                                    <div>
                                        {languages.map((single) => {
                                            return <div>
                                                <Label title={`Description [${single.title}]`} />
                                                <TinyEditor
                                                    className=""
                                                    name={`description_${single.code}`}
                                                    value={fields[`description_${single.code}`]}
                                                    handleChange={(field, e) =>
                                                        setFields({ ...fields, [field]: e.target.getContent() })}
                                                />
                                                {errors[`description_${single.code}`] ? (
                                                    <Label title={`description_${single.code}`} fieldError={errors[`description_${single.code}`]} />
                                                ) : null}
                                            </div>
                                        })}

                                    </div>
                                    <ButtonSection path={'/email-template'} handleSubmit={handleSubmit} />
                                </form>
                            </div>
                        </div>
                    </div>
                </div> </> : <> <Loader /> </>}

        </>
    )
}
