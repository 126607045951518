/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import Breadcrumb from '../../components/breadcrumb';
import labels from "../../constants/Dashboard";
import { ClipboardDocumentCheckIcon, InformationCircleIcon, BriefcaseIcon, ClockIcon, CheckCircleIcon, TrophyIcon, XCircleIcon, TrashIcon, ArrowUpCircleIcon, AdjustmentsHorizontalIcon, ArrowTrendingUpIcon } from '@heroicons/react/24/outline';
import {
    Chart,
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip
} from 'chart.js';
import { useHistory } from "react-router-dom";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import configuration from "../../config";
import { toast } from "react-toastify";


import { Bar, Line, Doughnut } from "react-chartjs-2";

Chart.register(
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip
);




// export default function Dashboard({ history }) {
const Tabs = ({ color }) => {
    // const appUsers = [
    //     { id: 1, name: 'Passenger ', stat: '200', icon: ArrowUpCircleIcon, },
    //     { id: 2, name: 'Driver', stat: '140', icon: ClockIcon, },
    //     { id: 3, name: 'Active', stat: '280', icon: ClipboardDocumentCheckIcon, },
    //     { id: 3, name: 'Inactive', stat: '60', icon: XCircleIcon, },
    // ]
    // const rides = [
    //     { id: 1, name: 'New ', stat: '24', icon: ArrowTrendingUpIcon, },
    //     { id: 2, name: 'Accepted', stat: '10', icon: ArrowTrendingUpIcon, },
    //     { id: 3, name: 'Started', stat: '22', icon: ArrowTrendingUpIcon, },
    //     { id: 4, name: 'Completed', stat: '250', icon: ArrowTrendingUpIcon, },
    //     { id: 5, name: 'Cancelled', stat: '20', icon: ArrowTrendingUpIcon, },
    //     { id: 6, name: 'Scheduled', stat: '12', icon: ArrowTrendingUpIcon, },
    // ]
    // const category = [
    //     { id: 1, name: 'Active ', stat: '3', icon: CheckCircleIcon, },
    //     { id: 2, name: 'Inactive', stat: '2', icon: XCircleIcon, },
    // ]
    // const brand = [
    //     { id: 1, name: 'Active ', stat: '20', icon: CheckCircleIcon, },
    //     { id: 2, name: 'Inactive', stat: '5', icon: XCircleIcon, },
    // ]
    // const rideOption = [
    //     { id: 1, name: 'Active ', stat: '5', icon: CheckCircleIcon, },
    //     { id: 2, name: 'Inactive', stat: '0', icon: XCircleIcon, },
    // ]
    // const services = [
    //     { id: 1, name: 'Active ', stat: '6', icon: CheckCircleIcon, },
    //     { id: 2, name: 'Inactive', stat: '1', icon: XCircleIcon, },
    // ]
    const options = {
        maintainAspectRatio: true,	// Don't maintain w/h ratio
    }
    // const data = {
    //     labels: ['Taxi Booking', 'Delivery',],
    //     datasets: [
    //         {

    //             data: [20, 80],
    //             backgroundColor: [
    //                 '#f1f1f1',
    //                 '#262626',

    //             ],
    //             borderColor: [
    //                 '#0A0A0A',
    //                 '#262626',

    //             ],
    //             borderWidth: 2,
    //         },

    //     ],
    //     maintainAspectRatio: false,
    // };
    // const data2 = {
    //     labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "July", "Aug", "Sep", "Oct", "Nov", "Dec"],
    //     datasets: [
    //         {
    //             label: "Total Sales",
    //             data: [33, 53, 85, 41, 44, 65, 100, 33, 53, 85, 41, 44, 65,],
    //             fill: true,
    //             color: '#FFFFFF',
    //             backgroundColor: "#f1f1f1",
    //             borderColor: "#0A0A0A",
    //             borderWidth: 2,
    //         }

    //     ],
    // };
    const [openTab, setOpenTab] = React.useState(1);
    const history = useHistory();
    const [Profit, setProfit] = useState([33, 53, 85, 41, 44, 65, 100, 33, 53, 85, 41, 44, 65]);
    const [Sales, setSales] = useState([33, 53, 85, 41, 44, 65, 100, 33, 53, 85, 41, 44, 65]);
    const [Tax, setTax] = useState([33, 53, 85, 41, 44, 65, 100, 33, 53, 85, 41, 44, 65]);
    const [years, setYears] = useState([]);
    const [salesYear, setSalesYear] = useState('');
    const [taxYear, setTaxYear] = useState('');
    const [serviceYear, setServiceYear] = useState('');
    const [serviceLable, setServiceLable] = useState(['Taxi Booking', 'Delivery']);
    const [serviceData, setServiceData] = useState([20, 80]);
    const [profitYear, setProfitYear] = useState('');
    // const [currency, setCurrency] = useState('$');
    const [Statistics, setStatistics] = useState({ adminUser: 12, serviceCount: 3, rideCount: 5, appUser: 853, brandCount: 16, emailCount: 15, pushCount: 12, smsCount: 16, languageCount: 2, ratingCount: 8, subscriptionCount: 14, taxCount: 2, currency: '$', approveDrivers: 0, notApproveDrivers: 0 });
    const [subStatistics, setSubStatistics] = useState([{ "id": 2, "name": "Pending ", "stat": 1 }, { "id": 3, "name": "Accepted", "stat": 0 }, { "id": 3, "name": "Pickedup", "stat": 1 }, { "id": 3, "name": "Started", "stat": 4 }, { "id": 3, "name": "Completed", "stat": 29920 }, { "id": 3, "name": "Cancelled", "stat": 5798 }]);

    useEffect(() => {
        try {
            var retrievedObject = JSON.parse(localStorage.getItem('token'));
            // console.log(retrievedObject);
            if (!retrievedObject) {
                history.push("/")
            }
        } catch (err) {
            history.push("/")
        }
        setSalesYear(new Date().getFullYear())
        setProfitYear(new Date().getFullYear())
        setTaxYear(new Date().getFullYear())
        setServiceYear(new Date().getFullYear())
        const startYear = 2022;
        const endYear = new Date().getFullYear();
        for (let i = startYear; i <= endYear; i++) {
            years.push(i);
        }
        setYears(years)
        configuration.getAPI({ url: 'dashboard/summary', params: {} }).then((data) => {
            setStatistics(data.payload || []);
        }).catch(error => {
            // console.log(error)
            return toast.error(error.message)
        });
        totalSales('sales', new Date().getFullYear())
        totalSales('profit', new Date().getFullYear())
        totalSales('tax', new Date().getFullYear())
        totalSales('service', new Date().getFullYear())
        getSubSummary('trip')
    }, []);

    function handleChange(type, e) {
        if (type === 'sales') {
            setSalesYear(e.target.value);
        } else if (type === 'tax') {
            setTaxYear(e.target.value);
        } else if (type === 'service') {
            setServiceYear(e.target.value);
        } else {
            setProfitYear(e.target.value)
        }
        totalSales(type, e.target.value);
    }
    function getSubSummary(user_type) {
        configuration.getAPI({ url: 'dashboard/sub-summary', params: { user_type } }).then((data) => {
            setSubStatistics(data.payload || []);
        }).catch(error => {
            // console.log(error)
            return toast.error(error.message)
        });
    }

    function totalSales(type, year) {
        let apiURL = type === 'service' ? 'dashboard/services' : 'dashboard/total-sales';
        configuration.getAPI({ url: apiURL, params: { type, year } }).then((data) => {
            if (type === 'sales') {
                setSales(data.payload || []);
            }
            if (type === 'profit') {
                setProfit(data.payload || []);
            }
            if (type === 'tax') {
                setTax(data.payload || []);
            }
            if (type === 'service') {
                setServiceLable(data.payload.serviceLable || []);
                setServiceData(data.payload.serviceData || []);
            }
        }).catch(error => {
            // console.log(error)
            return toast.error(error.message)
        });
    }

    function handleChangeTab(type, id, e) {
        console.log(id)
        // setSubStatistics([]);
        getSubSummary(type)
        setOpenTab(id);
    }

    const Box = ({ children }) => (
        <div key={children} className="relative bg-gray-50 pt-5 px-6 pb-6  shadow rounded-lg overflow-hidden">
            <dt className="text-base font-medium text-red-600 truncate">{children}</dt>
            <dd className="mt-1 text-3xl font-semibold text-gray-900">{children}</dd>
        </div>
    );
    return (
        <>
            {/* <HeaderInner /> */}
            <Breadcrumb module={labels.dashboard} />
            <div className='main bg-skin-white'>
                <div className="bg-skin-white">
                    <div className="py-8 px-5">
                        <h1 className="text-3xl font-bold tracking-tight text-skin-primary">Dashboard</h1>
                    </div>

                    <div className="grid grid-cols-1 lg:grid-cols-2">
                        <div className="px-3 py-3 ">
                            <div className="px-4 py-4 bg-skin-gray shadow-md rounded-md">
                                <div className='flex items-center justify-between min-h-[40px]'>
                                    <h3 className="text-lg leading-6 font-medium text-skin-primary">Total Sales</h3>
                                    <select
                                        id="country10"
                                        name="country10"
                                        autoComplete="country10-name"
                                        onChange={(e) => handleChange('sales', e)}
                                        value={salesYear}
                                        className="block w-48 rounded-[8px]  border-[1.5px] border-skin-light py-1 px-4 text-skin-primary  outline-0 bg-transparent"
                                    >
                                        {
                                            years.map((e, key) => {
                                                return <option key={key} value={e}>{e}</option>;
                                            })
                                        }
                                    </select>
                                </div>
                                <dl className="mt-5 grid grid-cols-1 gap-5">
                                    <div className="relative pt-4 px-4 pb-4  shadow rounded-lg bg-skin-white overflow-hidden">
                                        {/* <Bar data={data2} height={174} options={options} /> */}
                                        <Bar data={{
                                            labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "July", "Aug", "Sep", "Oct", "Nov", "Dec"],
                                            datasets: [
                                                {
                                                    label: `Sales Revenue (${Statistics.currency})`,
                                                    data: Sales,
                                                    fill: true,
                                                    color: '#FFFFFF',
                                                    backgroundColor: "#f1f1f1",
                                                    borderColor: "#0A0A0A",
                                                    borderWidth: 2,
                                                }
                                            ]
                                        }} height={174} />
                                    </div>
                                </dl>
                            </div>
                        </div>
                        <div className="px-3 py-3 ">
                            <div className="px-4 py-4 bg-skin-gray shadow-md rounded-md">
                                {/* <div className='flex items-center justify-between min-h-[40px]'>
                                    <h3 className="text-lg leading-6 font-medium text-skin-primary">Services</h3>

                                </div> */}
                                <div className='flex items-center justify-between min-h-[40px]'>
                                    <h3 className="text-lg leading-6 font-medium text-skin-primary">Services</h3>
                                    <select
                                        id="country10"
                                        name="country10"
                                        autoComplete="country10-name"
                                        onChange={(e) => handleChange('service', e)}
                                        value={serviceYear}
                                        className="block w-48 rounded-[8px]  border-[1.5px] border-skin-light py-1 px-4 text-skin-primary  outline-0 bg-transparent"
                                    >
                                        {
                                            years.map((e, key) => {
                                                return <option key={key} value={e}>{e}</option>;
                                            })
                                        }
                                    </select>
                                </div>
                                <dl className="mt-5 grid grid-cols-1 gap-5">
                                    <div className="relative pt-4 px-4 pb-4  shadow rounded-lg bg-skin-white overflow-hidden">
                                        <Doughnut data={{
                                            labels: serviceLable,
                                            datasets: [
                                                {

                                                    data: serviceData,
                                                    backgroundColor: [
                                                        '#f1f1f1',
                                                        '#262626',

                                                    ],
                                                    borderColor: [
                                                        '#0A0A0A',
                                                        '#262626',

                                                    ],
                                                    borderWidth: 2,
                                                },

                                            ],
                                            maintainAspectRatio: false,
                                        }} height={409} options={{ responsive: true, maintainAspectRatio: false }} />
                                    </div>
                                </dl>
                            </div>
                        </div>

                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-1">
                        <div className="px-3 py-3 ">
                            <div className="px-4 py-4 bg-white shadow-md rounded-md">
                                <dl className="grid grid-cols-1 gap-5 sm:grid-cols-3 lg:grid-cols-6">

                                    <div className="relative bg-gray-50 pt-5 px-6 pb-6  shadow rounded-lg overflow-hidden">
                                        <dt>
                                            <div className="absolute bg-skin-secondary rounded-md p-3">
                                                <ClockIcon className="h-5 w-5 text-skin-white" aria-hidden="true" />
                                            </div>
                                            <p className="ml-16 text-basefont-medium text-skin-primary truncate">App Users</p>
                                        </dt>
                                        <dd className="ml-16 flex items-baseline">
                                            <p className="text-2xl font-semibold text-gray-900">{(Statistics.appUser) ? Statistics.appUser : <Skeleton width={40} height={25} />}</p>
                                        </dd>
                                    </div>
                                    <div className="relative bg-gray-50 pt-5 px-6 pb-6  shadow rounded-lg overflow-hidden">
                                        <dt>
                                            <div className="absolute bg-skin-secondary rounded-md p-3">
                                                <ClockIcon className="h-5 w-5 text-skin-white" aria-hidden="true" />
                                            </div>
                                            <p className="ml-16 text-basefont-medium text-skin-primary truncate">Admin Users</p>
                                        </dt>
                                        <dd className="ml-16 flex items-baseline">
                                            <p className="text-2xl font-semibold text-gray-900">{(Statistics.adminUser) ? Statistics.adminUser : <Skeleton width={40} height={25} />}</p>
                                        </dd>
                                    </div>
                                    <div className="relative bg-gray-50 pt-5 px-6 pb-6  shadow rounded-lg overflow-hidden">
                                        <dt>
                                            <div className="absolute bg-skin-secondary rounded-md p-3">
                                                <ClockIcon className="h-5 w-5 text-skin-white" aria-hidden="true" />
                                            </div>
                                            <p className="ml-16 text-basefont-medium text-skin-primary truncate">Services</p>
                                        </dt>
                                        <dd className="ml-16 flex items-baseline">
                                            <p className="text-2xl font-semibold text-gray-900">{(Statistics.serviceCount) ? Statistics.serviceCount : <Skeleton width={40} height={25} />}</p>
                                        </dd>
                                    </div>
                                    <div className="relative bg-gray-50 pt-5 px-6 pb-6  shadow rounded-lg overflow-hidden">
                                        <dt>
                                            <div className="absolute bg-skin-secondary rounded-md p-3">
                                                <ClockIcon className="h-5 w-5 text-skin-white" aria-hidden="true" />
                                            </div>
                                            <p className="ml-16 text-basefont-medium text-skin-primary truncate">Ride Options</p>
                                        </dt>
                                        <dd className="ml-16 flex items-baseline">
                                            <p className="text-2xl font-semibold text-gray-900">{(Statistics.rideCount) ? Statistics.rideCount : <Skeleton width={50} height={25} />}</p>
                                        </dd>
                                    </div>
                                    <div className="relative bg-gray-50 pt-5 px-6 pb-6  shadow rounded-lg overflow-hidden">
                                        <dt>
                                            <div className="absolute bg-skin-secondary rounded-md p-3">
                                                <ClockIcon className="h-5 w-5 text-skin-white" aria-hidden="true" />
                                            </div>
                                            <p className="ml-16 text-basefont-medium text-skin-primary truncate">Brands</p>
                                        </dt>
                                        <dd className="ml-16 flex items-baseline">
                                            <p className="text-2xl font-semibold text-gray-900">{(Statistics.brandCount) ? Statistics.brandCount : <Skeleton width={60} height={25} />}</p>
                                        </dd>
                                    </div>
                                    <div className="relative bg-gray-50 pt-5 px-6 pb-6  shadow rounded-lg overflow-hidden">
                                        <dt>
                                            <div className="absolute bg-skin-secondary rounded-md p-3">
                                                <ClockIcon className="h-5 w-5 text-skin-white" aria-hidden="true" />
                                            </div>
                                            <p className="ml-16 text-basefont-medium text-skin-primary truncate">Language</p>
                                        </dt>
                                        <dd className="ml-16 flex items-baseline">
                                            <p className="text-2xl font-semibold text-gray-900">{(Statistics.languageCount) ? Statistics.languageCount : <Skeleton width={60} height={25} />}</p>
                                        </dd>
                                    </div>
                                    <div className="relative bg-gray-50 pt-5 px-6 pb-6  shadow rounded-lg overflow-hidden">
                                        <dt>
                                            <div className="absolute bg-skin-secondary rounded-md p-3">
                                                <ClockIcon className="h-5 w-5 text-skin-white" aria-hidden="true" />
                                            </div>
                                            <p className="ml-16 text-basefont-medium text-skin-primary truncate">Email Template</p>
                                        </dt>
                                        <dd className="ml-16 flex items-baseline">
                                            <p className="text-2xl font-semibold text-gray-900">{(Statistics.emailCount) ? Statistics.emailCount : <Skeleton width={45} height={25} />}</p>
                                        </dd>
                                    </div>
                                    <div className="relative bg-gray-50 pt-5 px-6 pb-6  shadow rounded-lg overflow-hidden">
                                        <dt>
                                            <div className="absolute bg-skin-secondary rounded-md p-3">
                                                <ClockIcon className="h-5 w-5 text-skin-white" aria-hidden="true" />
                                            </div>
                                            <p className="ml-16 text-basefont-medium text-skin-primary truncate">Push Template</p>
                                        </dt>
                                        <dd className="ml-16 flex items-baseline">
                                            <p className="text-2xl font-semibold text-gray-900">{(Statistics.pushCount) ? Statistics.pushCount : <Skeleton width={45} height={25} />}</p>
                                        </dd>
                                    </div>
                                    <div className="relative bg-gray-50 pt-5 px-6 pb-6  shadow rounded-lg overflow-hidden">
                                        <dt>
                                            <div className="absolute bg-skin-secondary rounded-md p-3">
                                                <ClockIcon className="h-5 w-5 text-skin-white" aria-hidden="true" />
                                            </div>
                                            <p className="ml-16 text-basefont-medium text-skin-primary truncate">SMS Template</p>
                                        </dt>
                                        <dd className="ml-16 flex items-baseline">
                                            <p className="text-2xl font-semibold text-gray-900">{(Statistics.smsCount) ? Statistics.smsCount : <Skeleton width={40} height={25} />}</p>
                                        </dd>
                                    </div>
                                    <div className="relative bg-gray-50 pt-5 px-6 pb-6  shadow rounded-lg overflow-hidden">
                                        <dt>
                                            <div className="absolute bg-skin-secondary rounded-md p-3">
                                                <ClockIcon className="h-5 w-5 text-skin-white" aria-hidden="true" />
                                            </div>
                                            <p className="ml-16 text-basefont-medium text-skin-primary truncate">Subscription</p>
                                        </dt>
                                        <dd className="ml-16 flex items-baseline">
                                            <p className="text-2xl font-semibold text-gray-900">{(Statistics.subscriptionCount) ? Statistics.subscriptionCount : <Skeleton width={40} height={25} />}</p>
                                        </dd>
                                    </div>
                                    <div className="relative bg-gray-50 pt-5 px-6 pb-6  shadow rounded-lg overflow-hidden">
                                        <dt>
                                            <div className="absolute bg-skin-secondary rounded-md p-3">
                                                <ClockIcon className="h-5 w-5 text-skin-white" aria-hidden="true" />
                                            </div>
                                            <p className="ml-16 text-basefont-medium text-skin-primary truncate">Tax</p>
                                        </dt>
                                        <dd className="ml-16 flex items-baseline">
                                            <p className="text-2xl font-semibold text-gray-900">{(Statistics.taxCount) ? Statistics.taxCount : <Skeleton width={40} height={25} />}</p>
                                        </dd>
                                    </div>
                                    <div className="relative bg-gray-50 pt-5 px-6 pb-6  shadow rounded-lg overflow-hidden">
                                        <dt>
                                            <div className="absolute bg-skin-secondary rounded-md p-3">
                                                <ClockIcon className="h-5 w-5 text-skin-white" aria-hidden="true" />
                                            </div>
                                            <p className="ml-16 text-basefont-medium text-skin-primary truncate">Rating Criteria</p>
                                        </dt>
                                        <dd className="ml-16 flex items-baseline">
                                            <p className="text-2xl font-semibold text-gray-900">{(Statistics.ratingCount) ? Statistics.ratingCount : <Skeleton width={40} height={25} />}</p>
                                        </dd>
                                    </div>



                                </dl>
                            </div>
                        </div>
                    </div>
                    <div className="grid grid-cols-1 lg:grid-cols-2">
                        <div className="px-3 py-3 ">
                            <div className="px-4 py-4 bg-skin-gray shadow-md rounded-md">
                                {/* <div className='flex items-center justify-between min-h-[40px]'>
                                    <h3 className="text-lg leading-6 font-medium text-skin-primary">Collected Tax</h3>
                                </div> */}
                                <div className='flex items-center justify-between min-h-[40px]'>
                                    <h3 className="text-lg leading-6 font-medium text-skin-primary">Collected Tax</h3>
                                    <select
                                        id="country10"
                                        name="country10"
                                        autoComplete="country10-name"
                                        onChange={(e) => handleChange('tax', e)}
                                        value={taxYear}
                                        className="block w-48 rounded-[8px]  border-[1.5px] border-skin-light py-1 px-4 text-skin-primary  outline-0 bg-transparent"
                                    >
                                        {
                                            years.map((e, key) => {
                                                return <option key={key} value={e}>{e}</option>;
                                            })
                                        }
                                    </select>
                                </div>
                                <dl className="mt-5 grid grid-cols-1 gap-5">
                                    <div className="relative pt-4 px-4 pb-4  shadow rounded-lg bg-skin-white overflow-hidden">
                                        <Line data={{
                                            labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "July", "Aug", "Sep", "Oct", "Nov", "Dec"],
                                            datasets: [
                                                {
                                                    label: `Highest Tax (${Statistics.currency})`,
                                                    data: Tax,
                                                    fill: false,
                                                    backgroundColor: "#f1f1f1",
                                                    borderColor: "#0A0A0A",
                                                    borderWidth: 2,
                                                }
                                            ]
                                        }} />
                                    </div>
                                </dl>
                            </div>
                        </div>
                        <div className="px-3 py-3 ">
                            <div className="px-4 py-4 bg-skin-gray shadow-md rounded-md">
                                <div className='flex items-center justify-between min-h-[40px]'>
                                    <h3 className="text-lg leading-6 font-medium text-skin-primary">Total Earning</h3>
                                    <select
                                        id="country10"
                                        name="country10"
                                        autoComplete="country10-name"
                                        onChange={(e) => handleChange('profit', e)}
                                        value={profitYear}
                                        className="block w-48 rounded-[8px]  border-[1.5px] border-skin-light py-1 px-4 text-skin-primary  outline-0 bg-transparent"
                                    >
                                        {
                                            years.map((e, key) => {
                                                return <option key={key} value={e}>{e}</option>;
                                            })
                                        }
                                    </select>
                                </div>
                                <dl className="mt-5 grid grid-cols-1 gap-5">
                                    <div className="relative pt-4 px-4 pb-4  shadow rounded-lg bg-skin-white overflow-hidden">
                                        {/* <Doughnut data={data} height={430} options={{ responsive: true, maintainAspectRatio: false }} /> */}
                                        <Line data={{
                                            labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "July", "Aug", "Sep", "Oct", "Nov", "Dec"],
                                            datasets: [
                                                {
                                                    label: `Earning (${Statistics.currency})`,
                                                    data: Profit,
                                                    fill: true,
                                                    backgroundColor: "#f1f1f1",
                                                    borderColor: "#0A0A0A"
                                                }
                                            ]
                                        }} />
                                    </div>
                                </dl>
                            </div>
                        </div>

                        {/* <div className="px-3 py-3 ">
                            <div className="px-4 py-4 bg-skin-gray shadow-md rounded-md">
                                <div className='flex items-center gap-3'>
                                    <h3 className="text-lg leading-6 font-medium text-skin-primary">Total Training : </h3>
                                    <h3 className="text-lg leading-6 font-medium text-skin-primary">320</h3>
                                </div>

                                <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 2xl:grid-cols-3">
                                    {training.map((item) => (
                                        <div
                                            key={item.id}
                                            className="relative pt-4 px-4 pb-4  shadow rounded-lg bg-skin-white overflow-hidden"
                                        >
                                            <dt>
                                                <div className="absolute bg-transparent border-[2px] border-skin-primary rounded-md p-3">
                                                    <item.icon className="h-5 w-5 text-skin-primary" aria-hidden="true" />
                                                </div>
                                                <p className="ml-16 text-sm font-medium text-skin-primary truncate">{item.name}</p>
                                            </dt>
                                            <dd className="ml-16 flex items-baseline">
                                                <p className="text-2xl font-semibold text-skin-primary">{item.stat}</p>

                                            </dd>
                                        </div>
                                    ))}
                                </dl>
                            </div>
                        </div> */}

                    </div>
                    <div className="grid grid-cols-1 lg:grid-cols-1 px-3 py-3 md:grid-cols-1 gap-6">
                        <div className="bg-white shadow sm:rounded-lg">
                            <div className="px-4 py-5 sm:px-6">
                                <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                                    Statistics
                                </h2>
                            </div>
                            <div className="border-t border-gray-200">
                                <div className="mb-5 chart-wrapper">
                                    <ul
                                        className="flex mb-0 list-none flex-wrap pt-3 flex-row border-b border-gray-200 font-medium"
                                        role="tablist"
                                    >
                                        <li className="-mb-px mr-2 last:mr-0 flex-auto text-center cursor-pointer">
                                            <a
                                                className={
                                                    "text-lg text-base px-5 py-3 block " +
                                                    (openTab === 1
                                                        ? "text-red-600 border-b-2 border-skin-secondary bg-" + color + "-600"
                                                        : "text-" + color + "-600 bg-white")
                                                }
                                                onClick={e => handleChangeTab('trip', 1, e)}
                                                data-toggle="tab"
                                                role="tablist"
                                            >
                                                Trips
                                            </a>
                                        </li>
                                        <li className="-mb-px mr-2 last:mr-0 flex-auto text-center cursor-pointer">
                                            <a
                                                className={
                                                    "text-lg text-base px-5 py-3 block " +
                                                    (openTab === 2
                                                        ? "text-red-600 border-b-2 border-skin-secondary bg-" + color + "-600"
                                                        : "text-" + color + "-600 bg-white")
                                                }
                                                onClick={e => handleChangeTab('passenger', 2, e)}
                                                data-toggle="tab"
                                                role="tablist"
                                            >
                                                Passengers
                                            </a>
                                        </li>
                                        <li className="-mb-px mr-2 last:mr-0 flex-auto text-center cursor-pointer">
                                            <a
                                                className={
                                                    "text-lg text-base px-5 py-3 block " +
                                                    (openTab === 3
                                                        ? "text-red-600 border-b-2 border-skin-secondary bg-" + color + "-600"
                                                        : "text-" + color + "-600 bg-white")
                                                }
                                                onClick={e => handleChangeTab('driver', 3, e)}
                                                data-toggle="tab"
                                                role="tablist"
                                            >
                                                Drivers
                                            </a>
                                        </li>
                                    </ul>

                                    <div className={openTab === 1 ? "block" : "hidden"} id="link1">
                                        {(subStatistics.length > 0) ?
                                            <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-6 px-4">
                                                {subStatistics.map((item) => (
                                                    <div key={item.name} className="relative bg-gray-50 pt-5 px-6 pb-6  shadow rounded-lg overflow-hidden">
                                                        <dt className="text-base font-medium text-red-600 truncate">{item.name}</dt>
                                                        <dd className="mt-1 text-3xl font-semibold text-gray-900">{(item) ? item.stat : <Skeleton width={40} height={25} />}</dd>
                                                    </div>
                                                ))}
                                            </dl>
                                            : <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-6 px-4">
                                                <Skeleton wrapper={Box} />
                                                <Skeleton wrapper={Box} />
                                                <Skeleton wrapper={Box} />
                                                <Skeleton wrapper={Box} />
                                                <Skeleton wrapper={Box} />
                                                <Skeleton wrapper={Box} />
                                            </dl>}
                                    </div>
                                    <div className={openTab === 2 ? "block" : "hidden"} id="link1">
                                        {(subStatistics.length > 0) ?
                                            <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-6 px-4">
                                                {subStatistics.map((item) => (
                                                    <div key={item.name} className="relative bg-gray-50 pt-5 px-6 pb-6  shadow rounded-lg overflow-hidden">
                                                        <dt className="text-base font-medium text-red-600 truncate">{item.name}</dt>
                                                        <dd className="mt-1 text-3xl font-semibold text-gray-900">{(item) ? item.stat : <Skeleton width={40} height={25} />}</dd>
                                                    </div>
                                                ))}
                                            </dl>
                                            : <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-6 px-4">
                                                <Skeleton wrapper={Box} />
                                                <Skeleton wrapper={Box} />
                                                <Skeleton wrapper={Box} />
                                                <Skeleton wrapper={Box} />
                                                <Skeleton wrapper={Box} />
                                                <Skeleton wrapper={Box} />
                                            </dl>}
                                    </div>
                                    <div className={openTab === 3 ? "block" : "hidden"} id="link1">
                                        {(subStatistics.length > 0) ?
                                            <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-6 px-4">
                                                {subStatistics.map((item) => (
                                                    <div key={item.name} className="relative bg-gray-50 pt-5 px-6 pb-6  shadow rounded-lg overflow-hidden">
                                                        <dt className="text-base font-medium text-red-600 truncate">{item.name}</dt>
                                                        <dd className="mt-1 text-3xl font-semibold text-gray-900">{(item) ? item.stat : <Skeleton width={40} height={25} />}</dd>
                                                    </div>
                                                ))}
                                            </dl>
                                            : <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-6 px-4">
                                                <Skeleton wrapper={Box} />
                                                <Skeleton wrapper={Box} />
                                                <Skeleton wrapper={Box} />
                                                <Skeleton wrapper={Box} />
                                                <Skeleton wrapper={Box} />
                                                <Skeleton wrapper={Box} />
                                            </dl>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <Footer /> */}
        </>
    )
}
export default function TabsRender() {
    return (
        <>
            <Tabs color="white" />
        </>
    );
}
