import React, { useState, useEffect } from "react";
import Label from "../../../components/Form/Label";
import TextInput from "../../../components/Form/TextInput";
import FileInput from "../../../components/Form/FileInput";
import BtnSaveRecord from "../../../components/Form/Button/BtnSaveRecord";
import configuration from '../../../config';
import { toast } from 'react-toastify';
import { Switch } from '@headlessui/react'

let Id = "";
// List Settings
function Summary({ user_id, reloadStateFun }) {
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  const fieldArr2 = [
    { id: 2, title: "Driving Licence Front Picture", name: "frontDrivingLicense", type: "FileInput" },
    { id: 3, title: "Driving Licence Back Picture", name: "backDrivingLicense", type: "FileInput" },
    { id: 1, title: "Driving Licence Number", name: "drivingLicense", type: "TextInput" },
    { id: 4, title: "CNIC", name: "CNIC", type: "TextInput" },
    { id: 2, title: "CNIC Front Picture", name: "frontCNIC", type: "FileInput" },
    { id: 3, title: "CNIC Back Picture", name: "backCNIC", type: "FileInput" },
  ];
  const fieldArr = [
    { id: 2, title: "Driving Licence Front Picture", name: "frontDrivingLicense", type: "FileInput" },
    { id: 3, title: "Driving Licence Back Picture", name: "backDrivingLicense", type: "FileInput" },
    { id: 1, title: "Driving Licence Number", name: "drivingLicense", type: "TextInput" },
    { id: 4, title: "CNIC", name: "CNIC", type: "TextInput" },
    { id: 2, title: "CNIC Front Picture", name: "frontCNIC", type: "FileInput" },
    { id: 3, title: "CNIC Back Picture", name: "backCNIC", type: "FileInput" },
  ];
  const [pendingDoc, setPendingDoc] = useState({});
  const [fields, setFields] = useState({});
  const [verificationMode, setVerificationMode] = useState(false);
  const [frontDrivingLicense, setLicenseFront] = useState(null);
  const [frontCNIC, setCNICFront] = useState(null);
  const [backCNIC, setCNICBack] = useState(null);
  const [backDrivingLicense, setLicenseBack] = useState(null);
  const [errors, setErrors] = useState({});
  const [isDocumentFlag, setIsDocumentFlag] = useState(true);
  const [documentToggle, setDocumentToggle] = useState({});

  useEffect(() => {
    Id = user_id;
    getUserData();
  }, []);

  function getUserData() {
    configuration.getAPI({ url: 'app-user/detail', params: { user_id: Id } }).then((data) => {
      if (data.payload) {
        setIsDocumentFlag(data.payload.isDocumentVerify)
        setDocumentToggle({ ...documentToggle, email: data.payload.email, mobile: data.payload.mobile, countryCode: data.payload.countryCode, _id: Id, isApprove: true });
        if (data.payload.document) {
          setFields(data.payload.document);
          setVerificationMode(data.payload?.driverDocumentVerificationMode);
          setPendingDoc(data.payload?.tempDocument || {});
        }
      }
    }).catch(error => {
      return toast.error(error.message)
    });
  }

  function handleChange(field, e) {
    if (field === 'frontDrivingLicense') {
      setLicenseFront(e.target.files[0]);
      setFields({ ...fields, [field]: URL.createObjectURL(e.target.files[0]) })
    } else if (field === 'frontCNIC') {
      setCNICFront(e.target.files[0]);
      setFields({ ...fields, [field]: URL.createObjectURL(e.target.files[0]) })
    } else if (field === 'backCNIC') {
      setCNICBack(e.target.files[0]);
      setFields({ ...fields, [field]: URL.createObjectURL(e.target.files[0]) })
    } else if (field === 'backDrivingLicense') {
      setLicenseBack(e.target.files[0]);
      setFields({ ...fields, [field]: URL.createObjectURL(e.target.files[0]) })
    } else {
      setFields({ ...fields, [field]: e.target.value })
    }
  }
  function cancleImage(field, e) {
    if (field === 'frontDrivingLicense') {
      setLicenseFront(null);
    } else if (field === 'frontCNIC') {
      setCNICFront(null);
    } else if (field === 'backCNIC') {
      setCNICBack(null);
    } else {
      setLicenseBack(null);
    }
    setFields({ ...fields, [field]: '' })
  }
  function validation() {
    let flag = true;
    let error = {}
    if (!fields.frontDrivingLicense) {
      error['frontDrivingLicense'] = "Please select licence front"
      flag = false
    }

    if (!fields.backDrivingLicense) {
      error['backDrivingLicense'] = "Please select licence back"
      flag = false
    }

    if (!fields.drivingLicense) {
      error['drivingLicense'] = "Please enter licence number"
      flag = false
    }
    if (!fields.CNIC) {
      error['CNIC'] = "Please enter CNIC number"
      flag = false
    }

    if (!fields.frontCNIC) {
      error['frontCNIC'] = "Please select CNIC front"
      flag = false
    }

    if (!fields.backCNIC) {
      error['backCNIC'] = "Please select CNIC back"
      flag = false
    }

    setErrors({ ...error })
    return flag;
  }
  const handleSubmit = () => {
    if (validation()) {
      console.log(fields)
      const formData = new FormData();
      formData.append('fields', JSON.stringify({ ...fields, lang_code: 'EN', user_id: Id }));
      if (frontDrivingLicense) {
        formData.append('frontDrivingLicense', frontDrivingLicense);
      }
      if (backDrivingLicense) {
        formData.append('backDrivingLicense', backDrivingLicense);
      }
      if (frontCNIC) {
        formData.append('frontCNIC', frontCNIC);
      }
      if (backCNIC) {
        formData.append('backCNIC', backCNIC);
      }
      configuration.postFormDataAPI({ url: 'app-user/update-document', params: formData }).then((data) => {
        if (data.payload) {
          // history.push('/driver')
          return toast.success('Update successfully')
        } else if (data.error.message) {
          return toast.error(data.error.message)
        } else {
          return toast.error('Something went wrong')
        }
      }).catch(error => {
        return toast.error(error.message)
      });
    }
  }

  const handleVerifyDoc = () => {
    // console.log(reloadState)

    configuration.allAPI({ url: 'app-user/update-approve-doc', method: 'PATCH', params: { user_id: Id, type: 'document' } }).then((data) => {
      // console.log(data)
      if (data.payload) {
        getUserData();
        reloadStateFun();
        return toast.success('Verify successfully')
      } else if (data.error) {
        return toast.error(data.error.message)
      } else {
        return toast.error('Something went wrong')
      }
    }).catch(error => {
      // console.log(error)
      return toast.error(error.message)
    });
  }
  function documentSwitch(e, type) {
    documentToggle.isDocumentVerify = e
    configuration.allAPI({ url: 'app-user/update', method: 'PATCH', params: documentToggle }).then((data) => {
      if (data.payload) {
        setIsDocumentFlag(e)
        return toast.success('Update successfully')
      } else if (data.error.message) {
        return toast.error(data.error.message)
      } else {
        return toast.error('Something went wrong')
      }
    }).catch(error => {
      return toast.error(error.message)
    });
  }
  return (
    <div className="md:pl-8 sm:py-7 col-span-10">
      <section aria-labelledby="applicant-information-title">
        <div className="bg-white shadow sm:rounded-lg">
          <div className="flex justify-between">
            <div className="px-4 py-5 sm:px-6">
              <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                Document Info
              </h2>
              <p className="mt-1 max-w-2xl text-sm text-gray-500 text_theme">This information is show user Document Info.</p>
            </div>
            <div className="flex items-center mr-6">
              <label htmlFor="push-everything" className="text-medium leading-6 font-medium text-gray-900">
                Approve Documents
              </label> &nbsp;&nbsp;
              <Switch
                checked={isDocumentFlag}
                onChange={(e) => documentSwitch(e)}
                className={classNames(
                  isDocumentFlag ? 'bg-indigo-600' : 'bg-gray-200',
                  'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                )}
              >
                <span className="sr-only">Use setting</span>
                <span
                  aria-hidden="true"
                  className={classNames(
                    isDocumentFlag ? 'translate-x-5' : 'translate-x-0',
                    'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                  )}
                />
              </Switch>
            </div>
          </div>
          <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
            <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              {fieldArr2.map((field) => {
                return (
                  <div className="sm:col-span-3" key={field.id}>
                    <Label title={field.title} />
                    <div className="mt-1">
                      {field.type === "TextInput" ? (
                        <TextInput
                          id={field.name}
                          name={field.name}
                          handleChange={handleChange}
                          value={fields[field.name]}
                          is_number={field.is_number || false}
                          is_password={field.is_password || false}
                        />
                      ) : field.type === "FileInput" ? (
                        <FileInput
                          id={field.name}
                          name={field.name}
                          value={fields[field.name]}
                          handleChange={handleChange}
                          cancleImage={cancleImage}
                        />
                      ) : null}
                    </div>
                    {errors[field.name] ? <Label title={field.title} fieldError={errors[field.name]} /> : null}
                  </div>
                );
              })}
            </div>
            <div className="pt-5 border_color_theme">
              <div className="flex justify-end">
                <BtnSaveRecord handleSubmit={handleSubmit} title="Save" path="/driverprofile" />
              </div>
            </div>
          </div>
          {(verificationMode) ?
            <><div className="flex border-t px-4 py-5 sm:px-6 justify-between">
              <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                Pending Documents
              </h2>
              <div>
                <BtnSaveRecord handleSubmit={handleVerifyDoc} title="Verify Documents" path="/driverprofile" />
              </div>
            </div>
              <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                  {fieldArr.map((field) => {
                    return (
                      <div className="sm:col-span-3" key={field.id}>
                        <Label title={field.title} />
                        <div className="mt-1">
                          {field.type === "TextInput" ? (
                            <TextInput
                              id={field.name}
                              name={field.name}
                              handleChange={handleChange}
                              value={pendingDoc?.[field.name]}
                              is_read_only={true}
                              is_number={field.is_number || false}
                              is_password={field.is_password || false}
                            />
                          ) : field.type === "FileInput" ? (
                            <FileInput
                              id={field.name}
                              name={field.name}
                              is_read_only={true}
                              value={pendingDoc?.[field.name]}
                              handleChange={handleChange}
                              cancleImage={cancleImage}
                            />
                          ) : null}
                        </div>
                        {errors[field.name] ? <Label title={field.title} fieldError={errors[field.name]} /> : null}
                      </div>
                    );
                  })}
                </div>
              </div></> : ''
          }
        </div>
      </section>
    </div>
  );
}

export default Summary;
