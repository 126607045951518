import React, { useEffect } from "react";
import Breadcrumb from '../../components/breadcrumb';
import labels from "../../constants/Role";
import { Fragment, useState } from 'react'
import { EllipsisVerticalIcon, FunnelIcon } from '@heroicons/react/20/solid'
import { Link } from 'react-router-dom';
import { Disclosure, Menu, Transition } from '@headlessui/react'
import DataTable from '../../components/Table/MDataTable';
import ActionButton from '../../components/Other/ActionButton';
import DeleteModel from '../../components/Other/DeleteModel';
import configuration from '../../config';
import { toast } from 'react-toastify';
import { pluck, findWhere } from 'underscore';
import Loader from "../../components/Loader/Loader";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


export default function View({ history }) {
  const [authorisation, setAuthorisation] = useState()
  const [isValid, setIsValid] = useState(false)
  const btnGroups = [
    { permission_type: "is_add", title: "Add New", type: "addNew", path: "/role/add" },
    { permission_type: "is_edit", title: "Make Active", type: "makeActive", path: "/role" },
    { permission_type: "is_edit", title: "Make Inactive", type: "makeInactive", path: "/role" },
    { permission_type: "is_delete", title: "Delete", type: "delete", path: "/role" },
  ];


  const columns = [
    {
      title: "ID",
      field: "_id",
      render: (rowData) => (
        (findWhere(authorisation, { code: 'ROLE' })?.data?.is_edit) ?
          <Link
            className="link text-skin-primary"
            to={`/role/edit/${rowData._id}`}
          >
            {rowData._id}
          </Link> : <>{rowData._id}</>
      ),
    },
    { title: "Title", field: "title" },
    {
      title: "Status",
      field: "status",
      render: (rowData) => (
        <span
          className={
            (rowData.status === "active") ? "inline-flex items-center px-3 py-0.5 rounded-full text-basefont-medium bg-lime-200 text-lime-800 capitalize" :
              "inline-flex items-center px-3 py-0.5 rounded-full text-basefont-medium bg-red-200 text-red-800 capitalize"
          }
        >
          {" "}
          {rowData.status}
        </span>
      ),
    }
  ];

  const [data, setData] = useState([]);
  const [ids, setIds] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const onSelectionChange = (e) => {
    let idArr = pluck(e, "_id");
    setIds(idArr);
  };

  useEffect(() => {
    var retrievedObject = JSON.parse(localStorage.getItem('token'));
    if (retrievedObject) {
      configuration.getAPI({ url: 'common/list-access', params: { roleID: retrievedObject.roleID } }).then((data) => {
        if (data.payload) {
          setAuthorisation(data.payload)
        }
      }).catch(error => {
        return toast.error(error.message)
      });
    }
    getDatas();
  }, []);
  function getDatas() {
    configuration.getAPI({ url: 'role/list', params: {} }).then((data) => {
      if (data.payload) {
        setData(data.payload)
      }
    }).catch(error => {
      return toast.error(error.message)
    });
  }
  function handleAction(type) {
    if (ids.length < 1) {
      return toast.error('Please select at least one record')
    } else if (type === 'delete') {
      setShowModal(true);
      return;
    }
    const sendData = {
      ids,
      type: (type === 'makeActive') ? 'active' : (type === 'makeInactive') ? 'inactive' : type
    }
    configuration.allAPI({ url: 'role/action', method: 'PATCH', params: sendData }).then((data) => {
      if (data.payload) {
        getDatas()
        setIds([]);
        return toast.success((type === 'delete') ? 'Record deleted successfully' : 'Record update successfully')
      } else if (data.error) {
        return toast.error(data.error.message)
      } else {
        return toast.error('Something went wrong')
      }
    }).catch(error => {
      return toast.error(error.message)
    });
  }
  function handleDelete() {
    const sendData = {
      ids,
      type: 'delete'
    }
    configuration.allAPI({ url: 'role/action', method: 'PATCH', params: sendData }).then((data) => {
      if (data.payload) {
        getDatas()
        setIds([]);
        setShowModal(false);
        return toast.success('Record deleted successfully')
      } else if (data.error) {
        return toast.error(data.error.message)
      } else {
        return toast.error('Something went wrong')
      }
    }).catch(error => {
      return toast.error(error.message)
    });
  }
  function handleCancel() {
    setShowModal(false);
  }


  useEffect(() => {
    const whereFind = (findWhere(authorisation, { code: 'ROLE' }))
    if (whereFind?.data?.is_view === false) {
      setIsValid(!isValid)
      history.push('/dashboard')
    }
    if (whereFind?.data?.is_view === true) {
      setIsValid(!isValid)
    }
  }, [authorisation])



  return (
    <>{isValid ? <>  <Breadcrumb module={labels.role} />
      <div className='main dark:bg-black bg-white mb-5'>
        <div className="dark:bg-black bg-white">
          <div className="py-5 px-5 flex items-center justify-between">
            <h1 className="text-3xl font-bold tracking-tight dark:text-[#fff] text-black">{labels.role}</h1>
            <div className='flex items-center gap-3'>
              <Menu as="div" className="relative inline-block text-left">
                <div>
                  <Menu.Button className="flex items-center rounded-full border-2 py-2 px-2 dark:border-[#00FF00] border-[#000] outline-none ">
                    <span className="sr-only">Open options</span>
                    <EllipsisVerticalIcon className="h-5 w-5 dark:text-white text-black" aria-hidden="true" />
                  </Menu.Button>
                </div>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 z-30 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                      {btnGroups.map((btn) => {
                        return (
                          <ActionButton
                            key={btn.id}
                            btnTitle={btn.title}
                            btnType={btn.type}
                            btnPath={btn.path}
                            handleAction={handleAction}
                            moduleName={"ROLE"}
                            authorisation={authorisation}
                            permission_type={btn.permission_type}
                          />
                        );
                      })}
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
          <DeleteModel
            mode={showModal}
            handleDelete={handleDelete}
            handleCancel={handleCancel}
          />
          <div className="dark:bg-black bg-white px-4 mt-5">
            <div className="mt-8 flex flex-col">
              <div className='datatable'>
                <DataTable
                  columns={columns}
                  data={data}
                  disabledCheckbox={false}
                  onSelectionChange={onSelectionChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div></> : <> <Loader /> </>



    }

    </>
  )
}
