import React, { useEffect, useState } from "react";
import Breadcrumb from '../../components/breadcrumb';
import labels from "../../constants/CMS";
import Label from "../../components/Form/Label";
import TextInput from "../../components/Form/TextInput";
import ButtonSection from "../../components/Form/ButtonSection";
import Dropdown from "../../components/Form/Dropdown";
import TinyEditor from "../../components/Form/TinyEditor";
import configuration from '../../config';
import { toast } from 'react-toastify';
import { each, findWhere } from 'underscore';
import Loader from '../../components/Loader/Loader';

export default function View({ history }) {
    const options = [
        { id: 1, title: "Active", value: "active" },
        { id: 2, title: "Inactive", value: "inactive" },
    ];
    const userType = [
        { id: 1, title: "Passenger", value: "passenger" },
        { id: 2, title: "Driver", value: "driver" },
    ];
    const [languages, setLanguages] = useState([])
    const [fields, setFields] = useState({});
    const [errors, setErrors] = useState({});
    const [isValid, setIsValid] = useState(false)
    const [authorisation, setAuthorisation] = useState()

    useEffect(() => {
        configuration.getAPI({ url: 'language/list', params: { status: 'active' } }).then((data) => {
            // console.log(data)
            if (data.payload) {
                setLanguages(data.payload)
            }
        }).catch(error => {
            // console.log(error)
            return toast.error(error.message)
        });
    }, []);

    function validation() {
        let flag = true;
        let error = {}
        each(languages, (lang, ind) => {
            if (!fields['title_' + lang.code]) {
                error['title_' + lang.code] = "Please enter title in " + lang.title
                flag = false
            }
            if (!fields['description_' + lang.code]) {
                error['description_' + lang.code] = "Please enter description in " + lang.title
                flag = false
            }
        })
        if (!fields.template_code) {
            error['template_code'] = "Please enter code"
            flag = false
        }
        if (!fields.user_type) {
            error['user_type'] = "Please select user type"
            flag = false
        }
        if (!fields.status) {
            error['status'] = "Please select status"
            flag = false
        }
        setErrors({ ...error })
        return flag;
    }

    const handleSubmit = () => {
        if (validation()) {
            let questionObj = {}
            let answerObj = {}
            each(languages, (lang, ind) => {
                questionObj[lang.code] = fields['title_' + lang.code] ? fields['title_' + lang.code] : '';
                answerObj[lang.code] = fields['description_' + lang.code] ? fields['description_' + lang.code] : '';
                delete fields['title_' + lang.code];
                delete fields['description_' + lang.code];
            })
            fields.title = questionObj;
            fields.description = answerObj;
            configuration.allAPI({ url: 'cms/create', method: 'put', params: fields }).then((data) => {
                // console.log(data)
                if (data.payload) {
                    history.push("/cms")
                } else if (data.error) {
                    return toast.error(data.error.message)
                } else {
                    return toast.error('Something went wrong')
                }
            }).catch(error => {
                // console.log(error)
                return toast.error(error.message)
            });
        }
    }

    useEffect(() => {
        var retrievedObject = JSON.parse(localStorage.getItem('token'));
        if (retrievedObject) {
            configuration.getAPI({ url: 'common/list-access', params: { roleID: retrievedObject.roleID } }).then((data) => {
                if (data.payload) {
                    setAuthorisation(data.payload)
                }
            }).catch(error => {
                return toast.error(error.message)
            });
        }
    }, []);
    useEffect(() => {
        const whereFind = (findWhere(authorisation, { code: 'STATIC_PAGES' }))
        if (whereFind?.data?.is_add === false) {
            setIsValid(!isValid)
            history.push('/dashboard')
        }
        if (whereFind?.data?.is_add === true) {
            setIsValid(!isValid)
        }
    }, [authorisation])



    return (
        <>
            {isValid ? <>   <Breadcrumb module={labels.addCMS} />
                <div className='main dark:bg-black bg-white mb-5'>
                    <div className="dark:bg-black bg-white">
                        <div className="py-5 px-5 flex items-center justify-between">
                            <h1 className="text-3xl font-bold tracking-tight dark:text-[#fff] text-black">{labels.addCMS}</h1>
                        </div>

                        <div className="mt-5 sm:mx-auto sm:w-full px-4">
                            <div className="bg-skin-white py-5 px-5 shadow sm:rounded-lg">
                                <form className="space-y-6" action="#" method="POST">
                                    <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
                                        {languages.map((single) => {
                                            return <div>
                                                <Label title={`Title [${single.title}]`} />
                                                <TextInput
                                                    id={`title_${single.code}`}
                                                    name={`title_${single.code}`}
                                                    handleChange={(field, e) =>
                                                        setFields({ ...fields, [field]: e.target.value })}
                                                    value={fields[`title_${single.code}`]}
                                                />
                                                {errors[`title_${single.code}`] ? (
                                                    <Label title={`title_${single.code}`} fieldError={errors[`title_${single.code}`]} />
                                                ) : null}
                                            </div>
                                        })}
                                        <div>
                                            <Label title="User Type" />
                                            <Dropdown
                                                id="user_type"
                                                name="user_type"
                                                title="User Type"
                                                value={fields.user_type}
                                                status={userType}
                                                handleChange={(field, e) => {
                                                    setFields({ ...fields, [field]: e.target.value })
                                                }}
                                            />
                                            {errors.user_type ? (
                                                <Label title="user_type" fieldError={errors.user_type} />
                                            ) : null}
                                        </div>
                                        <div>
                                            <Label title="Code" />
                                            <TextInput
                                                id={'template_code'}
                                                name={'template_code'}
                                                handleChange={(field, e) =>
                                                    setFields({ ...fields, [field]: e.target.value })}
                                                value={fields['template_code']}
                                            />
                                            {errors.template_code ? (
                                                <Label title="template_code" fieldError={errors.template_code} />
                                            ) : null}
                                        </div>
                                        <div>
                                            <Label title="Status" />
                                            <Dropdown
                                                id="status"
                                                name="status"
                                                title="status"
                                                value={fields.status}
                                                status={options}
                                                handleChange={(field, e) =>
                                                    setFields({ ...fields, [field]: e.target.value })}
                                            />
                                            {errors.status ? (
                                                <Label title="status" fieldError={errors.status} />
                                            ) : null}
                                        </div>
                                    </div>
                                    <div>
                                        {languages.map((single) => {
                                            return <div>
                                                <Label title={`Description [${single.title}]`} />
                                                <TinyEditor
                                                    className=""
                                                    name={`description_${single.code}`}
                                                    value={fields[`description_${single.code}`]}
                                                    handleChange={(field, e) =>
                                                        setFields({ ...fields, [field]: e.target.getContent() })}
                                                />
                                                {errors[`description_${single.code}`] ? (
                                                    <Label title={`description_${single.code}`} fieldError={errors[`description_${single.code}`]} />
                                                ) : null}
                                            </div>
                                        })}

                                    </div>
                                    <ButtonSection path={'/cms'} handleSubmit={handleSubmit} />
                                </form>
                            </div>
                        </div>
                    </div>
                </div> </> : <> <Loader />  </>}

        </>
    )
}
