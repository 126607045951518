import React, { useState, useEffect } from "react";
import Dropdown from "../../../components/Form/Dropdown";
import Label from "../../../components/Form/Label";
import TextInput from "../../../components/Form/TextInput";
import FileInput from "../../../components/Form/FileInput";
import BtnSaveRecord from "../../../components/Form/Button/BtnSaveRecord";
import { Switch } from '@headlessui/react'
import configuration from '../../../config';
import { toast } from 'react-toastify';

let Id = "";
// List Settings
function Summary({ user_id, reloadStateFun }) {
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  let [brandList, setBrandList] = useState([]);
  let [rideOptionList, setRideOptionList] = useState([]);
  const vehicles = [
    { id: 0, title: "Car Category", name: "rideOption", type: "Dropdown", options: rideOptionList },
    { id: 1, title: "Car Brand", name: "brandId", type: "Dropdown", options: brandList },
    { id: 4, title: "Car Name", name: "carName", type: "TextInput" },
    { id: 5, title: "Vehicle Number", name: "vehicleNo", type: "TextInput" },
    // { id: 6, title: "Color", name: "colorCode", type: "colorCode" },
    // { id: 6, title: "Upload Insurance Covernote", name: "insuranceCovernote", type: "FileInput" },
    { id: 6, title: "Upload Vehicle Photo(Front)", name: "vehiclePhotoFront", type: "FileInput" },
    { id: 8, title: "Upload Vehicle Photo(Rear)", name: "vehiclePhotoRear", type: "FileInput" },
    /* { id: 8, title: "Upload Vehicle Photo(Left)", name: "vehiclePhotoLeft", type: "FileInput" },
    { id: 8, title: "Upload Vehicle Photo(Right)", name: "vehiclePhotoRight", type: "FileInput" }, */
  ];
  const tempVehicles = [
    { id: 0, title: "Car Category", name: "rideOption", type: "Dropdown", options: rideOptionList },
    { id: 1, title: "Car Brand", name: "brandId", type: "Dropdown", options: brandList },
    { id: 4, title: "Car Name", name: "carName", type: "TextInput" },
    { id: 5, title: "Vehicle Number", name: "vehicleNo", type: "TextInput" },
    // { id: 6, title: "Upload Insurance Covernote", name: "insuranceCovernote", type: "FileInput" },
    { id: 6, title: "Upload Vehicle Photo(Front)", name: "vehiclePhotoFront", type: "FileInput" },
    { id: 8, title: "Upload Vehicle Photo(Rear)", name: "vehiclePhotoRear", type: "FileInput" },
    /* { id: 8, title: "Upload Vehicle Photo(Left)", name: "vehiclePhotoLeft", type: "FileInput" },
    { id: 8, title: "Upload Vehicle Photo(Right)", name: "vehiclePhotoRight", type: "FileInput" }, */
  ];
  const [fields, setFields] = useState({});
  const [tempFields, setTempFields] = useState({});
  const [verificationMode, setVerificationMode] = useState(false);
  const [insuranceCovernote, setInsuranceCovernote] = useState(null);
  const [vehiclePhotoRear, setVehiclePhotoRear] = useState(null);
  const [vehiclePhotoFront, setVehiclePhotoFront] = useState(null);
  const [vehiclePhotoLeft, setVehiclePhotoLeft] = useState(null);
  const [vehiclePhotoRight, setVehiclePhotoRight] = useState(null);
  const [errors, setErrors] = useState({});
  const [isDocumentFlag, setIsDocumentFlag] = useState(true);
  const [documentToggle, setDocumentToggle] = useState({});
  useEffect(() => {
    // console.log(props.location)
    Id = user_id;
    getUserData();
    // configuration.getAPI({ url: 'app-user/detail', params: { user_id: Id } }).then((data) => {
    //   if (data.payload && data.payload.vehicle) {
    //     setFields(data.payload.vehicle);
    //   }
    // }).catch(error => {
    //   return toast.error(error.message)
    // });
    configuration.getAPI({ url: 'ride-option/list', params: { status: "active" } }).then((data) => {
      if (data.payload) {
        if (data.payload.length > 0) {
          data.payload.forEach((element) => {
            element.value = element._id;
            element.id = element._id;
            return element;
          });
        }
        setRideOptionList(data.payload)
      }
    }).catch(error => {
      return toast.error(error.message)
    });
    configuration.getAPI({ url: 'brand/list', params: { status: "active" } }).then((data) => {
      if (data.payload) {
        if (data.payload.length > 0) {
          data.payload.forEach((element) => {
            element.value = element._id;
            element.id = element._id;
            return element;
          });
        }
        setBrandList(data.payload)
      }
    }).catch(error => {
      return toast.error(error.message)
    });
    // console.log(configuration.baseURL);
  }, []);
  function getUserData() {
    configuration.getAPI({ url: 'app-user/detail', params: { user_id: Id } }).then((data) => {
      if (data.payload) {
        setIsDocumentFlag(data.payload.isVehicleVerify)
        setDocumentToggle({ ...documentToggle, email: data.payload.email, mobile: data.payload.mobile, countryCode: data.payload.countryCode, _id: Id, isApprove: true });
        if (data.payload && data.payload.vehicle) {
          setFields(data.payload.vehicle);
          setVerificationMode(data.payload?.driverVehicleVerificationMode);
          setTempFields(data.payload?.tempVehicle || {});
        }
      }
    }).catch(error => {
      return toast.error(error.message)
    });
  }
  function handleChange(field, e) {
    if (field === 'insuranceCovernote') {
      setInsuranceCovernote(e.target.files[0]);
      setFields({ ...fields, [field]: URL.createObjectURL(e.target.files[0]) })
    } else if (field === 'vehiclePhotoFront') {
      setVehiclePhotoFront(e.target.files[0]);
      setFields({ ...fields, [field]: URL.createObjectURL(e.target.files[0]) })
    } else if (field === 'vehiclePhotoRear') {
      setVehiclePhotoRear(e.target.files[0]);
      setFields({ ...fields, [field]: URL.createObjectURL(e.target.files[0]) })
    } else if (field === 'vehiclePhotoRight') {
      setVehiclePhotoRight(e.target.files[0]);
      setFields({ ...fields, [field]: URL.createObjectURL(e.target.files[0]) })
    } else if (field === 'vehiclePhotoLeft') {
      setVehiclePhotoLeft(e.target.files[0]);
      setFields({ ...fields, [field]: URL.createObjectURL(e.target.files[0]) })
    } else {
      setFields({ ...fields, [field]: e.target.value })
    }
  }
  function cancleImage(field, e) {
    if (field === 'insuranceCovernote') {
      setInsuranceCovernote(null);
    } else if (field === 'vehiclePhotoFront') {
      setVehiclePhotoFront(null);
    } else if (field === 'vehiclePhotoRear') {
      setVehiclePhotoRear(null);
    } else if (field === 'vehiclePhotoLeft') {
      setVehiclePhotoLeft(null);
    } else if (field === 'vehiclePhotoRight') {
      setVehiclePhotoRight(null);
    }
    setFields({ ...fields, [field]: '' })
  }

  function handleValidation() {
    let flag = true;
    let error = {}

    if (!fields.rideOption) {
      error['rideOption'] = "Please Select ride Option"
      flag = false
    }
    if (!fields.brandId) {
      error['brandId'] = "Please Select brand"
      flag = false
    }
    /* if (!fields.insuranceCovernote) {
      error['insuranceCovernote'] = "Please Select insurance covernote"
      flag = false
    } */
    if (!fields.vehiclePhotoFront) {
      error['vehiclePhotoFront'] = "Please Select vehicle photo front"
      flag = false
    }
    if (!fields.vehiclePhotoRear) {
      error['vehiclePhotoRear'] = "Please Select vehicle photo rear"
      flag = false
    }
    /* if (!fields.vehiclePhotoLeft) {
      error['vehiclePhotoLeft'] = "Please Select vehicle photo left"
      flag = false
    }
    if (!fields.vehiclePhotoRight) {
      error['vehiclePhotoRight'] = "Please Select vehicle photo right"
      flag = false
    } */

    if (!fields.carName) {
      error['carName'] = "Please enter car name"
      flag = false
    }
    if (!fields.vehicleNo) {
      error['vehicleNo'] = "Please enter vehicle number"
      flag = false
    }
    setErrors({ ...error })
    return flag;
  }

  const handleSubmit = () => {
    if (handleValidation()) {
      const formData = new FormData();
      formData.append('fields', JSON.stringify({ ...fields, lang_code: 'EN', user_id: Id }));
      if (insuranceCovernote) {
        formData.append('insuranceCovernote', insuranceCovernote);
      }
      if (vehiclePhotoRight) {
        formData.append('vehiclePhotoRight', vehiclePhotoRight);
      }
      if (vehiclePhotoLeft) {
        formData.append('vehiclePhotoLeft', vehiclePhotoLeft);
      }
      if (vehiclePhotoFront) {
        formData.append('vehiclePhotoFront', vehiclePhotoFront);
      }
      if (vehiclePhotoRear) {
        formData.append('vehiclePhotoRear', vehiclePhotoRear);
      }
      const apiURL = 'app-user/update-vehicle';

      configuration.postFormDataAPI({ url: apiURL, params: formData }).then((data) => {
        if (data.payload) {
          return toast.success("Vehicle updated successfully");
        } else if (data.error.message) {
          return toast.error(data.error.message)
        } else {
          return toast.error('Something went wrong')
        }
      }).catch(error => {
        return toast.error(error.message)
      });
    }
  }
  const handleVerifyDoc = () => {
    configuration.allAPI({ url: 'app-user/update-approve-doc', method: 'PATCH', params: { user_id: Id, type: 'vehicle' } }).then((data) => {
      // console.log(data)
      if (data.payload) {
        getUserData();
        reloadStateFun();
        return toast.success('Verify successfully')
      } else if (data.error) {
        return toast.error(data.error.message)
      } else {
        return toast.error('Something went wrong')
      }
    }).catch(error => {
      // console.log(error)
      return toast.error(error.message)
    });
  }

  function vehicleSwitch(e, type) {
    documentToggle.isVehicleVerify = e
    configuration.allAPI({ url: 'app-user/update', method: 'PATCH', params: documentToggle }).then((data) => {
      if (data.payload) {
        setIsDocumentFlag(e)
        return toast.success('Update successfully')
      } else if (data.error.message) {
        return toast.error(data.error.message)
      } else {
        return toast.error('Something went wrong')
      }
    }).catch(error => {
      return toast.error(error.message)
    });
  }

  return (
    <div className="md:pl-8 sm:py-7 col-span-10">
      <section aria-labelledby="applicant-information-title">
        <div className="bg-white shadow sm:rounded-lg">
          <div className="flex justify-between">
            <div className="px-4 py-5 sm:px-6">
              <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                Vehicle Info
              </h2>
              <p className="mt-1 max-w-2xl text-sm text-gray-500 text_theme">This information will be displayed publicly so be careful what you share.</p>
            </div>
            <div className="flex items-center mr-6">
              <label htmlFor="push-everything" className="text-medium leading-6 font-medium text-gray-900">
                Approve Documents
              </label> &nbsp;&nbsp;
              <Switch
                checked={isDocumentFlag}
                onChange={(e) => vehicleSwitch(e)}
                className={classNames(
                  isDocumentFlag ? 'bg-indigo-600' : 'bg-gray-200',
                  'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                )}
              >
                <span className="sr-only">Use setting</span>
                <span
                  aria-hidden="true"
                  className={classNames(
                    isDocumentFlag ? 'translate-x-5' : 'translate-x-0',
                    'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                  )}
                />
              </Switch>
            </div>
          </div>
          <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
            <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              {vehicles.map((field) => {
                return (
                  <div className="sm:col-span-3" key={field.id}>
                    <Label title={field.title} />
                    <div className="mt-1">
                      {field.type === "TextInput" ? (
                        <TextInput
                          id={field.name}
                          name={field.name}
                          value={fields[field.name]}
                          handleChange={handleChange}
                          is_number={field.is_number || false}
                          is_password={field.is_password || false}
                        />
                      ) : field.type === "Dropdown" ? (
                        <Dropdown
                          id={field.name}
                          value={fields[field.name]}
                          name={field.name}
                          handleChange={handleChange}
                          status={field.options || []}
                        />
                      ) : field.type === "FileInput" ? (
                        <FileInput
                          id={field.name}
                          value={fields[field.name]}
                          handleChange={handleChange}
                          cancleImage={cancleImage}
                          name={field.name}
                        />
                      )
                        /* : field.type === "colorCode" ? (
                    <div className="space-y-1">
                      <input
                        type="color"
                        name={"colorCode"}
                        id={"colorCode"}
                        value={fields.colorCode}
                        onChange={(e) => handleChange("colorCode", e)}
                        // readOnly={is_read_only || false}
                        // autoComplete="given-name"
                        className={'w-full'}
                      />
                    </div>
                  ) */
                        : null}
                    </div>
                    {errors[field.name] ? <Label title={field.title} fieldError={errors[field.name]} /> : null}
                  </div>
                );
              })}
            </div>
            <div className="pt-5 border_color_theme">
              <div className="flex justify-end">
                <BtnSaveRecord title="Save" handleSubmit={handleSubmit} path="/driverprofile" />
              </div>
            </div>
          </div>
          {(verificationMode) ? <>
            <div className="flex border-t px-4 py-5 sm:px-6 justify-between">
              <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                Pending Vehicle
              </h2>
              <div>
                <BtnSaveRecord title="Verify Vehicle" handleSubmit={handleVerifyDoc} path="/driverprofile" />
              </div>
            </div>
            <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
              <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                {tempVehicles.map((field) => {
                  return (
                    <div className="sm:col-span-3" key={field.id}>
                      <Label title={field.title} />
                      <div className="mt-1">
                        {field.type === "TextInput" ? (
                          <TextInput
                            id={field.name}
                            name={field.name}
                            value={tempFields?.[field.name]}
                            is_read_only={true}
                            handleChange={handleChange}
                            is_number={field.is_number || false}
                            is_password={field.is_password || false}
                          />
                        ) : field.type === "Dropdown" ? (
                          <Dropdown
                            id={field.name}
                            value={tempFields?.[field.name]}
                            readOnly={true}
                            name={field.name}
                            handleChange={handleChange}
                            status={field.options || []}
                          />
                        ) : field.type === "FileInput" ? (
                          <FileInput
                            id={field.name}
                            value={tempFields?.[field.name]}
                            handleChange={handleChange}
                            is_read_only={true}
                            cancleImage={cancleImage}
                            name={field.name}
                          />
                        ) : null}
                      </div>
                      {errors[field.name] ? <Label title={field.title} fieldError={errors[field.name]} /> : null}
                    </div>
                  );
                })}
              </div>
            </div>
          </> : ''}
        </div>
      </section >
    </div >
  );
}

export default Summary;
