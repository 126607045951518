import React, { useEffect, useState } from "react";
import Breadcrumb from '../../components/breadcrumb';
import labels from "../../constants/Help";
import Label from "../../components/Form/Label";
import TextInput from "../../components/Form/TextInput";
import ButtonSection from "../../components/Form/ButtonSection";
import Dropdown from "../../components/Form/Dropdown";
import TinyEditor from "../../components/Form/TinyEditor";
import configuration from '../../config';
import { toast } from 'react-toastify';
import { each, findWhere } from 'underscore';
import Loader from '../../components/Loader/Loader';
export default function Edit({ history }) {
    const [HelpCategory, setHelpCategory] = useState([])
    const [languages, setLanguages] = useState([])
    const [fields, setFields] = useState({});
    const [errors, setErrors] = useState({});
    const [isValid, setIsValid] = useState(false)
    const [authorisation, setAuthorisation] = useState()
    const options = [
        { id: 1, title: "Active", value: "active" },
        { id: 2, title: "Inactive", value: "inactive" },
    ];
    const userType = [
        { id: 1, title: "Passenger", value: "passenger" },
        { id: 2, title: "Driver", value: "driver" },
    ];

    useEffect(() => {
        let url = window.location.href;
        let ID = url.substring(url.lastIndexOf("/") + 1);
        configuration.getAPI({ url: 'language/list', params: { status: 'active' } }).then((data1) => {
            // console.log(data)
            if (data1.payload) {
                setLanguages(data1.payload)
                configuration.getAPI({ url: 'help/list', params: { ID } }).then((data) => {
                    // console.log(data)
                    if (data.payload) {
                        getCategory(data.payload.user_type)
                        let questionObj = {}
                        let answerObj = {}
                        each(data1.payload, (lang, ind) => {
                            questionObj['question_' + lang.code] = data.payload.question[lang.code] ? data.payload.question[lang.code] : '';
                            answerObj['answer_' + lang.code] = data.payload.answer[lang.code] ? data.payload.answer[lang.code] : '';
                        })
                        delete data.payload.question;
                        delete data.payload.answer;
                        setFields({ ...questionObj, ...answerObj, ...data.payload })
                    }
                }).catch(error => {
                    // console.log(error)
                    return toast.error(error.message)
                });
            }
        }).catch(error => {
            // console.log(error)
            return toast.error(error.message)
        });
    }, []);
    function getCategory(type) {
        if (type) {
            configuration.getAPI({ url: 'help-category/list', params: { status: 'active', type } }).then((data) => {
                if (data.payload) {
                    each(data.payload, single => {
                        single.value = single._id
                    })
                    setHelpCategory(data.payload)
                }
            }).catch(error => {
                // console.log(error)
                return toast.error(error.message)
            });
        }
    }
    function validation() {
        let flag = true;
        let error = {}
        each(languages, (lang, ind) => {
            if (!fields['question_' + lang.code]) {
                error['question_' + lang.code] = "Please enter question in " + lang.title
                flag = false
            }
            if (!fields['answer_' + lang.code]) {
                error['answer_' + lang.code] = "Please enter answer in " + lang.title
                flag = false
            }
        })
        if (!fields.help_category_id) {
            error['help_category_id'] = "Please select category"
            flag = false
        }
        if (!fields.user_type) {
            error['user_type'] = "Please select user type"
            flag = false
        }
        if (!fields.status) {
            error['status'] = "Please select status"
            flag = false
        }
        setErrors({ ...error })
        return flag;
    }

    const handleSubmit = () => {
        if (validation()) {
            let questionObj = {}
            let answerObj = {}
            each(languages, (lang, ind) => {
                questionObj[lang.code] = fields['question_' + lang.code] ? fields['question_' + lang.code] : '';
                answerObj[lang.code] = fields['answer_' + lang.code] ? fields['answer_' + lang.code] : '';
                delete fields['question_' + lang.code];
                delete fields['answer_' + lang.code];
            })
            fields.question = questionObj;
            fields.answer = answerObj;
            configuration.allAPI({ url: 'help/update', method: 'PATCH', params: fields }).then((data) => {
                // console.log(data)
                if (data.payload) {
                    history.push("/help")
                } else if (data.error) {
                    return toast.error(data.error.message)
                } else {
                    return toast.error('Something went wrong')
                }
            }).catch(error => {
                // console.log(error)
                return toast.error(error.message)
            });
        }
    }
    
    useEffect(() => {
        var retrievedObject = JSON.parse(localStorage.getItem('token'));
        if (retrievedObject) {
            configuration.getAPI({ url: 'common/list-access', params: { roleID: retrievedObject.roleID } }).then((data) => {
                if (data.payload) {
                    setAuthorisation(data.payload)
                }
            }).catch(error => {
                return toast.error(error.message)
            });
        }

    }, []);
    useEffect(() => {
        const whereFind = (findWhere(authorisation, { code: 'HELP' }))
        if (whereFind?.data?.is_edit === false) {
            setIsValid(!isValid)
            history.push('/dashboard')
        }
        if (whereFind?.data?.is_edit === true) {
            setIsValid(!isValid)
        }
    }, [authorisation])

    return (
        <> {isValid ? <>  <Breadcrumb module={labels.editHelp} />
            <div className='main dark:bg-black bg-white mb-5'>
                <div className="dark:bg-black bg-white">
                    <div className="py-5 px-5 flex items-center justify-between">
                        <h1 className="text-3xl font-bold tracking-tight dark:text-[#fff] text-black">{labels.editHelp}</h1>
                    </div>

                    <div className="mt-5 sm:mx-auto sm:w-full px-4">
                        <div className="bg-skin-white py-5 px-5 shadow sm:rounded-lg">
                            <form className="space-y-6" action="#" method="POST">
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
                                    <div>
                                        <Label title="User Type" />
                                        <Dropdown
                                            id="user_type"
                                            name="user_type"
                                            title="User Type"
                                            value={fields.user_type}
                                            status={userType}
                                            handleChange={(field, e) => {
                                                getCategory(e.target.value)
                                                setFields({ ...fields, [field]: e.target.value })
                                            }}
                                        />
                                        {errors.user_type ? (
                                            <Label title="user_type" fieldError={errors.user_type} />
                                        ) : null}
                                    </div>
                                    <div>
                                        <Label title="Help Category" />
                                        <Dropdown
                                            id="help_category_id"
                                            name="help_category_id"
                                            title="Help Category"
                                            value={fields.help_category_id}
                                            status={HelpCategory}
                                            handleChange={(field, e) =>
                                                setFields({ ...fields, [field]: e.target.value })}
                                        />
                                        {errors.help_category_id ? (
                                            <Label title="help_category_id" fieldError={errors.help_category_id} />
                                        ) : null}
                                    </div>
                                    {languages.map((single) => {
                                        return <div>
                                            <Label title={`Question [${single.title}]`} />
                                            <TextInput
                                                id={`question_${single.code}`}
                                                name={`question_${single.code}`}
                                                handleChange={(field, e) =>
                                                    setFields({ ...fields, [field]: e.target.value })}
                                                value={fields[`question_${single.code}`]}
                                            />
                                            {errors[`question_${single.code}`] ? (
                                                <Label title={`question_${single.code}`} fieldError={errors[`question_${single.code}`]} />
                                            ) : null}
                                        </div>
                                    })}
                                    {languages.map((single) => {
                                        return <div>
                                            <Label title={`Answer [${single.title}]`} />
                                            <TinyEditor
                                                className=""
                                                name={`answer_${single.code}`}
                                                value={fields[`answer_${single.code}`]}
                                                handleChange={(field, e) =>
                                                    setFields({ ...fields, [field]: e.target.getContent() })}
                                            />
                                            {/* <TextInput
                                            id={`answer_${single.code}`}
                                            name={`answer_${single.code}`}
                                            handleChange={(field, e) =>
                                                setFields({ ...fields, [field]: e.target.value })}
                                            value={fields[`answer_${single.code}`]}
                                        /> */}
                                            {errors[`answer_${single.code}`] ? (
                                                <Label title={`answer_${single.code}`} fieldError={errors[`answer_${single.code}`]} />
                                            ) : null}
                                        </div>
                                    })}
                                    <div>
                                        <Label title="Status" />
                                        <Dropdown
                                            id="status"
                                            name="status"
                                            title="status"
                                            value={fields.status}
                                            status={options}
                                            handleChange={(field, e) =>
                                                setFields({ ...fields, [field]: e.target.value })}
                                        />
                                        {errors.status ? (
                                            <Label title="status" fieldError={errors.status} />
                                        ) : null}
                                    </div>
                                </div>
                                <ButtonSection path={'/help'} handleSubmit={handleSubmit} />
                            </form>
                        </div>
                    </div>
                </div>
            </div> </> : <> <Loader /> </>}

        </>
    )
}
