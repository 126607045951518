import {PhoneIcon} from "@heroicons/react/24/outline";
import React, { useState, useEffect } from "react";
import configuration from '../../../config';
import { toast } from 'react-toastify';

// Dynamic class
function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

let Id = "";
// List Settings
function Summary({user_id}) {
    const [actions, setActions] = useState([]);
    useEffect(() => {
        // var url = window.location.href;
        Id = user_id;
        configuration.getAPI({url:'app-user/emergency-contact', params:{user_id:Id}}).then((data) => {
          if(data.payload){
            setActions(data.payload)
          }
        }).catch(error => {
          return toast.error(error.message)
        });
        // console.log(configuration.baseURL);
    }, []);
    return (
        <div className="md:pl-8 sm:py-7 col-span-10">
          <section aria-labelledby="applicant-information-title">
              <div className="bg-white shadow sm:rounded-lg">
                  <div className="px-4 py-5 sm:px-6">
                      <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                          Emergency Contact
                      </h2>
                      <p className="mt-1 max-w-2xl text-base text-gray-500 text_theme">This information is show user emergency contact.</p>
                  </div>
                  <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                      <div className="rounded-lg divide-y divide-gray-200 sm:divide-y-0 sm:grid sm:grid-cols-1 sm:gap-px">
                          <ul role="list" className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
                              {actions.map((person) => (
                                  <li key={person.name} className="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200">
                                      <div className="w-full flex items-center justify-between p-6 space-x-6">
                                          <div className="flex-1 truncate">
                                              <div className="flex items-center space-x-3">
                                                  <h3 className="text-gray-900 text-lg font-bold truncate">{person.name}</h3>
                                                  
                                              </div>                                                                               
                                          </div>                                                                                           
                                      </div>
                                      <div>
                                          <div className="-mt-px flex divide-x divide-gray-200">                                                                                                
                                              <div className="-ml-px w-0 flex-1 flex">
                                                  <a
                                                      href={`tel:${person.mobile}`}
                                                      className="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-base text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500"
                                                  >
                                                      <PhoneIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
                                                      <span className="ml-3">{person.mobile}</span>
                                                  </a>
                                              </div>
                                          </div>
                                      </div>
                                  </li>
                              ))}
                          </ul>
                      </div>
                  </div>
              </div>
          </section>
      </div>
    );
}

export default Summary;
