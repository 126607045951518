import React, { useEffect, useState } from "react";
import Breadcrumb from '../../components/breadcrumb';
import labels from "../../constants/ShareRideDiscount";
import Label from "../../components/Form/Label";
import TextInput from "../../components/Form/TextInput";
import ButtonSection from "../../components/Form/ButtonSection";
import Dropdown from "../../components/Form/Dropdown";
import configuration from '../../config';
import { toast } from 'react-toastify';
import { each, findWhere, range } from 'underscore';
import Loader from '../../components/Loader/Loader';


export default function View({ history }) {
    const options = [
        { id: 1, title: "Active", value: "active" },
        { id: 2, title: "Inactive", value: "inactive" },
    ];
    const options1 = [
        { id: 1, title: "Percentage", value: "percentage" },
        { id: 2, title: "Amount", value: "amount" },
    ];
    const KMLength = 55
    const [fields, setFields] = useState({});
    const [errors, setErrors] = useState({});
    const [isValid, setIsValid] = useState(false)
    const [MinKmOption, setMinKmOption] = useState([])
    const [MaxKmOption, setMaxKmOption] = useState([])
    const [authorisation, setAuthorisation] = useState()
    useEffect(() => {
        MinMaxKm(0, KMLength, 'from_km');
    }, []);
    function validation() {
        let flag = true;
        let error = {}
        if (!fields.from_km) {
            error['from_km'] = "Please enter valid from KM"
            flag = false
        }
        if (!fields.to_km) {
            error['to_km'] = "Please enter valid to KM"
            flag = false
        }
        if (!fields.value) {
            error['value'] = "Please enter valid value"
            flag = false
        }
        if (!fields.status) {
            error['status'] = "Please select status"
            flag = false
        }
        setErrors({ ...error })
        return flag;
    }

    const handleSubmit = () => {
        if (validation()) {
            configuration.allAPI({ url: 'share-ride-discount/create', method: 'put', params: fields }).then((data) => {
                if (data.payload) {
                    history.push("/share-ride-discount")
                } else if (data.error) {
                    return toast.error(data.error.message)
                } else {
                    return toast.error('Something went wrong')
                }
            }).catch(error => {
                return toast.error(error.message)
            });
        }
    }

    useEffect(() => {
        var retrievedObject = JSON.parse(localStorage.getItem('token'));
        if (retrievedObject) {
            configuration.getAPI({ url: 'common/list-access', params: { roleID: retrievedObject.roleID } }).then((data) => {
                if (data.payload) {
                    setAuthorisation(data.payload)
                }
            }).catch(error => {
                return toast.error(error.message)
            });
        }
    }, []);
    useEffect(() => {
        const whereFind = (findWhere(authorisation, { code: 'SHARERIDEDISCOUNT' }))
        if (whereFind?.data?.is_add === false) {
            setIsValid(!isValid)
            history.push('/dashboard')
        }
        if (whereFind?.data?.is_add === true) {
            setIsValid(!isValid)
        }
    }, [authorisation])

    const MinMaxKm = (minimumKm, maximumKm, type) => {
        let no = []
        let options = []
        let minKm = minimumKm
        let number = {}
        const gapList = range(Number(minimumKm), maximumKm, 5)
        each(gapList, (single) => {
            if (single > minKm) {
                number = {
                    key: single,
                    value: single
                }
                no.push(number)
                options.push({ id: single, title: single, value: single })
            }
        })
        if (type == "to_km") {
            setMaxKmOption(options)
        } else {
            setMinKmOption(options)
        }
    }

    const onChangeHandler = (field, e) => {
        if (field == "from_km") {
            setFields({ ...fields, [field]: e.target.value })
            MinMaxKm(e.target.value, KMLength, "to_km");
        }
        setFields({ ...fields, [field]: e.target.value })
    }

    return (
        <>
            {isValid ? <>  <Breadcrumb module={labels.addShareRideDiscount} />
                <div className='main dark:bg-black bg-white mb-5'>
                    <div className="dark:bg-black bg-white">
                        <div className="py-5 px-5 flex items-center justify-between">
                            <h1 className="text-3xl font-bold tracking-tight dark:text-[#fff] text-black">{labels.addShareRideDiscount}</h1>
                        </div>

                        <div className="mt-5 sm:mx-auto sm:w-full px-4">
                            <div className="bg-skin-white py-5 px-5 shadow sm:rounded-lg">
                                <form className="space-y-6" action="#" method="POST">
                                    <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
                                        <div>
                                            <Label title="From KM" />
                                            <Dropdown
                                                id="from_km"
                                                name="from_km"
                                                title="from_km"
                                                value={fields.from_km}
                                                status={MinKmOption}
                                                handleChange={(field, e) =>
                                                    onChangeHandler(field, e)}
                                            />
                                            {errors.from_km ? (
                                                <Label title="from_km" fieldError={errors.from_km} />
                                            ) : null}
                                        </div>
                                        <div>
                                            <Label title="To KM" />
                                            <Dropdown
                                                id="to_km"
                                                name="to_km"
                                                title="to_km"
                                                value={fields.to_km}
                                                status={MaxKmOption}
                                                handleChange={(field, e) =>
                                                    onChangeHandler(field, e)}
                                            />
                                            {errors.to_km ? (
                                                <Label title="to_km" fieldError={errors.to_km} />
                                            ) : null}
                                        </div>

                                        <div>
                                            <Label title="Type" />
                                            <Dropdown
                                                id="type"
                                                name="type"
                                                title="type"
                                                value={fields.type}
                                                status={options1}
                                                handleChange={(field, e) =>
                                                    setFields({ ...fields, [field]: e.target.value })}
                                            />
                                            {errors.type ? (
                                                <Label title="type" fieldError={errors.type} />
                                            ) : null}
                                        </div>
                                        <div>
                                            <Label title="Value" />
                                            <TextInput
                                                id="value"
                                                name="value"
                                                handleChange={(field, e) =>
                                                    setFields({ ...fields, [field]: e.target.value })}
                                                value={fields.value}
                                            />
                                            {errors.value ? (
                                                <Label title="value" fieldError={errors.value} />
                                            ) : null}
                                        </div>
                                        <div>
                                            <Label title="Status" />
                                            <Dropdown
                                                id="status"
                                                name="status"
                                                title="status"
                                                value={fields.status}
                                                status={options}
                                                handleChange={(field, e) =>
                                                    setFields({ ...fields, [field]: e.target.value })}
                                            />
                                            {errors.status ? (
                                                <Label title="status" fieldError={errors.status} />
                                            ) : null}
                                        </div>
                                    </div>
                                    <ButtonSection path={'/share-ride-discount'} handleSubmit={handleSubmit} />
                                </form>
                            </div>
                        </div>
                    </div>
                </div></> : <>  <Loader /> </>}

        </>
    )
}
