import React, { Fragment, useEffect } from "react";
import Breadcrumb from '../../components/breadcrumb';
import labels from "../../constants/Report";
import { useState } from 'react'
import DataTable from '../../components/Table/MDataTable';
import { Menu, Transition } from '@headlessui/react'
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid'
import ActionButton from '../../components/Other/ActionButton';
import configuration from '../../config';
import { toast } from 'react-toastify';
import { pluck, reject, contains, findWhere, each } from 'underscore';
import Label from "../../components/Form/Label";
import DatePicker from "../../components/Form/DatePicker";
import Loader from '../../components/Loader/Loader';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


export default function View({ history }) {

  const [authorisation, setAuthorisation] = useState()
  const [isValid, setIsValid] = useState(false)

  const btnGroups = [
    { title: "Export CSV", type: "exportCSV", path: "/user", fileName: "Trips" },
  ];
  const columns = [
    { title: "ID", field: "user_id" },
    { title: "Name", field: "name" },
    { title: "Email", field: "email" },
    { title: "Mobile", field: "mobile" },
    { title: "Earning", field: "totalEarning" },
  ];

  const [data, setData] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [ids, setIds] = useState([]);
  const fieldArr = [
    { id: 3, title: "From  Date", name: "from_date", type: "DatePicker", disablePast: false },
    { id: 4, title: "To Date", name: "to_date", type: "DatePicker", disablePast: false },
  ]
  const [fieldsFilter, setFieldsFilter] = useState({
    from_date: null,
    to_date: null
  });
  const [errorsFilter, setErrorsFilter] = useState({
    from_date: '',
    to_date: ''
  });
  const onSelectionChange = (e) => {
    let idArr = pluck(e, "_id");
    setIds(idArr);
  };

  function getDatas(fieldsFilter) {
    configuration.getAPI({ url: 'report/driver-earning', params: fieldsFilter }).then((data) => {
      if (data.payload) {
        let CSV = [];
        each(data.payload, single => {
          CSV.push({
            ID: single.user_id,
            Name: single.name,
            Email: single.email,
            Mobile: single.mobile,
            "Driver Earning": single.totalEarning,
          })
        })
        setCsvData(CSV)
        setData(data.payload)
      }
    }).catch(error => {
      return toast.error(error.message)
    });
  }
  function handleChangeFilter(field, e) {
    if (field === 'status') {
      let sessionData = (fieldsFilter.status) ? fieldsFilter.status : []
      if (!contains(sessionData, e.target.value)) {
        sessionData.push(e.target.value)
      } else {
        sessionData = reject(sessionData, function (num) { return num === e.target.value; })
      }
      // console.log(sessionData)
      setFieldsFilter({ ...fieldsFilter, [field]: sessionData })
      if (sessionData && sessionData.length <= 0) {
        delete fieldsFilter.status;
        getDatas({ fieldsFilter })
      } else {
        getDatas({ ...fieldsFilter, [field]: sessionData })
      }
    } else {
      setFieldsFilter({ ...fieldsFilter, [field]: e })
    }
  }
  function handleFilterValidation() {
    let flag = true;
    let error = {}
    if (fieldsFilter.to_date && !fieldsFilter.from_date) {
      error['from_date'] = "Please select from date"
      flag = false
    }
    if (fieldsFilter.from_date && !fieldsFilter.to_date) {
      error['to_date'] = "Please select to date"
      flag = false
    }
    if (fieldsFilter.from_date && fieldsFilter.to_date) {
      if (fieldsFilter.to_date < fieldsFilter.from_date) {
        flag = false;
        error["to_date"] = "Please select end date greater than to start date.";
      }
    }
    if (fieldsFilter.status && fieldsFilter.status.length <= 0) {
      delete fieldsFilter.status;
      setFieldsFilter(fieldsFilter)
    }
    setErrorsFilter({ ...error })
    return flag;
  }
  function handleApplyFilter() {
    if (handleFilterValidation()) {
      getDatas(fieldsFilter)
    }
  }
  function handleClearFilter() {
    setFieldsFilter({ status: [], from_date: null, to_date: null })
    getDatas({})
  }
  useEffect(() => {
    var retrievedObject = JSON.parse(localStorage.getItem('token'));
    if (retrievedObject) {
      configuration.getAPI({ url: 'common/list-access', params: { roleID: retrievedObject.roleID } }).then((data) => {
        if (data.payload) {
          setAuthorisation(data.payload)
        }
      }).catch(error => {
        return toast.error(error.message)
      });
    }
    getDatas({});
  }, []);


  useEffect(() => {
    const whereFind = (findWhere(authorisation, { code: 'R_DRIVER' }))
    if (whereFind?.data?.is_view === false) {
      setIsValid(!isValid)
      history.push('/dashboard')
    }
    if (whereFind?.data?.is_view === true) {
      setIsValid(!isValid)
    }
  }, [authorisation])

  return (
    <>

      {isValid ? <>  <Breadcrumb module={labels.driverEarning} />
        <div className='main dark:bg-black bg-white mb-5'>
          <div className="dark:bg-black bg-white">
            <div className="py-5 px-5 flex items-center justify-between">
              <h1 className="text-3xl font-bold tracking-tight dark:text-[#fff] text-black">{labels.driverEarning}</h1>
              <div className='flex items-center gap-3'>
                <Menu as="div" className="relative inline-block text-left">
                  <div>
                    <Menu.Button className="flex items-center rounded-full border-2 py-2 px-2 dark:border-[#00FF00] border-[#000] outline-none ">
                      <span className="sr-only">Open options</span>
                      <EllipsisVerticalIcon className="h-5 w-5 dark:text-white text-black" aria-hidden="true" />
                    </Menu.Button>
                  </div>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-30 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="py-1">
                        {btnGroups.map((btn) => {
                          return (
                            <ActionButton
                              key={btn.id}
                              btnTitle={btn.title}
                              btnType={btn.type}
                              btnPath={btn.path}
                              csvData={csvData}
                              csvTitle={"Driver Earning"}
                            />
                          );
                        })}
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
            <div className="dark:bg-black bg-white px-4 mt-5">
              <div className="grid grid-cols-1 gap-y-6 gap-x-4 lg:grid-cols-3 mt-5">
                {fieldArr.map((field, index) => {
                  return (
                    <div className="" key={index}>
                      <Label title={field.title} />
                      <div className="mt-1 relative">
                        {field.type === "DatePicker" ? (
                          <DatePicker
                            id={field.name}
                            name={field.name}
                            handleChange={(name, e) => handleChangeFilter(name, e)}
                            value={fieldsFilter[field.name]}
                            disablePast={field.disablePast}
                            disableFuture={field.disableFuture}
                          />
                        ) : null}
                      </div>
                      {errorsFilter[field.name] ? <Label title={field.title} fieldError={errorsFilter[field.name]} /> : null}
                    </div>
                  );
                })}
              </div>
              <div className="py-2 flex gap-5 items-center">
                <button
                  type="button"
                  onClick={handleApplyFilter}
                  className="btn-link ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-[16px] font-medium shadow-sm bg-skin-primary text-skin-white rounded-md"
                >
                  Apply
                </button>
                <button
                  onClick={handleClearFilter}
                  type="button"
                  className="btn-link py-2 px-4 border border-gray-300 rounded-md shadow-sm text-[16px] font-medium shadow-sm bg-skin-white text-skin-black rounded-md"
                >
                  Clear
                </button>
              </div>
            </div>
            <div className="dark:bg-black bg-white px-4 mt-5">
              <div className="mt-8 flex flex-col">
                <div className='datatable'>
                  <DataTable
                    columns={columns}
                    data={data}
                    disabledCheckbox={true}
                    onSelectionChange={onSelectionChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>  </> : <><Loader /> </>}

    </>
  )
}
