import React, { useEffect, useState } from "react";
import Breadcrumb from '../../components/breadcrumb';
import labels from "../../constants/Setting";
import { Link } from 'react-router-dom';
import { findWhere } from 'underscore';
import configuration from '../../config';

export default function View({ history }) {

  const cards = [
    {
      id: 0,
      module: "Localizations",
      icon: "M9 20l-5.447-2.724A1 1 0 013 16.382V5.618a1 1 0 011.447-.894L9 7m0 13l6-3m-6 3V7m6 10l4.553 2.276A1 1 0 0021 18.382V7.618a1 1 0 00-.553-.894L15 4m0 13V4m0 0L9 7",
      menu: [
        { id: 0, title: "Language", code: 'LANGUAGE', redirect: "/language" },
        { id: 1, title: "Currency", code: 'CURRENCY', redirect: "/currency" },
        { id: 2, title: "Tax", code: 'TAX', redirect: "/tax" },
      ],
    },
    {
      id: 1,
      module: "Customizations",
      icon: "M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z",
      menu: [
        { id: 0, title: "Email Templates", code: 'EMAIL_T', redirect: "/email-template" },
        { id: 1, title: "Push Templates", code: 'PUSH_T', redirect: "/push-template" },
        { id: 2, title: "Sms Templates", code: 'SMS_T', redirect: "/sms-template" },
      ],
    },
  ];
  const [access, setAccess] = useState([]);

  useEffect(() => {
    var retrievedObject = JSON.parse(localStorage.getItem('token'));
    if (retrievedObject) {
      configuration.getAPI({ url: 'common/list-access', params: { roleID: retrievedObject.roleID } }).then((data) => {
        if (data.payload) {
          // console.log(data.payload)
          setAccess(data.payload);
        }
      }).catch(error => {
        console.log(error)
        // return toast.error(error.message)
      });
      // history.push("/dashboard")
    } else {
      history.push("/")
    }
  }, []);

  return (
    <>
      <Breadcrumb module={labels.adminland} />
      <div className="py-6 sm:px-6">
        <div className="relative">
          <section
            className="mt-20 pb-32 px-4"
            aria-labelledby="contact-heading"
          >
            <div className="grid grid-cols-1 gap-y-20 lg:grid-cols-4 lg:gap-y-0 lg:gap-x-8">
              {cards.map((card) => {

                return (
                  <div
                    className="flex flex-col bg-skin-white rounded-2xl shadow-xl mb-20 "
                    key={card.id}
                  >
                    <div className="flex-1 relative pt-16 px-6 pb-4 md:px-8">
                      <div className="absolute top-0 p-4 inline-block bg-skin-primary rounded-xl shadow-lg transform -translate-y-1/2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-8 w-8 text-skin-white"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d={card.icon}
                          />
                          {card.module === "Customizations" ? (
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d={card.icon2}
                            />
                          ) : null}
                        </svg>
                      </div>
                      <h3 className="text-xl font-medium text-skin-secondary">
                        {card.module}
                      </h3>
                    </div>
                    {card.menu.map((submenu, index) => {
                      let submenuRow =
                        index % 2 === 0
                          ? "p-4 bg-skin-lightgray md:px-8"
                          : "p-4 bg-skin-white md:px-8";

                      submenuRow += card.menu.length - 1 === index ? " rounded-bl-2xl rounded-br-2xl" : ''
                      let accessData = findWhere(access, { code: submenu.code });
                      if (accessData && accessData.data.is_view) {
                        return (
                          <div className={submenuRow} key={submenu.id}>
                            {submenu.redirect !== "/switch" && submenu.redirect !== '/color_theme' ?
                              <Link
                                to={submenu.redirect}
                                className="text-base font-medium text-skin-primary link"
                              >
                                {submenu.title}
                                <span aria-hidden="true"> &rarr;</span>
                              </Link> : ""}
                          </div>
                        );
                      } else {
                        return '';
                      }
                    })}
                  </div>
                );
              })}
            </div>
          </section>
        </div>
      </div>
    </>
  )
}
