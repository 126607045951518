import React, { useEffect, useState } from "react";
import Breadcrumb from '../../components/breadcrumb';
import labels from "../../constants/Points";
import Label from "../../components/Form/Label";
import TextInput from "../../components/Form/TextInput";
import ButtonSection from "../../components/Form/ButtonSection";
import Dropdown from "../../components/Form/Dropdown";
import configuration from '../../config';
import { toast } from 'react-toastify';
import { each, findWhere } from 'underscore';
import Loader from "../../components/Loader/Loader";
export default function View({ history }) {
    const options = [
        { id: 1, title: "Active", value: "active" },
        { id: 2, title: "Inactive", value: "inactive" },
    ];
    const userTypeOpt = [
        { id: 1, title: "Passanger", value: "passenger" },
        { id: 2, title: "Driver", value: "driver" },
    ];

    const [languages, setLanguages] = useState([])
    // const [icon, setIcon] = useState(null);
    const [fields, setFields] = useState({});
    const [errors, setErrors] = useState({});
    const [isPassanger, setIsPassanger] = useState(false);
    const [isDriver, setIsDriver] = useState(false);
    const [isValid, setIsValid] = useState(false)
    const [authorisation, setAuthorisation] = useState()
    useEffect(() => {
        configuration.getAPI({ url: 'language/list', params: { status: 'active' } }).then((data) => {
            // console.log(data)
            if (data.payload) {
                setLanguages(data.payload)
            }
        }).catch(error => {
            // console.log(error)
            return toast.error(error.message)
        });
    }, []);
    function validation() {
        let flag = true;
        let error = {}
        each(languages, (lang, ind) => {
            if (!fields['title_' + lang.code]) {
                error['title_' + lang.code] = "Please enter title in " + lang.title
                flag = false
            }
        })
        if (!fields.points) {
            error['points'] = "Please enter points"
            flag = false
        }
        if (!fields.status) {
            error['status'] = "Please select status"
            flag = false
        }
        setErrors({ ...error })
        return flag;
    }

    const handleSubmit = () => {
        if (validation()) {
            let titleObj = {}
            each(languages, (lang, ind) => {
                titleObj[lang.code] = fields['title_' + lang.code] ? fields['title_' + lang.code] : '';
                delete fields['title_' + lang.code];
            })
            fields.title = titleObj;
            /* const formData = new FormData();
            formData.append('fields', JSON.stringify({ ...fields, lang_code: 'EN' })); */
            // formData.append('icon', icon);
            configuration.postAPI({ url: 'points/create', params: fields }).then((data) => {
                // console.log(data)
                if (data.payload) {
                    history.push("/points")
                } else if (data.error) {
                    return toast.error(data.error.message)
                } else {
                    return toast.error('Something went wrong')
                }
            }).catch(error => {
                // console.log(error)
                return toast.error(error.message)
            });
        }
    }

    useEffect(() => {
        var retrievedObject = JSON.parse(localStorage.getItem('token'));
        if (retrievedObject) {
            configuration.getAPI({ url: 'common/list-access', params: { roleID: retrievedObject.roleID } }).then((data) => {
                if (data.payload) {
                    setAuthorisation(data.payload)
                }
            }).catch(error => {
                return toast.error(error.message)
            });
        }
    }, []);
    useEffect(() => {
        const whereFind = (findWhere(authorisation, { code: 'POINTS' }))
        if (whereFind?.data?.is_add === false) {
            setIsValid(!isValid)
            history.push('/dashboard')
        }
        if (whereFind?.data?.is_add === true) {
            setIsValid(!isValid)
        }
    }, [authorisation])

    const changeHandle = (field, e) => {
        if (field === "userType") {
            if (e.target.value === "driver") {
                setIsDriver(true)
                setIsPassanger(false)
            } else {
                setIsDriver(false)
                setIsPassanger(true)
            }
        }
        setFields({ ...fields, [field]: e.target.value })
    }

    return (
        <> {isValid ? <>  <Breadcrumb module={labels.addPoints} />
            <div className='main dark:bg-black bg-white mb-5'>
                <div className="dark:bg-black bg-white">
                    <div className="py-5 px-5 flex items-center justify-between">
                        <h1 className="text-3xl font-bold tracking-tight dark:text-[#fff] text-black">{labels.addPoints}</h1>
                    </div>

                    <div className="mt-5 sm:mx-auto sm:w-full px-4">
                        <div className="bg-skin-white py-5 px-5 shadow sm:rounded-lg">
                            <form className="space-y-6" action="#" method="POST">
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
                                    {languages.map((single) => {
                                        return <div>
                                            <Label title={`Title [${single.title}]`} />
                                            <TextInput
                                                id={`title_${single.code}`}
                                                name={`title_${single.code}`}
                                                handleChange={(field, e) => changeHandle(field, e)}
                                                value={fields[`title_${single.code}`]}
                                            />
                                            {errors[`title_${single.code}`] ? (
                                                <Label title={`title_${single.code}`} fieldError={errors[`title_${single.code}`]} />
                                            ) : null}
                                        </div>
                                    })}
                                    <div>
                                        <Label title="User Type" />
                                        <Dropdown
                                            id="userType"
                                            name="userType"
                                            title="userType"
                                            value={fields.userType}
                                            status={userTypeOpt}
                                            handleChange={(field, e) => changeHandle(field, e)}
                                        />
                                        {errors.status ? (
                                            <Label title="userType" fieldError={errors.userType} />
                                        ) : null}
                                    </div>
                                    {
                                        (isPassanger) ?
                                            <div>
                                                <Label title="User Total Spending" />
                                                <TextInput
                                                    id="user_total_spending"
                                                    name="user_total_spending"
                                                    handleChange={(field, e) => changeHandle(field, e)}
                                                    value={fields.user_total_spending}
                                                    is_number={true}
                                                />
                                                {errors.user_total_spending ? (
                                                    <Label title="user_total_spending" fieldError={errors.user_total_spending} />
                                                ) : null}
                                            </div>
                                            : ""
                                    }
                                    {
                                        (isDriver) ?
                                            <div>
                                                <Label title="User Total Earning" />
                                                <TextInput
                                                    id="user_total_earning"
                                                    name="user_total_earning"
                                                    handleChange={(field, e) => changeHandle(field, e)}
                                                    value={fields.user_total_earning}
                                                    is_number={true}
                                                />
                                                {errors.user_total_earning ? (
                                                    <Label title="user_total_earning" fieldError={errors.user_total_earning} />
                                                ) : null}
                                            </div>
                                            : ""
                                    }
                                    <div>
                                        <Label title="Points" />
                                        <TextInput
                                            id="points"
                                            name="points"
                                            handleChange={(field, e) => changeHandle(field, e)}
                                            value={fields.points}
                                            is_number={true}
                                        />
                                        {errors.points ? (
                                            <Label title="points" fieldError={errors.points} />
                                        ) : null}
                                    </div>
                                    <div>
                                        <Label title="Status" />
                                        <Dropdown
                                            id="status"
                                            name="status"
                                            title="status"
                                            value={fields.status}
                                            status={options}
                                            handleChange={(field, e) => changeHandle(field, e)}
                                        />
                                        {errors.status ? (
                                            <Label title="status" fieldError={errors.status} />
                                        ) : null}
                                    </div>
                                </div>
                                <ButtonSection path={'/points'} handleSubmit={handleSubmit} />
                            </form>
                        </div>
                    </div>
                </div>
            </div></> : <> <Loader /> </>}

        </>
    )
}
