import {
  DocumentTextIcon,
  PhoneIcon,
  PhoneArrowUpRightIcon,
  ServerIcon,
  DocumentChartBarIcon,
  CurrencyDollarIcon,
  MapPinIcon,
  ShieldCheckIcon,
  CreditCardIcon,
  TruckIcon,
  FingerPrintIcon,
  GiftIcon,
  AcademicCapIcon,
  UsersIcon,
  UserGroupIcon,
  DevicePhoneMobileIcon,
  AtSymbolIcon,
  CogIcon,
  BanknotesIcon,
} from "@heroicons/react/24/outline";
import React, { useState, useEffect } from "react";
import Breadcrumb from "../../components/breadcrumb";
import labels from "../../constants/AppUser";
import configuration from "../../config";
import Summary from "./profile/summary";
import PaymentInfo from "./profile/PaymentInfo";
import Trips from "./profile/trips";
import FavouriteAddress from "./profile/favouriteAddress";
import Setting from "./profile/setting";
import Services from "./profile/services";
import Document from "./profile/document";
import Vehicles from "./profile/vehicles";
import Memberships from "./profile/Memberships";
import Contacts from "./profile/Contacts";
import Earning from "./profile/earning";
import Bank from "./profile/bank";
import PointsTrips from "./profile/pointsTrips";
import { toast } from "react-toastify";
import { each } from "underscore";
import { Link, useLocation } from "react-router-dom";
import { RadioGroup } from "@headlessui/react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const memoryOptions = [
  { name: "As Passenger", key: "passenger" },
  { name: "As Driver", key: "driver" },
];
let Id = "";
export default function View({ history }) {
  // const location = useLocation();
  // const [Id, setId] = useState('')
  const [mem, setMem] = useState(memoryOptions[0]);
  const [driverData, setDriverData] = useState({
    bank_detail: {},
    isDocumentVerify: false,
    isVehicleVerify: false,
    driverDocumentVerificationMode: false,
    driverVehicleVerificationMode: false,
  });
  const [reloadState, setReloadState] = useState(false);
  const [subNavigation, setSubNavigation] = useState([
    { name: "Summary ", href: "#", icon: DocumentChartBarIcon, current: true, type: "both" },
    { name: "Services ", href: "#", icon: ServerIcon, current: false, type: "driver" },
    { name: "Payment Info ", href: "#", icon: CreditCardIcon, current: false, type: "passenger" },
    { name: "Trips ", href: "#", icon: MapPinIcon, current: false, type: "both" },
    { name: "Favourite Address", href: "#", icon: MapPinIcon, current: false, type: "passenger" },
    {
      name: "Document Info",
      href: "#",
      icon: DocumentTextIcon,
      current: false,
      type: "driver",
      isBadge: true,
    },
    {
      name: "Vehicles",
      href: "#",
      icon: TruckIcon,
      current: false,
      type: "driver",
      isBadge: true,
    },
    { name: "Memberships  ", href: "#", icon: UsersIcon, current: false, type: "driver" },
    { name: "Earnings ", href: "#", icon: CurrencyDollarIcon, current: false, type: "driver" },
    { name: "Bank ", href: "#", icon: BanknotesIcon, current: false, type: "driver" },
    { name: "Emergency Contacts", href: "#", icon: PhoneIcon, current: false, type: "driver" },
    { name: "App Settings ", href: "#", icon: CogIcon, current: false, type: "both" },
    { name: "Points & Trips ", href: "#", icon: GiftIcon, current: false, type: "both" },
  ]);
  const [tab, setTab] = useState(0);
  function setTabMenu(index) {
    setTab(index);
    const navigate = subNavigation;
    each(navigate, (element, i) => {
      let currentState = false;
      if (i === index) {
        currentState = true;
      }
      navigate[i].current = currentState;
    });
    setSubNavigation(navigate);
  }
  useEffect(() => {
    let url = window.location.href;
    Id = url.substring(url.lastIndexOf("/") + 1);
    // setId(ID);
    configuration
      .getAPI({ url: "app-user/detail", params: { user_id: Id } })
      .then((data) => {
        if (data.payload) {
          setDriverData({ ...data.payload });
          setMem(data.payload.currentProfile === "driver" ? memoryOptions[1] : memoryOptions[0]);
          /* for new user flag manage */
          if (data.payload.isNewUser) {
            configuration
              .allAPI({ url: "app-user/update-user", method: "PATCH", params: { _id: Id, isNewUser: false } })
              .then((data) => {})
              .catch((error) => {
                return toast.error(error.message);
              });
          }
        }
      })
      .catch((error) => {
        // console.log(error)
        return toast.error(error.message);
      });
  }, [reloadState]);

  function reloadStateFun() {
    setReloadState(!reloadState);
  }

  return (
    <>
      <Breadcrumb module={labels.contactUs} />
      <div className="py-6 sm:px-6">
        <div className="relative">
          <section className="px-4" aria-labelledby="contact-heading">
            <div className="flex flex-col">
              <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                  <div className="w-full md:flex md:items-center md:justify-between md:space-x-5 mb-6 mt-4">
                    <div className="flex items-top space-x-5">
                      <div className="flex-shrink-0">
                        <div className="relative">
                          <img
                            className="h-16 w-16 rounded-full"
                            src={driverData.photo ? driverData.photo : "/image/avatar.png"}
                            alt=""
                          />
                          <span className="absolute inset-0 shadow-inner rounded-full" aria-hidden="true" />
                        </div>
                      </div>
                      <div>
                        <h1 className="text-2xl font-bold text-skin-primary">{driverData.name}</h1>
                        <p class="mt-3 flex items-center text-sm text-skin-darkGray font-medium sm:mr-6 sm:mt-0 capitalize py-1">
                          <DevicePhoneMobileIcon
                            className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                          {driverData.mobile_country_code} {driverData.mobile}
                        </p>
                        <p class="mt-3 flex items-center text-sm text-skin-darkGray font-medium sm:mr-6 sm:mt-0 py-1">
                          <AtSymbolIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                          {driverData.email}
                        </p>
                      </div>
                    </div>
                    <div className="items-end">
                      <div className="flex items-center justify-between">
                        <h2 className="text-sm font-medium text-skin-darkGray">Switch Account</h2>
                      </div>

                      <RadioGroup
                        value={mem}
                        onChange={(e) => {
                          setMem(e);
                          setTabMenu(0);
                        }}
                        className="mt-2"
                      >
                        <RadioGroup.Label className="sr-only">Choose a memory option</RadioGroup.Label>
                        <div className="grid grid-cols-3 gap-3 sm:grid-cols-3">
                          {memoryOptions.map((option) => (
                            <RadioGroup.Option
                              key={option.name}
                              value={option}
                              // onChannge={() => setTabMenu(0)}
                              className={({ active, checked }) =>
                                classNames(
                                  "cursor-pointer focus:outline-none",
                                  active ? "ring-2 ring-offset-2 ring-indigo-500" : "",
                                  checked
                                    ? "bg-skin-primary border-transparent text-skin-white hover:bg-skin-secondary"
                                    : "bg-skin-white border-skin-light text-skin-black hover:bg-skin-secondary hover:text-skin-white ",
                                  "border rounded-md py-3 px-3 flex items-center justify-center text-sm font-medium Capitalize sm:flex-1"
                                )
                              }
                            >
                              <RadioGroup.Label as="p">{option.name}</RadioGroup.Label>
                            </RadioGroup.Option>
                          ))}
                        </div>
                      </RadioGroup>
                    </div>
                  </div>

                  <div className="">
                    <main className="relative">
                      <div className="mx-auto">
                        <div className="">
                          <div className="divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0">
                            <aside className="py-6 lg:col-span-2">
                              <nav className="space-y-1">
                                {subNavigation.map((item, index) =>
                                  item.type === mem.key || item.type === "both" ? (
                                    <Link
                                      to="#"
                                      key={item.name}
                                      onClick={() => setTabMenu(index)}
                                      onKeyDown={() => setTabMenu(index)}
                                      className={classNames(
                                        item.current
                                          ? "bg-skin-white text-skin-red hover:bg-skin-white hover:text-skin-red rounded-md"
                                          : "border-transparent text-skin-darkGray hover:bg-skin-gray hover:text-skin-red text_theme rounded-md",
                                        "group px-3 py-2 flex items-center text-base"
                                      )}
                                      aria-current={item.current ? "page" : null}
                                    >
                                      <item.icon
                                        className={classNames(
                                          item.current
                                            ? "text-skin-red group-hover:text-skin-red"
                                            : "text-skin-darkGray group-hover:text-skin-red",
                                          "flex-shrink-0 -ml-1 mr-3 h-6 w-6"
                                        )}
                                        aria-hidden="true"
                                      />
                                      <span className="relative flex items-center justify-between w-full">
                                        {item.name}
                                        {item.name === "Vehicles" &&
                                        (driverData.driverVehicleVerificationMode || !driverData.isVehicleVerify) ? (
                                          <div class="inline-flex items-center justify-center w-3 h-3 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full -top-0 -right-2 dark:border-gray-900"></div>
                                        ) : item.name === "Document Info" &&
                                          (driverData.driverDocumentVerificationMode ||
                                            !driverData.isDocumentVerify) ? (
                                          <div class="inline-flex items-center justify-center w-3 h-3 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full -top-0 -right-2 dark:border-gray-900"></div>
                                        ) : (
                                          ""
                                        )}
                                      </span>
                                    </Link>
                                  ) : (
                                    ""
                                  )
                                )}
                              </nav>
                            </aside>
                            {tab === 0 ? <Summary user_id={Id} user_type={mem.key} /> : ""}
                            {tab === 1 ? <Services user_id={Id} /> : ""}
                            {tab === 2 ? <PaymentInfo user_id={Id} /> : ""}
                            {tab === 3 ? <Trips user_id={Id} user_type={mem.key} /> : ""}
                            {tab === 4 ? <FavouriteAddress user_id={Id} /> : ""}
                            {tab === 5 ? <Document user_id={Id} reloadStateFun={reloadStateFun} /> : ""}
                            {tab === 6 ? <Vehicles user_id={Id} reloadStateFun={reloadStateFun} /> : ""}
                            {tab === 7 ? <Memberships user_id={Id} /> : ""}
                            {tab === 8 ? <Earning user_id={Id} /> : ""}
                            {tab === 9 ? <Bank bank_data={driverData.bank_detail} /> : ""}
                            {tab === 10 ? <Contacts user_id={Id} /> : ""}
                            {tab === 11 ? <Setting user_id={Id} /> : ""}
                            {tab === 12 ? <PointsTrips user_id={Id} /> : ""}
                          </div>
                        </div>
                      </div>
                    </main>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}
