import React, { useEffect, useState } from "react";
import Breadcrumb from '../../components/breadcrumb';
import labels from "../../constants/Module";
import Label from "../../components/Form/Label";
import TextInput from "../../components/Form/TextInput";
import ButtonSection from "../../components/Form/ButtonSection";
import Dropdown from "../../components/Form/Dropdown";
import configuration from '../../config';
import { toast } from 'react-toastify';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}


export default function View({ history }) {
    const options = [
        { id: 1, title: "Active", value: "active" },
        { id: 2, title: "Inactive", value: "inactive" },
    ];
    const [fields, setFields] = useState({});
    const [errors, setErrors] = useState({});
    useEffect(() => {
        
    }, []);
    function validation() {
        let flag = true;
        let error = {}
        if (!fields.title) {
          error['title'] = "Please enter valid title"
          flag = false
        }
    
        if (!fields.code) {
          error['code'] = "Please enter valid code"
          flag = false
        }
        setErrors({...error })
        return flag;
      }

    const handleSubmit = () => {
        if (validation()) {
            configuration.allAPI({ url: 'module/create', method:'put', params: fields }).then((data) => {
                // console.log(data)
                if (data.payload) {
                  history.push("/module")
                } else if (data.error) {
                  return toast.error(data.error.message)
                } else {
                  return toast.error('Something went wrong')
                }
              }).catch(error => {
                // console.log(error)
                return toast.error(error.message)
              });
        }
     }
    return (
        <>
            <Breadcrumb module={labels.addModule} />
            <div className='main dark:bg-black bg-white mb-5'>
                <div className="dark:bg-black bg-white">
                    <div className="py-5 px-5 flex items-center justify-between">
                        <h1 className="text-3xl font-bold tracking-tight dark:text-[#fff] text-black">{labels.addModule}</h1>
                    </div>

                    <div className="mt-5 sm:mx-auto sm:w-full px-4">
                        <div className="bg-skin-white py-5 px-5 shadow sm:rounded-lg">
                            <form className="space-y-6" action="#" method="POST">
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
                                    <div>
                                        <Label title="Title" />
                                        <TextInput
                                            id="title"
                                            name="title"
                                            handleChange={(field, e) =>
                                                setFields({ ...fields, [field]: e.target.value })}
                                            value={fields.title}
                                        />
                                        {errors.title ? (
                                            <Label title="title" fieldError={errors.title} />
                                        ) : null}
                                    </div>
                                    <div>
                                        <Label title="Code" />
                                        <TextInput
                                            id="code"
                                            name="code"
                                            handleChange={(field, e) =>
                                                setFields({ ...fields, [field]: e.target.value })}
                                            value={fields.code}
                                        />
                                        {errors.code ? (
                                            <Label title="code" fieldError={errors.code} />
                                        ) : null}
                                    </div>
                                </div>
                                <ButtonSection path={'/module'} handleSubmit={handleSubmit} />
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
