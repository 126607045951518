import React, { Fragment, useState, useEffect } from "react";
import configuration from '../../../config';
import { toast } from 'react-toastify';
import { Switch } from '@headlessui/react'

let Id = "";
// List Settings
// Dynamic class
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
function Summary({ user_id, user_type }) {
  const [profile, setProfile] = useState({});
  const [isDriverApprove, setisDriverApprove] = useState(false);
  useEffect(() => {
    let url = window.location.href;
    Id = url.substring(url.lastIndexOf("/") + 1);
    // Id = user_id
    configuration.getAPI({ url: 'app-user/detail', params: { user_id: Id } }).then((data) => {
      // console.log(data)
      if (data.payload) {
        let profileData = {
          'ID': `USR${data.payload._id}`,
          'Name': data.payload.name,
          'Email': data.payload.email,
          'Mobile': `${(data.payload.mobile_country_code) ? data.payload.mobile_country_code : ''} ${data.payload.mobile}`,
          'Reg Date': data.payload.createdAt,
          'Status': data.payload.status,
          'Average Rating': Number(data.payload.averageRatings).toFixed(1)
        }
        profileData['Is Current Ride'] = (data.payload.isCurrentRide) ? "Yes" : "No"
        profileData['Is Vehicle Verify'] = (data.payload.isVehicleVerify) ? "Yes" : "No"
        profileData['Is Document Verify'] = (data.payload.isDocumentVerify) ? "Yes" : "No"
        if (data.payload.social_security_number && data.payload.social_security_number) {
          profileData['Social Security Number'] = data.payload.social_security_number
        }
        if (data.payload.device_details && data.payload.device_details.device_name) {
          profileData['Device Name'] = data.payload.device_details.device_name
        }
        if (data.payload.device_details && data.payload.device_details.device_type) {
          profileData['Device Type'] = data.payload.device_details.device_type
        }
        if (data.payload.device_details && data.payload.device_details.device_token) {
          profileData['Device Token'] = data.payload.device_details.device_token
        }
        setProfile(profileData)
        setisDriverApprove(data.payload.isDriverApprove)
      }
    }).catch(error => {
      // console.log(error)
      return toast.error(error.message)
    });
    // console.log(configuration.baseURL);
  }, []);

  function onChangeSwitch(e) {
    configuration.allAPI({ url: 'app-user/update-approve', method: 'PATCH', params: { isDriverApprove: (e) ? 'yes' : 'no', user_id: Id } }).then((data) => {
      if (data.payload) {
        setisDriverApprove(e)
        return toast.success('Update successfully')
      } else if (data.error.message) {
        return toast.error(data.error.message)
      } else {
        return toast.error('Something went wrong')
      }
    }).catch(error => {
      return toast.error(error.message)
    });
  }

  return (
    <>
      <div className="md:pl-8 sm:py-7 col-span-10">
        <section aria-labelledby="applicant-information-title">
          <div className="bg-white shadow sm:rounded-lg">
            <div className="flex justify-between">
              <div className="px-4 py-5 sm:px-6">
                <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                  Summary
                </h2>
                <p className="mt-1 max-w-2xl text-sm text-gray-500 text_theme">This information is show user summary.</p>
              </div>
              {/* {(user_type === 'driver' && profile['Is Vehicle Verify'] === 'Yes' && profile['Is Document Verify'] === 'Yes')? */}
              {(user_type === 'driver') ?
                <div className="flex items-center">
                  <label htmlFor="push-everything" className="text-medium leading-6 font-medium text-gray-900">
                    Approve Driver
                  </label> &nbsp;&nbsp;
                  <Switch
                    checked={isDriverApprove}
                    onChange={(e) => onChangeSwitch(e)}
                    className={classNames(
                      isDriverApprove ? 'bg-indigo-600' : 'bg-gray-200',
                      'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                    )}
                  >
                    <span className="sr-only">Use setting</span>
                    <span
                      aria-hidden="true"
                      className={classNames(
                        isDriverApprove ? 'translate-x-5' : 'translate-x-0',
                        'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                      )}
                    />
                  </Switch>
                </div> : ''
              }
            </div>
            <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
              < div className="">
                <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                  {Object.keys(profile).map((field) => (
                    <div key={field} className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">{field}</dt>
                      <dd className={classNames("mt-1 text-sm text-gray-900 md:break-all", (field !== 'Email') ? "capitalize" : '')}>{profile[field]}</dd>
                    </div>
                  ))}
                </dl>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Summary;
