import { Link } from 'react-router-dom';
import Logo from '../../assets/img/logo.png';
import React, { useState, useEffect } from "react";
import configuration from '../../config';
import { toast } from 'react-toastify';
import Label from "../../components/Form/Label";
import TextInput from "../../components/Form/TextInput";
import { reactLocalStorage } from 'reactjs-localstorage';
// var jwt = require('jsonwebtoken');

export default function SignUp({history}) {
    const [message, setMessage] = useState("Please wait for your email to be verified");
      useEffect(() => {
        let url = window.location.href;
        let resetCode = url.substring(url.lastIndexOf("/") + 1);
        configuration.allAPI({ url: 'app-user/check-email', method: 'PATCH', params: {'resetCode': resetCode} }).then((data) => {
          // console.log(data)
          if (data.payload) {
            setMessage("You have successfully verified your email address. Thank you for your patience.")
          } else if (data.error) {
            // return toast.error(data.error.message)
            // setMessage(data.error.message)
            setMessage("We're sorry but your email hasn't been verified.")
          } else {
            setMessage("We're sorry but your email hasn't been verified.")
            // return toast.error('Something went wrong')
          }
        }).catch(error => {
          // console.log(error)
          return toast.error(error.message)
        });
      }, []);
    return (
        <div className="flex dark:bg-black h-screen min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <img
                    className="mx-auto h-28 w-auto"
                    src={Logo}
                    alt="Your Company"
                />
                <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-skin-primary">{message}</h2>
            </div>
        </div>
    )
}
