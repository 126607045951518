import React, { useEffect, useState } from "react";
import Breadcrumb from '../../components/breadcrumb';
import labels from "../../constants/SMSTemplate";
import Label from "../../components/Form/Label";
import TextInput from "../../components/Form/TextInput";
import ButtonSection from "../../components/Form/ButtonSection";
import Dropdown from "../../components/Form/Dropdown";
import configuration from '../../config';
import { toast } from 'react-toastify';
import { each, findWhere } from 'underscore';
import Loader from "../../components/Loader/Loader";
export default function Edit({ history }) {
    const options = [
        { id: 1, title: "Active", value: "active" },
        { id: 2, title: "Inactive", value: "inactive" },
    ];
    const [languages, setLanguages] = useState([])
    const [fields, setFields] = useState({});
    const [errors, setErrors] = useState({}); 
    const [isValid, setIsValid] = useState(false)
    const [authorisation, setAuthorisation] = useState()
    useEffect(() => {
        let url = window.location.href;
        let ID = url.substring(url.lastIndexOf("/") + 1);
        configuration.getAPI({ url: 'language/list', params: { status: 'active' } }).then((data1) => {
            // console.log(data)
            if (data1.payload) {
                setLanguages(data1.payload)
                configuration.getAPI({ url: 'sms-template/list', params: { ID } }).then((data) => {
                    // console.log(data)
                    if (data.payload) {
                        let questionObj = {}
                        each(data1.payload, (lang, ind) => {
                            questionObj['value' + lang.code] = data.payload.value[lang.code] ? data.payload.value[lang.code] : '';
                        })
                        delete data.payload.value;
                        setFields({ ...questionObj, ...data.payload })
                    }
                }).catch(error => {
                    console.log(error)
                    return toast.error(error.message)
                });
            }
        }).catch(error => {
            // console.log(error)
            return toast.error(error.message)
        });
    }, []);
    function validation() {
        let flag = true;
        let error = {}
        each(languages, (lang, ind) => {
            if (!fields['value' + lang.code]) {
                error['value' + lang.code] = "Please enter Value in " + lang.title
                flag = false
            }
        })
        // if (!fields.template_code) {
        //     error['template_code'] = "Please enter code"
        //     flag = false
        // }
        if (!fields.title) {
            error['title'] = "Please enter title"
            flag = false
        }
        if (!fields.status) {
            error['status'] = "Please select status"
            flag = false
        }
        setErrors({ ...error })
        return flag;
    }

    const handleSubmit = () => {
        if (validation()) {
            let questionObj = {}
            each(languages, (lang, ind) => {
                questionObj[lang.code] = fields['value' + lang.code] ? fields['value' + lang.code] : '';
                delete fields['value' + lang.code];
            })
            fields.value = questionObj;
            configuration.allAPI({ url: 'sms-template/update', method: 'PATCH', params: fields }).then((data) => {
                // console.log(data)
                if (data.payload) {
                    history.push("/sms-template")
                } else if (data.error) {
                    return toast.error(data.error.message)
                } else {
                    return toast.error('Something went wrong')
                }
            }).catch(error => {
                // console.log(error)
                return toast.error(error.message)
            });
        }
    }

    useEffect(() => {
        var retrievedObject = JSON.parse(localStorage.getItem('token'));
        if (retrievedObject) {
            configuration.getAPI({ url: 'common/list-access', params: { roleID: retrievedObject.roleID } }).then((data) => {
                if (data.payload) {
                    setAuthorisation(data.payload)
                }
            }).catch(error => {
                return toast.error(error.message)
            });
        }

    }, []);
    useEffect(() => {
        const whereFind = (findWhere(authorisation, { code: 'SMS_T' }))
        if (whereFind?.data?.is_edit === false) {
            setIsValid(!isValid)
            history.push('/dashboard')
        }
        if (whereFind?.data?.is_edit === true) {
            setIsValid(!isValid)
        }
    }, [authorisation])

    return (
        <>

            {isValid ? <> <Breadcrumb module={labels.editSMSTemplate} />
                <div className='main dark:bg-black bg-white mb-5'>
                    <div className="dark:bg-black bg-white">
                        <div className="py-5 px-5 flex items-center justify-between">
                            <h1 className="text-3xl font-bold tracking-tight dark:text-[#fff] text-black">{labels.editSMSTemplate}</h1>
                        </div>

                        <div className="mt-5 sm:mx-auto sm:w-full px-4">
                            <div className="bg-skin-white py-5 px-5 shadow sm:rounded-lg">
                                <form className="space-y-6" action="#" method="POST">
                                    <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
                                        <div>
                                            <Label title="Title" />
                                            <TextInput
                                                id={'title'}
                                                name={'title'}
                                                handleChange={(field, e) =>
                                                    setFields({ ...fields, [field]: e.target.value })}
                                                value={fields['title']}
                                            />
                                            {errors.title ? (
                                                <Label title="title" fieldError={errors.title} />
                                            ) : null}
                                        </div>
                                        <div>
                                            <Label title="Code" />
                                            <TextInput
                                                id={'template_code'}
                                                name={'template_code'}
                                                handleChange={(field, e) =>
                                                    setFields({ ...fields, [field]: e.target.value })}
                                                value={fields['template_code']}
                                                is_read_only={true}
                                            />
                                            {errors.template_code ? (
                                                <Label title="template_code" fieldError={errors.template_code} />
                                            ) : null}
                                        </div>
                                        {languages.map((single) => {
                                            return <div>
                                                <Label title={`Value [${single.title}]`} />
                                                <TextInput
                                                    id={`value${single.code}`}
                                                    name={`value${single.code}`}
                                                    handleChange={(field, e) =>
                                                        setFields({ ...fields, [field]: e.target.value })}
                                                    value={fields[`value${single.code}`]}
                                                />
                                                {errors[`value${single.code}`] ? (
                                                    <Label title={`value${single.code}`} fieldError={errors[`value${single.code}`]} />
                                                ) : null}
                                            </div>
                                        })}
                                        <div>
                                            <Label title="Status" />
                                            <Dropdown
                                                id="status"
                                                name="status"
                                                title="status"
                                                value={fields.status}
                                                status={options}
                                                handleChange={(field, e) =>
                                                    setFields({ ...fields, [field]: e.target.value })}
                                            />
                                            {errors.status ? (
                                                <Label title="status" fieldError={errors.status} />
                                            ) : null}
                                        </div>
                                    </div>
                                    <ButtonSection path={'/sms-template'} handleSubmit={handleSubmit} />
                                </form>
                            </div>
                        </div>
                    </div>
                </div> </> : <> <Loader />  </>}

        </>
    )
}
