import React, { useEffect, useState } from "react";
import Breadcrumb from '../../components/breadcrumb';
import labels from "../../constants/Subscription";
import Label from "../../components/Form/Label";
import TextInput from "../../components/Form/TextInput";
import ButtonSection from "../../components/Form/ButtonSection";
import Dropdown from "../../components/Form/Dropdown";
import configuration from '../../config';
import { toast } from 'react-toastify';
import { each, findWhere } from 'underscore';
import Loader from '../../components/Loader/Loader';

export default function View({ history }) {
    const options = [
        { id: 1, title: "Active", value: "active" },
        { id: 2, title: "Inactive", value: "inactive" },
    ];
    const userType = [
        { id: 1, title: "Day", value: "day" },
        { id: 2, title: "Trip", value: "trip" },
    ];
    const [languages, setLanguages] = useState([])
    const [fields, setFields] = useState({});
    const [errors, setErrors] = useState({});
    const [isValid, setIsValid] = useState(false)
    const [authorisation, setAuthorisation] = useState()

    useEffect(() => {
        configuration.getAPI({ url: 'language/list', params: { status: 'active' } }).then((data) => {
            // console.log(data)
            if (data.payload) {
                setLanguages(data.payload)
            }
        }).catch(error => {
            // console.log(error)
            return toast.error(error.message)
        });
    }, []);
    function validation() {
        let flag = true;
        let error = {}
        each(languages, (lang, ind) => {
            if (!fields['title_' + lang.code]) {
                error['title_' + lang.code] = "Please enter title in " + lang.title
                flag = false
            }
            if (!fields['description_' + lang.code]) {
                error['description_' + lang.code] = "Please enter description in " + lang.title
                flag = false
            }
        })
        if (!fields.type) {
            error['type'] = "Please select type"
            flag = false
        }
        if (!fields.value) {
            error['value'] = "Please select value"
            flag = false
        }
        if (!fields.price) {
            error['price'] = "Please select price"
            flag = false
        }
        if (!fields.status) {
            error['status'] = "Please select status"
            flag = false
        }
        setErrors({ ...error })
        return flag;
    }

    const handleSubmit = () => {
        if (validation()) {
            let titleObj = {}
            let descriptionObj = {}
            each(languages, (lang, ind) => {
                titleObj[lang.code] = fields['title_' + lang.code] ? fields['title_' + lang.code] : '';
                delete fields['title_' + lang.code];
                descriptionObj[lang.code] = fields['description_' + lang.code] ? fields['description_' + lang.code] : '';
                delete fields['description_' + lang.code];
            })
            fields.title = titleObj;
            fields.description = descriptionObj;
            configuration.allAPI({ url: 'subscription/create', method: 'put', params: fields }).then((data) => {
                // console.log(data)
                if (data.payload) {
                    history.push("/subscription")
                } else if (data.error) {
                    return toast.error(data.error.message)
                } else {
                    return toast.error('Something went wrong')
                }
            }).catch(error => {
                // console.log(error)
                return toast.error(error.message)
            });
        }
    }


    useEffect(() => {
        var retrievedObject = JSON.parse(localStorage.getItem('token'));
        if (retrievedObject) {
            configuration.getAPI({ url: 'common/list-access', params: { roleID: retrievedObject.roleID } }).then((data) => {
                if (data.payload) {
                    setAuthorisation(data.payload)
                }
            }).catch(error => {
                return toast.error(error.message)
            });
        }
    }, []);
    useEffect(() => {
        const whereFind = (findWhere(authorisation, { code: 'SUBSCRIPTION' }))
        if (whereFind?.data?.is_add === false) {
            setIsValid(!isValid)
            history.push('/dashboard')
        }
        if (whereFind?.data?.is_add === true) {
            setIsValid(!isValid)
        }
    }, [authorisation])
    return (
        <>
            {isValid ? <>  <Breadcrumb module={labels.addSubscription} />
                <div className='main dark:bg-black bg-white mb-5'>
                    <div className="dark:bg-black bg-white">
                        <div className="py-5 px-5 flex items-center justify-between">
                            <h1 className="text-3xl font-bold tracking-tight dark:text-[#fff] text-black">{labels.addSubscription}</h1>
                        </div>

                        <div className="mt-5 sm:mx-auto sm:w-full px-4">
                            <div className="bg-skin-white py-5 px-5 shadow sm:rounded-lg">
                                <form className="space-y-6" action="#" method="POST">
                                    <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
                                        {languages.map((single) => {
                                            return <div>
                                                <Label title={`Title [${single.title}]`} />
                                                <TextInput
                                                    id={`title_${single.code}`}
                                                    name={`title_${single.code}`}
                                                    handleChange={(field, e) =>
                                                        setFields({ ...fields, [field]: e.target.value })}
                                                    value={fields[`title_${single.code}`]}
                                                />
                                                {errors[`title_${single.code}`] ? (
                                                    <Label title={`title_${single.code}`} fieldError={errors[`title_${single.code}`]} />
                                                ) : null}
                                            </div>
                                        })}
                                        {languages.map((single) => {
                                            return <div>
                                                <Label title={`Description [${single.title}]`} />
                                                <TextInput
                                                    id={`description_${single.code}`}
                                                    name={`description_${single.code}`}
                                                    handleChange={(field, e) =>
                                                        setFields({ ...fields, [field]: e.target.value })}
                                                    value={fields[`description_${single.code}`]}
                                                />
                                                {errors[`description_${single.code}`] ? (
                                                    <Label title={`description_${single.code}`} fieldError={errors[`description_${single.code}`]} />
                                                ) : null}
                                            </div>
                                        })}
                                        <div>
                                            <Label title="Subscription Type" />
                                            <div className="grid grid-cols-1 md:grid-cols-3 gap-5">
                                                <TextInput
                                                    id="value"
                                                    name="value"
                                                    handleChange={(field, e) =>
                                                        setFields({ ...fields, [field]: e.target.value })}
                                                    value={fields.value}
                                                    is_number={true}
                                                />
                                                {errors.value ? (
                                                    <Label title="value" fieldError={errors.value} />
                                                ) : null}
                                                <Dropdown
                                                    id="type"
                                                    name="type"
                                                    title="type"
                                                    value={fields.type}
                                                    status={userType}
                                                    handleChange={(field, e) =>
                                                        setFields({ ...fields, [field]: e.target.value })}
                                                />
                                                {errors.type ? (
                                                    <Label title="type" fieldError={errors.type} />
                                                ) : null}

                                            </div>
                                        </div>
                                        <div>
                                            <Label title="Price" />
                                            <TextInput
                                                id="price"
                                                name="price"
                                                handleChange={(field, e) =>
                                                    setFields({ ...fields, [field]: e.target.value })}
                                                value={fields.price}
                                                is_number={true}
                                            />
                                            {errors.price ? (
                                                <Label title="price" fieldError={errors.price} />
                                            ) : null}
                                        </div>
                                        <div>
                                            <Label title="Status" />
                                            <Dropdown
                                                id="status"
                                                name="status"
                                                title="status"
                                                value={fields.status}
                                                status={options}
                                                handleChange={(field, e) =>
                                                    setFields({ ...fields, [field]: e.target.value })}
                                            />
                                            {errors.status ? (
                                                <Label title="status" fieldError={errors.status} />
                                            ) : null}
                                        </div>
                                    </div>
                                    <ButtonSection path={'/subscription'} handleSubmit={handleSubmit} />
                                </form>
                            </div>
                        </div>
                    </div>
                </div></> : <> <Loader />  </>}

        </>
    )
}
