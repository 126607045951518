import PropTypes from "prop-types";
import React from "react";
function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}
// For Input Element
function TextInput({ id, name, handleChange, value, is_number, is_password, is_read_only }) {
  return (
      <input
        type= {(is_number) ? "number":(is_password)?"password":"text"}
        name={name}
        id={id}
        value = {value}
        onChange={(e)=>handleChange(name, e)}
        readOnly={is_read_only || false}
        autoComplete="given-name"
        className={classNames(is_read_only ? 'read-only' : '', 'block w-full rounded-[8px] border-[1.5px] border-skin-light text-skin-black py-2 px-4  outline-0 bg-transparent md:col-span-2 col-span-1')}
      />
  );
}

TextInput.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
};

TextInput.defaultProps = {
  id: "",
  name: "",
};

export default TextInput;
