import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Breadcrumb from "../../components/breadcrumb";
import labels from "../../constants/Ride";
import { PaperAirplaneIcon, DocumentArrowDownIcon, EyeIcon } from "@heroicons/react/20/solid";
import configuration from "../../config";
import { toast } from "react-toastify";
import html2pdf from "html2pdf.js";
import { useLocation } from "react-router-dom";
import { contains, findWhere } from "underscore";

// For Detail
function Detail({ history }) {
  const location = useLocation();
  const Id = useState(location.state);
  const [currency, setCurrency] = useState("");
  const [tripDetail, setTripDetail] = useState({
    customer_info: {},
    driver_info: {},
    vehicle_ride_info: {},
    service_info: {},
    fare: {},
    calculatedFare: {},
    rental_price_info: {},
    discount: 0,
    drop_off: [],
    additional_charges: [],
    tax: [],
  });
  const [authorisation, setAuthorisation] = useState([]);
  useEffect(() => {
    var retrievedObject = JSON.parse(localStorage.getItem("token"));
    if (retrievedObject) {
      configuration
        .getAPI({ url: "common/list-access", params: { roleID: retrievedObject.roleID } })
        .then((data) => {
          // console.log(data)
          if (data.payload) {
            setAuthorisation(data.payload);
          }
        })
        .catch((error) => {
          // console.log(error)
          return toast.error(error.message);
        });
    }
    configuration
      .getAPI({ url: "ride/detail", params: { ride_id: Id[0] } })
      .then((data) => {
        // console.log(data)
        if (data.payload) {
          setTripDetail(data.payload);
        }
      })
      .catch((error) => {
        // console.log(error)
        return toast.error(error.message);
      });
    configuration
      .getAPI({ url: "common/detail", params: {} })
      .then((data) => {
        // console.log(data)
        if (data.payload) {
          setCurrency(data.payload.currency);
        }
      })
      .catch((error) => {
        // console.log(error)
        return toast.error(error.message);
      });
  }, []);
  function printInvoice() {
    const element = document.getElementById("make_pdf");
    let fileName = tripDetail.ride_id;
    var opt = {
      margin: 1,
      filename: fileName + ".pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    };
    html2pdf().from(element).set(opt).save();
  }
  return (
    <>
      <Breadcrumb module={labels.rideDetail} />
      <div className="py-6 sm:px-6">
        <div className="relative">
          <section className="px-4" aria-labelledby="contact-heading">
            <div className="flex flex-col">
              <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                  <div className="mx-auto py-8 lg:flex lg:items-center lg:justify-between">
                    <h1 className="text-3xl font-bold tracking-tight dark:text-[#fff] text-black">
                      {labels.rideDetail}
                    </h1>
                  </div>
                  <div className="mb-5 text-right">
                    {/* <button type="button" className="mr-3 py-1 px-2 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 xl-admin-btn">
                      <span className="sr-only">Open options</span>
                      <PaperAirplaneIcon className="h-5 w-5 inline" aria-hidden="true" /> Send to Customer
                    </button> */}
                    <button
                      type="button"
                      onClick={printInvoice}
                      className="mr-3 py-1 px-2 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 xl-admin-btn"
                    >
                      <span className="sr-only">Open options</span>
                      <DocumentArrowDownIcon className="h-5 w-5 inline" aria-hidden="true" /> Download as PDF
                    </button>
                    {findWhere(authorisation, { code: "RIDE_INVOICE" })?.data?.is_view ? (
                      <Link
                        to={{
                          pathname: `/ride-invoice`,
                          state: Id[0],
                        }}
                      >
                        <button
                          type="button"
                          className="py-1 px-2 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 xl-admin-btn"
                        >
                          <span className="sr-only">Open options</span>
                          <EyeIcon className="h-5 w-5 inline" aria-hidden="true" /> Preview Invoice
                        </button>
                      </Link>
                    ) : (
                      ""
                    )}
                  </div>
                  <div id="make_pdf">
                    <section className="grid grid-cols-2 gap-4">
                      <div className="bg-white shadow sm:rounded-lg">
                        <div className="px-4 py-5 sm:px-6">
                          <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                            Passenger Details
                          </h2>
                        </div>
                        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                            <div className="sm:col-span-1">
                              <dt className="text-basefont-medium text-gray-500">Name</dt>
                              <dd className="mt-1 text-base text-gray-900">{tripDetail.customer_info.name}</dd>
                            </div>
                            <div className="sm:col-span-1">
                              <dt className="text-basefont-medium text-gray-500">Email</dt>
                              <dd className="mt-1 text-base text-gray-900 break-words">
                                {tripDetail.customer_info.email}
                              </dd>
                            </div>
                            <div className="sm:col-span-1">
                              <dt className="text-basefont-medium text-gray-500">Mobile No</dt>
                              <dd className="mt-1 text-base text-gray-900">
                                {tripDetail.customer_info.countryCode} {tripDetail.customer_info.mobile}
                              </dd>
                            </div>
                          </dl>
                        </div>
                      </div>
                      <div className="bg-white shadow sm:rounded-lg">
                        {tripDetail.driver_info ? (
                          <>
                            <div className="px-4 py-5 sm:px-6">
                              <h2
                                id="applicant-information-title"
                                className="text-lg leading-6 font-medium text-gray-900"
                              >
                                Driver Details
                              </h2>
                            </div>
                            <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                              <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                <div className="sm:col-span-1">
                                  <dt className="text-basefont-medium text-gray-500">Name</dt>
                                  <dd className="mt-1 text-base text-gray-900">{tripDetail.driver_info.name}</dd>
                                </div>
                                <div className="sm:col-span-1">
                                  <dt className="text-basefont-medium text-gray-500">Email</dt>
                                  <dd className="mt-1 text-base text-gray-900 break-words">
                                    {tripDetail.driver_info.email}
                                  </dd>
                                </div>
                                <div className="sm:col-span-1">
                                  <dt className="text-basefont-medium text-gray-500">Mobile No</dt>
                                  <dd className="mt-1 text-base text-gray-900">
                                    {tripDetail.driver_info.countryCode} {tripDetail.driver_info.mobile}
                                  </dd>
                                </div>
                              </dl>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="bg-white shadow sm:rounded-lg">
                        <div className="px-4 py-5 sm:px-6">
                          <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                            Trip Details
                          </h2>
                        </div>
                        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                            <div className="sm:col-span-1">
                              <dt className="text-basefont-medium text-gray-500">Pickup Address</dt>
                              <dd className="mt-1 text-base text-gray-900">{tripDetail.pickup_address}</dd>
                            </div>
                            {}
                            <div className="sm:col-span-1">
                              <dt className="text-basefont-medium text-gray-500">Dropoff Address</dt>
                              <dd className="mt-1 text-base text-gray-900">{tripDetail.dropoff_address}</dd>
                              {/*
                                tripDetail.dropoff_address.map((e, key) => {
                                  return (<dd className="mt-1 text-base text-gray-900">{key + 1}. {e.location}</dd>)
                                })
                              */}
                            </div>
                            <div className="sm:col-span-1">
                              <dt className="text-basefont-medium text-gray-500">Book Date</dt>
                              <dd className="mt-1 text-base text-gray-900">{tripDetail.booking_date}</dd>
                            </div>
                            {contains(["accepted", "started", "picked_up", "completed"], tripDetail.status) ? (
                              <div className="sm:col-span-1">
                                <dt className="text-basefont-medium text-gray-500">Accepted At</dt>
                                <dd className="mt-1 text-base text-gray-900">{tripDetail.acceptedAt}</dd>
                              </div>
                            ) : (
                              ""
                            )}
                            {contains(["started", "picked_up", "completed"], tripDetail.status) ? (
                              <div className="sm:col-span-1">
                                <dt className="text-basefont-medium text-gray-500">PickedUp At</dt>
                                <dd className="mt-1 text-base text-gray-900">{tripDetail.pickedUpAt}</dd>
                              </div>
                            ) : (
                              ""
                            )}
                            {contains(["completed", "started"], tripDetail.status) ? (
                              <div className="sm:col-span-1">
                                <dt className="text-basefont-medium text-gray-500">Started At</dt>
                                <dd className="mt-1 text-base text-gray-900">{tripDetail.startedAt}</dd>
                              </div>
                            ) : (
                              ""
                            )}
                            {tripDetail.status === "completed" ? (
                              <div className="sm:col-span-1">
                                <dt className="text-basefont-medium text-gray-500">Completed At</dt>
                                <dd className="mt-1 text-base text-gray-900">{tripDetail.completedAt}</dd>
                              </div>
                            ) : (
                              ""
                            )}
                            <div className="sm:col-span-1">
                              <dt className="text-basefont-medium text-gray-500">Total Distance</dt>
                              <dd className="mt-1 text-base text-gray-900">{tripDetail.formattedDistance}</dd>
                            </div>
                            <div className="sm:col-span-1">
                              <dt className="text-basefont-medium text-gray-500">Total Duration</dt>
                              <dd className="mt-1 text-base text-gray-900">{tripDetail.formattedDuration}</dd>
                            </div>
                            <div className="sm:col-span-1">
                              <dt className="text-basefont-medium text-gray-500">Service</dt>
                              <dd className="mt-1 text-base text-gray-900">{tripDetail.service}</dd>
                            </div>
                            <div className="sm:col-span-1">
                              <dt className="text-basefont-medium text-gray-500">Vehicle</dt>
                              <dd className="mt-1 text-base text-gray-900">{tripDetail.rideOption}</dd>
                            </div>
                            {/* <div className="sm:col-span-1">
                              <dt className="text-basefont-medium text-gray-500">Payment Type</dt>
                              <dd className="mt-1 text-base text-gray-900 capitalize">{tripDetail.payment_type}</dd>
                            </div> */}
                            {/*<div className="sm:col-span-1">
                              <dt className="text-basefont-medium text-gray-500">Payment Status</dt>
                              <dd className="mt-1 text-base text-gray-900 capitalize">{tripDetail.payment}</dd>
                            </div>*/}
                            <div className="sm:col-span-1">
                              <dt className="text-basefont-medium text-gray-500">Trip Status</dt>
                              <dd className="mt-1 text-base text-gray-900 capitalize">{tripDetail.status}</dd>
                            </div>
                            {!!(tripDetail.status === "cancelled" || tripDetail.status === "no_service") && (
                              <>
                                {!!tripDetail?.cancellationInfo?.byWhom?.length && (
                                  <div className="sm:col-span-1">
                                    <dt className="text-basefont-medium text-gray-500">Cancel By</dt>
                                    <dd className="mt-1 text-base text-gray-900 capitalize">
                                      {tripDetail.cancellationInfo.byWhom}
                                    </dd>
                                  </div>
                                )}
                                {!!tripDetail?.cancellationInfo?.reason?.length && (
                                  <div className="sm:col-span-1">
                                    <dt className="text-basefont-medium text-gray-500">Reason</dt>
                                    <dd className="mt-1 text-base text-gray-900 capitalize">
                                      {tripDetail.cancellationInfo.reason}
                                    </dd>
                                  </div>
                                )}
                              </>
                            )}
                          </dl>
                        </div>
                      </div>
                      <div className="bg-white shadow sm:rounded-lg">
                        <div className="px-4 py-5 sm:px-6">
                          <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                            {tripDetail.serviceCode} Fare Details
                          </h2>
                        </div>
                        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                            <div className="sm:col-span-1">
                              <dt className="text-basefont-medium text-gray-500">Min Base KM</dt>
                              <dd className="mt-1 text-base text-gray-900">{tripDetail.fare.min_base_km}</dd>
                            </div>
                            <div className="sm:col-span-1">
                              <dt className="text-basefont-medium text-gray-500">Min Base Fare</dt>
                              <dd className="mt-1 text-base text-gray-900">
                                {currency} {Number(tripDetail.fare.min_base_fare).toFixed(2)}
                              </dd>
                            </div>
                            <div className="sm:col-span-1">
                              <dt className="text-basefont-medium text-gray-500">Per KM Fare</dt>
                              <dd className="mt-1 text-base text-gray-900">
                                {currency} {Number(tripDetail.fare.per_km_fare).toFixed(2)}
                              </dd>
                            </div>
                            <div className="sm:col-span-1">
                              <dt className="text-basefont-medium text-gray-500">Per Min Fare</dt>
                              <dd className="mt-1 text-base text-gray-900">
                                {currency} {Number(tripDetail.fare.per_min_fare).toFixed(2)}
                              </dd>
                            </div>
                            <div className="sm:col-span-1">
                              <dt className="text-basefont-medium text-gray-500">Wait Fees</dt>
                              <dd className="mt-1 text-base text-gray-900">
                                {currency} {Number(tripDetail.fare.wait_fees).toFixed(2)}
                              </dd>
                            </div>
                            <div className="sm:col-span-1">
                              <dt className="text-basefont-medium text-gray-500">Base Fare</dt>
                              <dd className="mt-1 text-base text-gray-900">
                                {currency} {Number(tripDetail?.calculatedFare?.baseFare).toFixed(2)}
                              </dd>
                            </div>
                            <div className="sm:col-span-1">
                              <dt className="text-basefont-medium text-gray-500">Total KM Fare</dt>
                              <dd className="mt-1 text-base text-gray-900">
                                {currency} {Number(tripDetail?.calculatedFare?.perKMFare).toFixed(2)}
                              </dd>
                            </div>
                            <div className="sm:col-span-1">
                              <dt className="text-basefont-medium text-gray-500">Total Min Fare</dt>
                              <dd className="mt-1 text-base text-gray-900">
                                {currency} {Number(tripDetail?.calculatedFare?.perMinuteFare).toFixed(2)}
                              </dd>
                            </div>
                          </dl>
                        </div>
                      </div>
                    </section>
                    {/* <div className="grid grid-cols-2 mt-5 gap-4">
                      <div className="bg-white shadow sm:rounded-lg py-4 px-4">
                        <img src={tripDetail.google_map_image} alt="" />
                      </div>
                      <div className="bg-white shadow sm:rounded-lg py-4 px-4">
                        <div>
                          <label htmlFor="comment" className="block text-basefont-medium text-gray-700">
                            Trip Notes
                          </label>
                          <div className="mt-1">
                            <textarea
                              rows={4}
                              name="comment"
                              id="comment"
                              onChange={(e) => setAdmin_notes(e.target.value)}
                              value={admin_notes}
                              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-baseborder-gray-300 rounded-md"
                              defaultValue={''}
                            />
                          </div>
                        </div>
                        <div className="mt-5">
                          <button
                            type="button"
                            onClick={handleSaveNote}
                            className="mr-3 inline-flex items-center px-3 py-2 border border-transparent text-baseleading-4 font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          >
                            Save Notes
                          </button>
                        </div>
                      </div>
                    </div> */}
                    <div className="total">
                      <div className="mt-5 float-right	">
                        <dl style={{ minWidth: "320px" }} className=" space-y-6 pb-5 pt-5">
                          <div className="flex items-center justify-between">
                            <dt className="text-sm">Subtotal</dt>
                            <dd className="text-basefont-medium text-gray-900">
                              {currency} {Number(tripDetail.subTotal).toFixed(2)}
                            </dd>
                          </div>
                          {/*(tripDetail?.additional_charges.length > 0) ?
                            tripDetail?.additional_charges.map((single, indx) => {
                              return (<div className="flex items-center justify-between">
                                <dt className="text-sm">{single.title}</dt>
                                <dd className="text-basefont-medium text-gray-900">{currency} {Number(single.price).toFixed(2)}</dd>
                              </div>)
                            }) : ''
                          */}

                          <div className="flex items-center justify-between">
                            <dt className="text-sm">Waiting Charge</dt>
                            <dd className="text-basefont-medium text-gray-900">
                              {currency} {tripDetail?.waitingFees}
                            </dd>
                          </div>

                          {/* <div className="flex items-center justify-between">
                            <dt className="text-sm">Tax</dt>
                            <dd className="text-basefont-medium text-gray-900">{currency} {tripDetail?.totalTax}</dd>
                          </div> */}

                          <div className="flex items-center justify-between">
                            <dt className="text-sm">Tip</dt>
                            <dd className="text-basefont-medium text-gray-900">
                              {currency} {tripDetail?.tips_amount}
                            </dd>
                          </div>

                          {/* {(tripDetail.tax.length > 0) ?
                            tripDetail.tax.map((single, indx) => {
                              return (<div className="flex items-center justify-between">
                                <dt className="text-sm">{single.name} ({single.value}%)</dt>
                                <dd className="text-basefont-medium text-gray-900">{currency} {Number(single.taxAmount).toFixed(2)}</dd>
                              </div>)
                            }) : ''
                          } */}

                          <div className="flex items-center justify-between">
                            <dt className="text-sm">Discount</dt>
                            <dd className="text-basefont-medium text-gray-900">
                              {currency} {Number(tripDetail.totalDiscount).toFixed(2)}
                            </dd>
                          </div>
                          <div className="flex items-center justify-between">
                            <dt className="text-sm">Wallet Amount</dt>
                            <dd className="text-basefont-medium text-gray-900">
                              {currency} {Number(tripDetail.walletAmount).toFixed(2)}
                            </dd>
                          </div>

                          <div className="flex items-center justify-between border-t border-gray-200 pt-6">
                            <dt className="text-base font-medium">Total</dt>
                            <dd className="text-base font-medium text-gray-900">
                              {currency} {tripDetail?.totalFare}
                            </dd>
                          </div>
                        </dl>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default Detail;
