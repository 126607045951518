import React, { useEffect, useState } from "react";
import Breadcrumb from '../../components/breadcrumb';
import labels from "../../constants/User";
import Label from "../../components/Form/Label";
import TextInput from "../../components/Form/TextInput";
import ButtonSection from "../../components/Form/ButtonSection";
import Dropdown from "../../components/Form/Dropdown";
import CountryCode from '../../country_code';
import configuration from '../../config';
import { toast } from 'react-toastify';
import { each, findWhere } from 'underscore';
import Loader from "../../components/Loader/Loader";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}


export default function Edit({ history }) {
    const options = [
        { id: 1, title: "Active", value: "active" },
        { id: 2, title: "Inactive", value: "inactive" },
    ];
    const [fields, setFields] = useState({});
    const [errors, setErrors] = useState({});
    const [adminRole, setAdminRole] = useState('');
    const [role, setRole] = useState([]);
    const [isValid, setIsValid] = useState(false)
    const [authorisation, setAuthorisation] = useState()

    function getRole() {
        configuration.getAPI({ url: 'role/list', params: { status: 'active' } }).then((data) => {
            if (data.payload) {
                each(data.payload, single => {
                    single.value = single._id
                })
                setRole(data.payload);

            } else if (data.error) {
                return toast.error(data.error.message)
            } else {
                return toast.error('Something went wrong')
            }
        }).catch(error => {
            // console.log(error)
            return toast.error(error.message)
        });
    }
    useEffect(() => {
        try {
            var retrievedObject = JSON.parse(localStorage.getItem('token'));
            console.log(retrievedObject);
            setAdminRole(retrievedObject.roleTitle)
        } catch (err) {
            history.push("/")
        }
        let url = window.location.href;
        let ID = url.substring(url.lastIndexOf("/") + 1);
        getRole();
        configuration.getAPI({ url: 'user/list', params: { userID: ID } }).then((data) => {
            // console.log(data)
            if (data.payload) {
                setFields(data.payload)
            }
        }).catch(error => {
            // console.log(error)
            return toast.error(error.message)
        });
    }, []);
    function validation() {
        let flag = true;
        let error = {}
        if (!fields.firstName) {
            error['firstName'] = "Please enter valid first name"
            flag = false
        }

        if (!fields.lastName) {
            error['lastName'] = "Please enter valid last name"
            flag = false
        }
        if (!fields.mobile) {
            error['mobile'] = "Please enter valid mobile"
            flag = false
        }
        if (!fields.countryCode) {
            error['countryCode'] = "Please enter mobile country code"
            flag = false
        }
        if (!fields.email) {
            error['email'] = "Please enter valid email"
            flag = false
        }
        // if (!fields.roleID) {
        //     error['roleID'] = "Please select role"
        //     flag = false
        // }
        if (!fields.status) {
            error['status'] = "Please select status"
            flag = false
        }
        setErrors({ ...error })
        return flag;
    }

    const handleSubmit = () => {
        if (validation()) {
            configuration.allAPI({ url: 'user/update', method: 'PATCH', params: fields }).then((data) => {
                // console.log(data)
                if (data.payload) {
                    history.push("/user")
                } else if (data.error) {
                    return toast.error(data.error.message)
                } else {
                    return toast.error('Something went wrong')
                }
            }).catch(error => {
                // console.log(error)
                return toast.error(error.message)
            });
        }
    }



    useEffect(() => {
        var retrievedObject = JSON.parse(localStorage.getItem('token'));
        if (retrievedObject) {
            configuration.getAPI({ url: 'common/list-access', params: { roleID: retrievedObject.roleID } }).then((data) => {
                if (data.payload) {
                    setAuthorisation(data.payload)
                }
            }).catch(error => {
                return toast.error(error.message)
            });
        }

    }, []);
    useEffect(() => {
        const whereFind = (findWhere(authorisation, { code: 'ADMIN_USER' }))
        if (whereFind?.data?.is_edit === false) {
            setIsValid(!isValid)
            history.push('/dashboard')
        }
        if (whereFind?.data?.is_edit === true) {
            setIsValid(!isValid)
        }
    }, [authorisation])
    return (
        <>
            {isValid ? <> <Breadcrumb module={labels.editUsers} />
                <div className='main dark:bg-black bg-white mb-5'>
                    <div className="dark:bg-black bg-white">
                        <div className="py-5 px-5 flex items-center justify-between">
                            <h1 className="text-3xl font-bold tracking-tight dark:text-[#fff] text-black">{labels.editUsers}</h1>
                        </div>

                        <div className="mt-5 sm:mx-auto sm:w-full px-4">
                            <div className="bg-skin-white py-5 px-5 shadow sm:rounded-lg">
                                <form className="space-y-6" action="#" method="POST">
                                    <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
                                        <div>
                                            <Label title="First Name" />
                                            <TextInput
                                                id="firstName"
                                                name="firstName"
                                                handleChange={(field, e) =>
                                                    setFields({ ...fields, [field]: e.target.value })}
                                                value={fields.firstName}
                                            />
                                            {errors.firstName ? (
                                                <Label title="firstName" fieldError={errors.firstName} />
                                            ) : null}
                                        </div>
                                        <div>
                                            <Label title="Last Name" />
                                            <TextInput
                                                id="lastName"
                                                name="lastName"
                                                handleChange={(field, e) =>
                                                    setFields({ ...fields, [field]: e.target.value })}
                                                value={fields.lastName}
                                            />
                                            {errors.lastName ? (
                                                <Label title="lastName" fieldError={errors.lastName} />
                                            ) : null}
                                        </div>
                                        <div>
                                            <Label title="Email address" />
                                            <TextInput
                                                id="email"
                                                name="email"
                                                handleChange={(field, e) =>
                                                    setFields({ ...fields, [field]: e.target.value })}
                                                value={fields.email}
                                            />
                                            {errors.email ? (
                                                <Label title="email" fieldError={errors.email} />
                                            ) : null}
                                        </div>
                                        <div>
                                            <Label title="Mobile" />
                                            <div className="grid grid-cols-1 md:grid-cols-3 gap-5">
                                                <Dropdown
                                                    id="countryCode"
                                                    name="countryCode"
                                                    title="Country Code"
                                                    value={fields.countryCode}
                                                    status={CountryCode.countries}
                                                    handleChange={(field, e) =>
                                                        setFields({ ...fields, [field]: e.target.value })}
                                                />
                                                <TextInput
                                                    id="mobile"
                                                    name="mobile"
                                                    handleChange={(field, e) =>
                                                        setFields({ ...fields, [field]: e.target.value })}
                                                    value={fields.mobile}
                                                />

                                            </div>
                                            {errors.mobile ? (
                                                <Label title="mobile" fieldError={errors.mobile} />
                                            ) : null}
                                        </div>
                                        <div>
                                            <Label title="Role" />
                                            <Dropdown
                                                id="roleID"
                                                name="roleID"
                                                title="Role"
                                                readOnly={(adminRole !== 'Administrator')}
                                                value={fields.roleID}
                                                status={role}
                                                handleChange={(field, e) =>
                                                    setFields({ ...fields, [field]: e.target.value })}
                                            />
                                            {errors.roleID ? (
                                                <Label title="roleID" fieldError={errors.roleID} />
                                            ) : null}
                                        </div>
                                        <div>
                                            <Label title="Status" />
                                            <Dropdown
                                                id="status"
                                                name="status"
                                                title="status"
                                                value={fields.status}
                                                status={options}
                                                handleChange={(field, e) =>
                                                    setFields({ ...fields, [field]: e.target.value })}
                                            />
                                            {errors.status ? (
                                                <Label title="status" fieldError={errors.status} />
                                            ) : null}
                                        </div>
                                    </div>
                                    <ButtonSection path={'/user'} handleSubmit={handleSubmit} />
                                </form>
                            </div>
                        </div>
                    </div>
                </div> </> : <> <Loader />  </>}

        </>
    )
}
