
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import configuration from '../../config';
import database from '../../firebase';
import { onValue, ref, orderByChild, equalTo, query, child, get } from "firebase/database";
import { getAuth, signInAnonymously } from "firebase/auth";
import React, { useState, useRef, useEffect } from "react";
import { Map, GoogleApiWrapper, Marker, InfoWindow } from "google-maps-react";
import { findWhere } from 'underscore';
import { toast } from 'react-toastify';
import Loader from '../../components/Loader/Loader';
import {
  useHistory
} from "react-router-dom";
// const AnyReactComponent = ({ icon, onClickMarker }) => {
//   return (<div onClick={onClickMarker}>
//     <img style={{ width: "40px" }} src={icon} />
//   </div>)
// }
const MapContainer = ({ google }) => {
  const [authorisation, setAuthorisation] = useState()
  // const [intervalData, setIntervalData] = useState(null);
  const [isValid, setIsValid] = useState(false)
  const notificationMethods = [
    { id: 'all', title: 'All Drivers' },
    { id: 'online', title: 'Online Drivers' },
    { id: 'offline', title: 'Offline Drivers' },
  ]
  const history = useHistory()
  let defaultProps = {
    /* center: {
      lat: 23.0260094693156,
      lng: 72.55570555792589
    }, */
    center: {
      lat: 47.07500559993815,
      lng: 103.17381467274808
    },
    zoom: 7,

  };
  const [drivers, setDrivers] = useState([]);
  const reference = useRef(null);
  const [activeMarker, setActiveMarker] = useState(null);
  const [selectedElement, setSelectedElement] = useState(null);
  const containerStyle = {
  }

  function OnMarkerClick(e, marker, element) {
    // setSelectedElement(element);
    // setActiveMarker(marker);
    configuration
      .getAPI({ url: "app-user/live-track-detail", params: { user_id: element.driver_id } })
      .then((data) => {
        if (data.payload) {
          setSelectedElement(data.payload);
          setActiveMarker(marker);
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
  };
  useEffect(() => {
    var retrievedObject = JSON.parse(localStorage.getItem('token'));
    if (retrievedObject) {
      configuration.getAPI({ url: 'common/list-access', params: { roleID: retrievedObject.roleID } }).then((data) => {
        if (data.payload) {
          setAuthorisation(data.payload)
          const whereFind = (findWhere(data.payload, { code: 'TRACKING' }))
          if (whereFind?.data?.is_view === false) {
            setIsValid(!isValid)
            history.push('/dashboard')
          }
          if (whereFind?.data?.is_view === true) {
            setIsValid(!isValid)
          }
        }
      }).catch(error => {
        return toast.error(error.message)
      });
    }
    const auth = getAuth();
    signInAnonymously(auth)
      .then((userCredential) => {
        setDriverInterval('all')
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
      });
    // return () => {
    //   console.log("Leave Page")
    //   clearInterval(intervalData);
    // };
  }, []);

  function setDriverInterval(type) {
    // setIntervalData(setInterval(() => {
    getLiveData(type)
    // }, 3000))

  }
  function getLiveData(type) {
    let recentPostsRef = ref(database, "driversData");
    if (type === 'online' || type === 'offline') {
      recentPostsRef = query(ref(database, 'driversData'), orderByChild('availability_status'), equalTo(type));
    }
    return onValue(recentPostsRef, (snapshot) => {
      let finalData = [];
      const markers = snapshot.val();
      if (snapshot.exists()) {
        for (let a in markers) {
          let data = markers[a];
          if (data.latitude && data.longitude) {
            let innerObj = {
              driver_id: a,
              lat: parseFloat(data.latitude),
              lng: parseFloat(data.longitude),
              status: data?.availability_status,
              name: data?.driver_name,
            };
            finalData.push(innerObj);
          }
        }
        // console.log(finalData)
        setDrivers(finalData)
      }
    }, {
      onlyOnce: false
    });
  }

  function changeDriver(e, type) {
    // clearInterval(intervalData);
    setDriverInterval(type)
  }
  return (
    <div className="bg-gray-50 flex flex-col justify-center">
      <div className="grid grid-cols-1 md:grid-cols-1 ">
        {/* <div className=" py-4 px-4 shadow-lg bg-white" >
          <div>
            <fieldset className="mt-4">
              <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                {notificationMethods.map((a) => (
                  <div key={a.id} className="flex items-center">
                    <input
                      id={a.id}
                      name="notification-method"
                      type="radio"
                      onClick={(e) => changeDriver(e, a.id)}
                      defaultChecked={a.id === 'all'}
                      className="focus:ring-indigo-600 h-4 w-4 text-indigo-600 border-gray-300"
                    />
                    <label htmlFor={a.id} className="ml-3 block text-basefont-medium text-gray-700">
                      {a.title}
                    </label>
                  </div>
                ))}
              </div>
            </fieldset>
          </div>

        </div> */}
        <div className="relative h-screen w-full">
          <Map
            google={google}
            initialCenter={{
              lat: defaultProps.center.lat,
              lng: defaultProps.center.lng
            }}
            containerStyle={containerStyle}
            zoom={defaultProps.zoom}
          >
            {/* set markers for orders */}
            {drivers?.map((element, index) => {
              return (
                <Marker
                  key={index}
                  ref={reference}
                  title={element.name}
                  position={{
                    lat: element?.lat,
                    lng: element?.lng
                  }}
                  icon={{
                    url: (element.status === 'online') ? "image/Online.png" : "image/Offline.png",
                    anchor: new google.maps.Point(30, 45),
                    scaledSize: new google.maps.Size(30, 45)
                  }}
                  onClick={(props, marker) => OnMarkerClick(props, marker, element)}
                />
              );
            })}

            <InfoWindow
              visible={true}
              marker={activeMarker}
              onCloseClick={() => {
                setSelectedElement(null);
              }}
            >
              {(selectedElement?.type === 'ongoing') ?
                <div>
                  <div className="tooltip-hd tooltip_red">Booked Seats : {selectedElement?.bookedSeat}</div>
                  <div className="tooltip_row">
                    <span>Male : {selectedElement?.passengerCount?.male}</span>
                    <span>Female : {selectedElement?.passengerCount?.female}</span>
                    <span>Kids : {selectedElement?.passengerCount?.kids}</span>
                  </div>
                  <div className="tooltip_row">
                    <span>Luggage : {selectedElement?.luggage_weight}</span>
                  </div>
                  <div className="tooltip-hd tooltip_green">Available Seats : {selectedElement?.availableSeats}</div>
                  <div className="tooltip-hd">Vehicle Detail</div>
                  <div className="tooltip_row">
                    <span>{selectedElement?.rideOption}</span>
                    <span><strong>{selectedElement?.driver_info?.vehicleNo}</strong></span>
                    <span>{selectedElement?.driver_info?.brand} {selectedElement?.driver_info?.carName}</span>
                  </div>
                </div> :
                <div style={{ minWidth: '250px' }}>
                  <div className="tooltip-hd tooltip_red">{selectedElement?.name}</div>
                  <div>
                    <p style={{ paddingBottom: '5px' }}>DriverID : {selectedElement?._id}</p>
                    <p style={{ paddingBottom: '5px' }}>Email : {selectedElement?.email}</p>
                    <p style={{ paddingBottom: '5px' }}>Mobile : {selectedElement?.countryCode}{selectedElement?.mobile}</p>
                  </div>
                </div>
              }

            </InfoWindow>
          </Map>
        </div>
      </div>
    </div>
  );
}

export default GoogleApiWrapper({
  apiKey: configuration.google_key
})(MapContainer);
