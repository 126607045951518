
import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import Breadcrumb from "../../components/breadcrumb";
import labels from "../../constants/Ride";
import { PrinterIcon, DocumentArrowDownIcon } from '@heroicons/react/20/solid'
import configuration from '../../config';
import { toast } from 'react-toastify';
import html2pdf from "html2pdf.js";
import { useLocation } from 'react-router-dom'
// import { last } from "underscore";
import ReactToPrint from "react-to-print";

// Dynamic class
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

// For Detail
function Detail({ history }) {
  const location = useLocation();
  const Id = useState(location.state)
  const printInvoiceRef = useRef();
  const [currency, setCurrency] = useState('')
  const [tripDetail, setTripDetail] = useState({
    customer_info: {},
    driver_info: {},
    vehicle_ride_info: {},
    service_info: {},
    fare: {},
    calculatedFare: {},
    rental_price_info: {},
    discount: 0,
    drop_off: [],
    additional_charges: [],
    tax: [],
  });
  useEffect(() => {
    // var url = window.location.href;
    // Id = url.substring(url.lastIndexOf("/") + 1);
    console.log(Id[0])
    configuration.getAPI({ url: 'ride/detail', params: { ride_id: Id[0] } }).then((data) => {
      // console.log(data)
      if (data.payload) {
        setTripDetail(data.payload)
      }
    }).catch(error => {
      // console.log(error)
      return toast.error(error.message)
    });
    configuration.getAPI({ url: 'common/detail', params: {} }).then((data) => {
      // console.log(data)
      if (data.payload) {
        setCurrency(data.payload.currency)
      }
    }).catch(error => {
      // console.log(error)
      return toast.error(error.message)
    });
  }, []);
  function printInvoice() {
    const element = document.getElementById("make_pdf");
    let fileName = tripDetail.ride_id;
    var opt = {
      margin: 1,
      filename: fileName + '.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
    };
    html2pdf().from(element).set(opt).save();
  }
  return (
    <>
      <Breadcrumb module={labels.rideInvoice} />
      <div className="py-6 sm:px-6">
        <div className="relative">
          <section className="px-4" aria-labelledby="contact-heading">
            <div className="flex flex-col">
              <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                  <div className="mx-auto py-8 lg:flex lg:items-center lg:justify-between">
                    <h1 className="text-3xl font-bold tracking-tight dark:text-[#fff] text-black">{labels.rideInvoice}</h1>
                  </div>
                  <div className="shadow overflow-hidden border-b border-gray-300 sm:rounded-lg bg-white box_bg border-bottom-theme">
                    <div className="lg:gap-y-0 lg:gap-x-8 sm:p-6">
                      <form className="space-y-8 divide-y divide-gray-200">
                        <div className="space-y-8 divide-y divide-gray-200">
                          <div className="pt-1">
                            <div className="py-3 border-b flex justify-between">
                              <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                                Booking ID: #{tripDetail.ride_id}

                              </h2>
                              <div>
                                <ReactToPrint
                                  trigger={() => <button type="button" className="mr-3 py-1 px-2 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 xl-admin-btn">
                                    <span className="sr-only">Open options</span>
                                    <PrinterIcon className="h-5 w-5 inline" aria-hidden="true" /> Print
                                  </button>}
                                  content={() => printInvoiceRef.current}
                                />

                                <button onClick={printInvoice} type="button" className="py-1 px-2 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 xl-admin-btn">
                                  <span className="sr-only">Open options</span>
                                  <DocumentArrowDownIcon className="h-5 w-5 inline" aria-hidden="true" /> Download as PDF
                                </button>
                              </div>
                            </div>
                            <div className="mt-5" id="make_pdf" ref={printInvoiceRef}>
                              <div className="">

                                <div className="grid grid-cols-1">
                                  <div className="  flex items-center  justify-between bg-white shadow sm:rounded-lg mt-5 border py-3 px-3 min-h">
                                    <img className="md:mb-0 mb-3  w-12 " src="https://assets.peindia.cab/logo.png" alt=""></img>
                                    <p className="justify-self-start text-xl font-bold">Invoice</p>
                                  </div>
                                  <div className="grid grid-cols-1 md:grid-cols-3 gap-4">

                                    <div className="bg-white shadow sm:rounded-lg mt-5 border">
                                      <div className="px-4 py-5 sm:px-6">
                                        <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                                          Passenger Info
                                        </h2>
                                      </div>
                                      <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                                        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                          <div className="sm:col-span-1">
                                            <dt className="text-basefont-medium text-gray-500">Name</dt>
                                            <dd className="mt-1 text-base text-gray-900">{tripDetail.customer_info.name}</dd>
                                          </div>
                                          <div className="sm:col-span-1">
                                            <dt className="text-basefont-medium text-gray-500">Email</dt>
                                            <dd className="mt-1 text-base text-gray-900 break-words">{tripDetail.customer_info.email}</dd>
                                          </div>
                                          <div className="sm:col-span-1">
                                            <dt className="text-basefont-medium text-gray-500">Mobile</dt>
                                            <dd className="mt-1 text-base text-gray-900">{tripDetail.customer_info.mobile_country_code} {tripDetail.customer_info.mobile}</dd>
                                          </div>
                                        </dl>
                                      </div>
                                    </div>
                                    <div className="bg-white shadow sm:rounded-lg mt-5 border">
                                      {(tripDetail.driver_info) ?
                                        <>
                                          <div className="px-4 py-5 sm:px-6">
                                            <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                                              Driver Info
                                            </h2>
                                          </div>
                                          <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                                            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                              <div className="sm:col-span-1">
                                                <dt className="text-basefont-medium text-gray-500">Name</dt>
                                                <dd className="mt-1 text-base text-gray-900">{tripDetail.driver_info.name}</dd>
                                              </div>
                                              <div className="sm:col-span-1">
                                                <dt className="text-basefont-medium text-gray-500">Email</dt>
                                                <dd className="mt-1 text-base text-gray-900 break-words">{tripDetail.driver_info.email}</dd>
                                              </div>
                                              <div className="sm:col-span-1">
                                                <dt className="text-basefont-medium text-gray-500">Mobile</dt>
                                                <dd className="mt-1 text-base text-gray-900">{tripDetail.driver_info.mobile_country_code} {tripDetail.driver_info.mobile}</dd>
                                              </div>
                                              {/*<div className="sm:col-span-1">
                                              <dt className="text-basefont-medium text-gray-500">VAT Number</dt>
                                              <dd className="mt-1 text-base text-gray-900">45875</dd>
                                            </div>*/}
                                            </dl>
                                          </div></> : ''
                                      }
                                    </div>
                                  </div>


                                  <div className="">
                                    <dl className="  grid grid-cols-1 md:grid-cols-1 gap-x-6 mt-7 py-4 px-4 text-basebg-white shadow sm:rounded-lg mt-5 border">
                                      <div className="mb-5">
                                        <div className="mb-5 col-span-4">
                                          <dd className="mt-2 text-gray-700">
                                            <address className="not-italic">
                                              <span className="block text-base">Date & Time: <strong>{tripDetail.booking_date}</strong></span>
                                              <span className="block text-base">Total Distance: <strong>{tripDetail.formattedDistance}</strong></span>
                                              <span className="block text-base">Total Duration: <strong>{tripDetail.formattedDuration}</strong></span>
                                            </address>
                                          </dd>
                                        </div>


                                        <dt className="font-medium text-lg text-gray-900">Location</dt>
                                        {/*<div className="xi">
                                          <div className="">
                                            <input disabled="" name="pickup" title="" placeholder="From location" autocomplete="off" className="custom_input from_input" value="54, rue du Gue Jacquet" />
                                          </div>
                                          <div className="sn">
                                            <div className="">
                                              <input disabled="" name="destination" title="" placeholder="To Location" autocomplete="off" className="custom_input from_input" value="38, rue des Nations" />
                                            </div>
                                          </div>
                                          <div className=" xy xz y5 yc "></div>
                                        </div>*/}

                                        <div className="xi mt-4 py-4 px-4 shadow-lg bg-white rounded-md">
                                          <div className="relative ml-10">
                                            <span className="custom_input from_input bor">{tripDetail.pickup_address}</span>
                                          </div>
                                          {/*
                                            tripDetail.drop_off.map((e, key) => {
                                              let cssClass = (last(tripDetail.drop_off).location === e.location) ? "sn" : "sn centerdrop"
                                              return (
                                                <div className={cssClass}>
                                                  <div className="relative ml-10">
                                                    <span className="custom_input from_input bor">{e.location} </span>
                                                  </div>
                                                </div>
                                              )
                                            })
                                          */}
                                          <div className='sn centerdrop'>
                                            <div className="relative ml-10">
                                              <span className="custom_input from_input bor">{tripDetail.dropoff_address} </span>
                                            </div>
                                          </div>
                                          <div className=" xy xz y5 yc "></div>
                                        </div>
                                      </div>
                                    </dl>
                                    <div className="bg-white shadow sm:rounded-lg mt-5 border">
                                      <div className="px-4 py-5 sm:px-6">
                                        <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                                          Fare Details
                                        </h2>
                                      </div>
                                      <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                                        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                          <div className="sm:col-span-1">
                                            <dt className="text-basefont-medium text-gray-500">Min Base KM</dt>
                                            <dd className="mt-1 text-base text-gray-900">{tripDetail.fare.min_base_km}</dd>
                                          </div>
                                          <div className="sm:col-span-1">
                                            <dt className="text-basefont-medium text-gray-500">Min Base Fare</dt>
                                            <dd className="mt-1 text-base text-gray-900">{currency} {Number(tripDetail.fare.min_base_fare).toFixed(2)}</dd>
                                          </div>
                                          <div className="sm:col-span-1">
                                            <dt className="text-basefont-medium text-gray-500">Per KM Fare</dt>
                                            <dd className="mt-1 text-base text-gray-900">{currency} {Number(tripDetail.fare.per_km_fare).toFixed(2)}</dd>
                                          </div>
                                          <div className="sm:col-span-1">
                                            <dt className="text-basefont-medium text-gray-500">Per Min Fare</dt>
                                            <dd className="mt-1 text-base text-gray-900">{currency} {Number(tripDetail.fare.per_min_fare).toFixed(2)}</dd>
                                          </div>
                                          {/* <div className="sm:col-span-1">
                                            <dt className="text-basefont-medium text-gray-500">Wait Fees</dt>
                                            <dd className="mt-1 text-base text-gray-900">{currency} {Number(tripDetail.fare.wait_fees).toFixed(2)}</dd>
                                          </div> */}
                                          <div className="sm:col-span-1">
                                            <dt className="text-basefont-medium text-gray-500">Base Fare</dt>
                                            <dd className="mt-1 text-base text-gray-900">{currency} {Number(tripDetail.calculatedFare.baseFare).toFixed(2)}</dd>
                                          </div>
                                          <div className="sm:col-span-1">
                                            <dt className="text-basefont-medium text-gray-500">Total KM Fare</dt>
                                            <dd className="mt-1 text-base text-gray-900">{currency} {Number(tripDetail.calculatedFare.perKMFare).toFixed(2)}</dd>
                                          </div>
                                          <div className="sm:col-span-1">
                                            <dt className="text-basefont-medium text-gray-500">Total Min Fare</dt>
                                            <dd className="mt-1 text-base text-gray-900">{currency} {Number(tripDetail.calculatedFare.perMinuteFare).toFixed(2)}</dd>
                                          </div>
                                        </dl>
                                      </div>
                                    </div>
                                    <div className="mt-5 float-right	">
                                      <dl style={{ minWidth: '320px', }} className=" space-y-6 pb-5 pt-5">
                                        <div className="flex items-center justify-between">
                                          <dt className="text-sm">Subtotal</dt>
                                          <dd className="text-basefont-medium text-gray-900">{currency} {Number(tripDetail.subTotal).toFixed(2)}</dd>
                                        </div>
                                        {/*(tripDetail.additional_charges.length > 0) ?
                                          tripDetail.additional_charges.map((single, indx) => {
                                            return (<div className="flex items-center justify-between">
                                              <dt className="text-sm">{single.title}</dt>
                                              <dd className="text-basefont-medium text-gray-900">{currency} {Number(single.price).toFixed(2)}</dd>
                                            </div>)
                                          }) : ''
                                        }
                                        {(tripDetail.waiting_fare > 0) ?
                                          <div className="flex items-center justify-between">
                                            <dt className="text-sm">Waiting Charge</dt>
                                            <dd className="text-basefont-medium text-gray-900">{currency} {tripDetail.waiting_fare}</dd>
                                          </div> : ''
                                        */}
                                        {(tripDetail.tax.length > 0) ?
                                          tripDetail.tax.map((single, indx) => {
                                            return (<div className="flex items-center justify-between">
                                              <dt className="text-sm">{single.name} ({single.value}%)</dt>
                                              <dd className="text-basefont-medium text-gray-900">{currency} {Number(single.taxAmount).toFixed(2)}</dd>
                                            </div>)
                                          }) : ''
                                        }
                                        {/*(tripDetail.discount) ?
                                          <div className="flex items-center justify-between">
                                            <dt className="text-sm">Discount</dt>
                                            <dd className="text-basefont-medium text-gray-900">{currency} {Number(tripDetail.discount).toFixed(2)}</dd>
                                          </div> : ''
                                      */}

                                        <div className="flex items-center justify-between border-t border-gray-200 pt-6">
                                          <dt className="text-base font-medium">Total</dt>
                                          <dd className="text-base font-medium text-gray-900">{currency} {tripDetail.totalFare}</dd>
                                        </div>
                                      </dl>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default Detail;
