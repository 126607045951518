import React, { useState, useEffect } from "react";
import configuration from '../../../config';
import { toast } from 'react-toastify';
import DataTable from "../../../components/Table/MDataTable";
import { without } from 'underscore';
import moment from 'moment';

// Dynamic class
function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

let Id = "";
// List Settings
function Summary({ user_id, user_type }) {
    const [subscription, setSubscription] = useState({});
    const columns = [
        { title: "ID", field: "_id"},
        
        { title: "Subscription", field: "title" },
        { title: "Start Date", field: "createdAt" },
        { title: "End Date", field: "subscriptionExpiryDate" },
        {
          title: "Price",
          field: "amount"
        }
    ];
    const [data, setData] = useState([]);
    const onSelectionChange = (e) => {
        // let idArr = pluck(e, 'user_id');
        // setIds(idArr)
    }
    useEffect(() => {
        Id = user_id;
        configuration.getAPI({ url: 'app-user/detail', params: { user_id: Id } }).then((data) => {
            if (data.payload) {
                setSubscription(data.payload);
            }
        }).catch(error => {
            return toast.error(error.message)
        });
        configuration.getAPI({ url: 'app-user/subscription-history', params: { user_id: Id } }).then((data) => {
            if (data.payload) {
                // console.log(data.payload)
                setData(data.payload)
            }
        }).catch(error => {
            return toast.error(error.message)
        });
        // console.log(configuration.baseURL);
    }, []);
    return (
        <div className="md:pl-8 sm:py-7 col-span-10">
            <section aria-labelledby="applicant-information-title">
                <div className="bg-white shadow sm:rounded-lg">
                    <div className="px-4 py-5 sm:px-6">
                        <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                            Memberships
                        </h2>
                        <p className="mt-1 max-w-2xl text-base text-gray-500 text_theme">This information is show user memberships.</p>
                    </div>
                    <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                        < div className="">
                            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                <div key='status' className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">Memberships Status</dt>
                                    <dd className="mt-1 text-sm text-gray-900 md:break-all capitalize">{(subscription.isSubscriptionEnable)?"Available":"Expired"}</dd>
                                </div>
                                {/*(subscription.isSubscriptionEnable) ?
                                    <div key='status' className="sm:col-span-1">
                                        <dt className="text-sm font-medium text-gray-500">Memberships</dt>
                                        <dd className="mt-1 text-sm text-gray-900 md:break-all capitalize">{subscription.subscription_code}</dd>
    </div> : ''*/}
                                {(subscription.isSubscriptionEnable) ? 
                                <>
                                    <div key='status' className="sm:col-span-1">
                                        <dt className="text-sm font-medium text-gray-500">Memberships Expire Date</dt>
                                        <dd className="mt-1 text-sm text-gray-900 md:break-all capitalize">{moment(subscription.subscriptionExpiryDate).format('DD MMM YYYY')}</dd>
                                    </div> 
                                    <div key='status' className="sm:col-span-1">
                                        <dt className="text-sm font-medium text-gray-500">Memberships Title</dt>
                                        <dd className="mt-1 text-sm text-gray-900 md:break-all capitalize">{subscription.subscriptionTitle}</dd>
                                    </div> 
                                    {/* <div key='status' className="sm:col-span-1">
                                        <dt className="text-sm font-medium text-gray-500">Memberships Type</dt>
                                        <dd className="mt-1 text-sm text-gray-900 md:break-all capitalize">{subscription.subscriptionType}</dd>
                                    </div>  */}
                                    {(subscription.subscriptionType === 'trip')?
                                        <div key='status' className="sm:col-span-1">
                                            <dt className="text-sm font-medium text-gray-500">Remaining Trips</dt>
                                            <dd className="mt-1 text-sm text-gray-900 md:break-all capitalize">{subscription.subscriptionRemainingTrip}</dd>
                                        </div> :''
                                    }
                                </>
                                : ''
                                }
                            </dl>

                                <div className="datatable  border-t border-gray-200 py-5 mt-5">                                                                   
                                <DataTable
                                columns={without(columns, null)}
                                data={data}
                                disabledCheckbox={true}
                                onSelectionChange={onSelectionChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Summary;
