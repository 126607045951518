import React, { Fragment } from 'react'
import loader from "../../assets/img/loader.gif"


const Loader = () => {
    return (
        <Fragment>
            <div className="w-full mx-auto h-screen flex items-center justify-center">
                <img className="overflow-auto w-auto" src={loader} />
            </div>
        </ Fragment>
    )
}

export default Loader
