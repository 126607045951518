import { Link } from 'react-router-dom';
import Logo from '../../assets/img/logo.png';
import SigninVector from '../../assets/img/login-rideshare.svg';
import React, { useState, useEffect } from "react";
import configuration from '../../config';
import { toast } from 'react-toastify';
import Label from "../../components/Form/Label";
import TextInput from "../../components/Form/TextInput";
import { reactLocalStorage } from 'reactjs-localstorage';
import {
  UserIcon,
} from '@heroicons/react/20/solid'
// var jwt = require('jsonwebtoken');

export default function SignUp({ history }) {
  const [fields, setFields] = useState({});
  const [errors, setErrors] = useState({
    email: "",
    password: "",
  });
  function validation() {
    let flag = true;
    let error = {}
    if (!fields.email) {
      error['email'] = "Please enter email"
      flag = false
    }
    setErrors({ ...error })
    return flag;
  }
  useEffect(() => {
    // try{
    //     var retrievedObject = JSON.parse(localStorage.getItem('token'));
    //     console.log(retrievedObject);
    //     if(retrievedObject){
    //         history.push("/dashboard")
    //     } 
    // } catch(err){
    //     history.push("/")
    // }
  }, []);

  const handleSubmit = () => {
    console.log("fields");
    if (validation()) {
      configuration.postAPI({ url: 'auth/forgot-password', params: fields }).then((data) => {
        // console.log(data)
        if (data.payload) {
          history.push("/")
        } else if (data.error) {
          return toast.error(data.error.message)
        } else {
          return toast.error('Something went wrong')
        }
      }).catch(error => {
        // console.log(error)
        return toast.error(error.message)
      });
    }
  }
  return (
    <div className='app w-100 justify-content-center'>
      <div className="mobileview align-items-center justify-content-center row">
        <div className='col-md-6'>
          <div className='login_vector'>
            <div className='loginlogo'>
              <img
                className="mx-auto w-80"
                src={Logo}
                alt="Your Company"
              />
            </div>
            <p className='text-muted px-4'>
              <span style={{ color: "#4262D6" }}>XLRideshare</span> is a proprietary product developed by <span style={{ color: "#4262D6" }}>XongoLab Technologies LLP</span>. Any unauthorized use, including but not limited to copyright infringement, referencing, or extraction of information from this platform, is strictly prohibited and subject to legal consequences.
            </p>
            <div><img alt='XLRideshare' src={SigninVector} /></div>
          </div>
        </div>
        <div className='col-md-4'>
          <h3 className='login-title'>Forgot your password</h3>
          <form className="space-y-6" action="#" method="POST">
            <div>
              <Label title="Email" />
              <div className="mt-1 cus_input">
                <TextInput className="form-control textbox"
                  id="email"
                  name="email"
                  handleChange={(field, e) =>
                    setFields({ ...fields, [field]: e.target.value })}
                  value={fields.email}
                />
                <div className='input_icons'>
                  <UserIcon className="h-5 w-5" aria-hidden="true" />
                </div>
              </div>
              {errors.email ? (
                <Label title="email" fieldError={errors.email} />
              ) : null}
            </div>

            <div className="flex items-center justify-end">
              <div className="text-base">
                Just remembered? &nbsp;
                <Link to="/" className="font-medium text-skin-primary link">
                  Sign in
                </Link>
              </div>
            </div>

            <div>
              <button
                type='button' className="btn-link mt-1 flex justify-center w-full items-center rounded-md border-2 border-skin-primary px-5 py-2 text-[16px] font-medium shadow-sm bg-skin-primary text-skin-white" onClick={handleSubmit}>
                Reset Password
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>

  )
}
