import React from "react";
/* import configuration from '../../../config';
import { toast } from 'react-toastify';

let Id = ""; */
// List Settings
function BankDetails({ bank_data }) {
    /* useEffect(() => {
        Id = user_id;
        configuration.getAPI({ url: 'app-user/detail', params: { user_id: Id } }).then((data) => {
            if (data.payload) {
                setData(data.payload);
            }
        }).catch(error => {
            return toast.error(error.message)
        });

    }, []); */
    return (
        <div className="md:pl-8 sm:py-7 col-span-10">
            <section aria-labelledby="applicant-information-title">
                <div className="bg-white shadow sm:rounded-lg">
                    <div className="px-4 py-5 sm:px-6">
                        <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                            Bank Details
                        </h2>
                        <p className="mt-1 max-w-2xl text-base text-gray-500 text_theme">This information is show user bank detail.</p>
                    </div>
                    <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                        < div className="">
                            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                <div key='status' className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">Bank Name</dt>
                                    <dd className="mt-1 text-sm text-gray-900 md:break-all capitalize">{bank_data.bank_name}</dd>
                                </div>
                                <div key='status' className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">Bank Holder Name</dt>
                                    <dd className="mt-1 text-sm text-gray-900 md:break-all capitalize">{bank_data.account_holder_name}</dd>
                                </div>
                                <div key='status' className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">Account no</dt>
                                    <dd className="mt-1 text-sm text-gray-900 md:break-all capitalize">{bank_data.account_no}</dd>
                                </div>
                                <div key='status' className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">Bank Code</dt>
                                    <dd className="mt-1 text-sm text-gray-900 md:break-all capitalize">{bank_data.bank_code}</dd>
                                </div>
                            </dl>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default BankDetails;
