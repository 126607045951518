import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import configuration from './config';
import { findWhere } from 'underscore';

export default function View({ moduleName }) {
  const history = useHistory();
  useEffect(() => {
    var retrievedObject = JSON.parse(localStorage.getItem('token'));
    if (retrievedObject) {
      configuration.getAPI({ url: 'common/list-access', params: { roleID: retrievedObject.roleID } }).then((data) => {
        if (data.payload) {
          let accessData = findWhere(data.payload, { code: moduleName });
          if (!accessData.data.is_view) {
            history.push("/");
          }
        }
      }).catch(error => {
        console.log(error)
      });
    } else {
      history.push("/")
    }
  }, []);
    return (
        <>
            <div className='main'>
            </div>
        </>
    )
}

